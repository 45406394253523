import React from "react"
import { Checkbox as AntdChekbox } from "antd"
import type { CheckboxProps } from "antd/es/checkbox"
import styled from "styled-components"
export const StyledCheckbox = styled(AntdChekbox)`
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5rem;
  white-space: nowrap;
  margin-left: 0px !important;
  .ant-checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 6px;
    margin-left: 0px;
    :hover .ant-checkbox::after {
      visibility: hidden;
    }
  }
  .ant-checkbox {
    top: 0;
    :hover::after {
      visibility: hidden;
    }
    .ant-checkbox-inner {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      width: 25px;
      height: 25px;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #cde6f4;
        ::after {
          border-color: #000;
          border-width: 1.5px;
          left: 30.5%;
          transition: none;
        }
      }
    }
    + span {
      padding-left: 0;
      line-height: 30px;
    }
  }
`
interface IProps extends CheckboxProps {
  style?: any
  label?: string
}

export const Checkbox: React.FC<IProps> = ({ label, ...rest }) => {
  return <StyledCheckbox {...rest}>{label}</StyledCheckbox>
}
