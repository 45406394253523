export const scrollToSelectedElement = (listRef: any) => {
  const y =
    listRef?.current?.getBoundingClientRect().top + window.pageYOffset - 100
  if (window !== undefined) {
    window.scrollTo({ top: y, behavior: "smooth" })
  }
}

export const scrollToSelectedRefElement = (listRef: any) => {
  listRef?.current?.scrollIntoView({ behavior: "smooth" })
}
