export function getFacilities<T>(facilities: any): T[] {
  return facilities?.map((fac) => {
    const facilityOption = {
      label: fac.facility_name_short,
      value: fac.id,
      disabled: fac.account_number === "",
      type: fac?.pref_id,
    }
    return facilityOption
  })
}

export function getAllFacilities<T>(facilities: any): T[] {
  return facilities?.map((fac) => {
    const facilityOption = {
      label: fac.facility_name_short,
      value: fac.id,
    }
    return facilityOption
  })
}
