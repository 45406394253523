export const adultTheme = {
  colors: {
    primary: "#A1E5EA",
    secondary: "#3F5554",
    accent: "#E4EAA1",
    button: "#0A9BA6",
    link: "#0B96D1",
    error: "#E00000",
    success: "#0DD481",
    info: "#D26D10",
    white: "#FFFFFF",
    secondary2: "#374151",
  },
  breakpoints: {
    xxs: "300px",
    xs: "400px",
    sm: "480px",
    md: "767px",
    xmd: "800px",
    lg: "1024px",
    xlg: "1100px",
    xl: "1200px",
  },
  size: {
    xxs: "0.2em",
    xs: "0.4em",
    sm: "0.5em",
    md: "0.7em",
    xmd: "0.9em",
    lg: "1em",
    xlg: "1.5em",
    xl: "2em",
    xxl: "3em",
    xxxl: "4em",
  },
  fonts: {
    lighter: "lighter",
    normal: "normal",
    bold: "bold",
    bolder: "bolder",
  },
  radius: {
    xs: "0.4em",
    sm: "0.5em",
    md: "0.7em",
    lg: "1em",
    xl: "2em",
    circle: "50%",
    round: "4%",
  },
}
