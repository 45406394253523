import { DownloadOutlined, EyeOutlined } from "@ant-design/icons"
import { Empty, Image, Popconfirm, Skeleton, Tag } from "antd"
import { t } from "i18next"
import React, { useRef } from "react"
import styled from "styled-components"

// types
import Link from "next/link"
import { theme } from "../../../theme"
import { MessageListDataProps } from "./types"

interface iMessage {
  data?: MessageListDataProps[]
  loading?: boolean
  deleteComments?: any
  isDeleting?: boolean
  scrollToMessageId?: number
}

const Container = styled.div`
  display: contents;
  min-height: 200px;
  .download-section {
    padding-top: 1rem;
    a {
      font-size: 13px;
      margin-top: 1.9px;
      white-space: nowrap;
      color: ${theme.blue2};
      font-style: italic;
    }
  }
`
const LeftContain = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 14px;
`
const RightContain = styled(LeftContain)`
  align-items: flex-end;
  .download-section {
    padding-top: 1rem;
    a {
      font-size: 13px;
      margin-top: 1.9px;
      white-space: nowrap;
      color: ${theme.blue2};
      font-style: italic;
    }
  }
`
const LeftBox = styled.div`
  padding: 20px 24px;
  margin-top: 0.2rem;
  background: #f3f3f3;
  border-radius: 12px;
  white-space: pre-line;
  max-width: 50%;
  word-break: break-all;
  @media screen and (max-width: 761px) {
    max-width: 100%;
  }
`
const RightTop = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const RightBox = styled(LeftBox)`
  background: #fff9e6;
`
const FileWrapper = styled.a`
  padding: 10px;
  border: 1px solid gray;
  margin-top: 5px;
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: 10px;
  }
`

const MessageComponent: React.FC<iMessage> = ({
  data,
  loading,
  deleteComments,
  isDeleting,
  scrollToMessageId,
}) => {
  const containerRef = useRef(null)
  const scrollToBottom = () => {
    if (scrollToMessageId > 0) {
      const scrollId = document.getElementById(`message-${scrollToMessageId}`)
      if (scrollId)
        window.scrollTo({
          top: scrollId.offsetTop,
          behavior: "smooth",
        })
    }
  }
  if (loading) {
    return (
      <Container>
        <Skeleton />
      </Container>
    )
  }

  if (data?.length <= 0) {
    return (
      <Container>
        <Empty description={t("No data to display")} />
      </Container>
    )
  }

  // make some delay to adjust loading
  setTimeout(() => scrollToBottom(), 1000)

  return (
    <Container ref={containerRef}>
      {data?.map((item, index) => {
        return (
          <div key={index} id={`message-${item.id}`}>
            {!item?.self ? (
              <LeftContain>
                <div className={"date"}>
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <p style={{ fontSize: "13px" }}>
                      {t("{{date}} Posted by: ", {
                        date: item?.date,
                      })}
                      <strong>
                        {t("{{staff_name}}", {
                          // This will work since, the comment will either have staff name or parent name, depending on where the chat was sent from.
                          // Check staff_name first since, parent name is always available
                          staff_name: item?.staff_name || item?.parent_name,
                        })}
                      </strong>
                    </p>
                  ) : (
                    <p style={{ fontSize: "13px" }}>
                      {t("{{date}} ", {
                        date: item?.date,
                      })}
                      <strong>
                        {t("{{staff_name}}", {
                          // This will work since the comment will either have staff name or parent name, depending on where the chat was sent from.
                          // Check staff_name first since parent name is always available
                          staff_name:
                            localStorage.getItem("i18nextLng") === "ja"
                              ? item?.staff_name || item?.parent_name
                              : null,
                        })}
                      </strong>
                      {t(" Posted by", {})}
                    </p>
                  )}
                </div>
                {item?.type === "image" ? (
                  <>
                    {item?.message && <LeftBox>{item?.message}</LeftBox>}
                    <Image
                      src={item?.file_url?.replace("/thumbnail/", "/original/")}
                      width={"200px"}
                      height={"200px"}
                      style={{ objectFit: "contain", backgroundColor: "grey" }}
                      preview={{
                        mask: (
                          <span>
                            <EyeOutlined />
                            &nbsp;
                            {t("Preview")}
                          </span>
                        ),
                      }}
                    />
                    {item?.file_url && (
                      <div className={"download-section"}>
                        <Link
                          href={item?.file_url?.replace(
                            "thumbnail",
                            "original"
                          )}
                        >
                          <a target={"_blank"}>{t("Download")}</a>
                        </Link>
                      </div>
                    )}
                  </>
                ) : item?.type === "file" ? (
                  <>
                    {item?.message && <LeftBox>{item?.message}</LeftBox>}
                    <FileWrapper
                      href={item?.file_url}
                      download={item?.file_name}
                    >
                      <DownloadOutlined />
                      <div>{item?.file_name}</div>
                    </FileWrapper>
                  </>
                ) : (
                  <LeftBox>{item?.message}</LeftBox>
                )}
              </LeftContain>
            ) : (
              <RightContain>
                <RightTop>
                  <div className={"date"}>
                    <span style={{ fontSize: "13px" }}>{`${item.date}`} </span>
                    <strong>
                      {t("{{parent_name}}", {
                        // This will work since, the comment will either have staff name or parent name, depending on where the chat was sent from.
                        // Check staff_name first since, parent name is always available
                        parent_name: item?.staff_name || item?.parent_name,
                      })}
                    </strong>
                    <span style={{ fontSize: "13px" }}>{t(" post")}</span>
                  </div>
                  {/**
                   *
                   *this component is shown when someone read the chat
                   */}
                  {item?.read && (
                    <Tag
                      color={"#4CB476"}
                      style={{ borderRadius: "6px", height: "24px" }}
                    >
                      {t("Read")}
                    </Tag>
                  )}
                  {
                    /**
                     * Previously, 'delete button' was rendered conditionally
                     * relative to isDeleting boolean which was causing flickering
                     * of delete button. I removed it. If was there for a reason,
                     * please add it back and fix the flickering issue.
                     * */

                    /* !isDeleting && */
                    <DeleteButton
                      deleteComments={deleteComments}
                      id={item.id}
                      isDeleting={isDeleting}
                    />
                  }
                </RightTop>
                {item?.type === "image" ? (
                  <>
                    {item?.message && <RightBox>{item?.message}</RightBox>}
                    <Image
                      src={item?.file_url?.replace("/thumbnail/", "/original/")}
                      width={"200px"}
                      height={"200px"}
                      style={{
                        objectFit: "contain",
                        backgroundColor: "grey",
                        margin: "1rem 0",
                      }}
                      preview={{
                        mask: (
                          <span>
                            <EyeOutlined />
                            &nbsp;
                            {t("Preview")}
                          </span>
                        ),
                      }}
                    />
                    {item?.file_url && (
                      <div className={"download-section"}>
                        <Link
                          href={item?.file_url?.replace(
                            "/thumbnail/",
                            "/original/"
                          )}
                        >
                          <a target={"_blank"}>{t("Download")}</a>
                        </Link>
                      </div>
                    )}
                  </>
                ) : item?.type === "file" ? (
                  <>
                    {item?.message && <RightBox>{item?.message}</RightBox>}
                    <FileWrapper
                      href={item?.file_url}
                      download={item?.file_name}
                    >
                      <DownloadOutlined />
                      <div>{item?.file_name}</div>
                    </FileWrapper>
                  </>
                ) : (
                  <RightBox>{item?.message}</RightBox>
                )}
              </RightContain>
            )}
          </div>
        )
      })}
    </Container>
  )
}

export const DeleteButton = ({
  deleteComments,
  id,
}: {
  deleteComments: (id: number) => void
  id: number
  isDeleting: boolean
}) => {
  return (
    <Popconfirm
      title={t("Are you sure to delete this message?")}
      onConfirm={() => {
        deleteComments(id)
      }}
      okText={t("Ok")}
      cancelText={t("Cancel")}
      placement={"topRight"}
    >
      <a
        style={{
          textDecoration: "underline",
          color: "red",
          fontSize: "13px",
          marginTop: "1.9px",
          whiteSpace: "nowrap",
        }}
      >
        {t("Delete ")}
      </a>
    </Popconfirm>
  )
}

export { MessageComponent }
