import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import moment, { Moment } from "moment"
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CalendarOutlined,
} from "@ant-design/icons"
import { theme } from "../../../theme"
import { useTranslation } from "react-i18next"
import { Button, Input, InputProps, Popover, Select } from "antd"

import {
  PickerContent,
  PickerFooter,
  PickerHeader,
  WarikeDatePickerContainer,
  Wrapper,
} from "./WarikeDatePicker.style"
import {
  generateJapaneseEraAndYears,
  generateMonthsAccordingToEra,
} from "../../../utils"

const weeks = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

interface WarikeDatePickerProps extends InputProps {
  icon?: ReactNode
  format?: string
  date: any
  onDateChange?: (date: Moment | null) => void
  placement?: any
  disabledDate?: any
  error?: any
}
const dateFormate = "YYYY年MM月DD日"
export const WarikeDatePicker = ({
  icon: Icon,
  format = dateFormate,
  date: defaultDate,
  onDateChange,
  placement = "bottomLeft",
  disabledDate,
  error,
  ...rest
}: WarikeDatePickerProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<any>(null)
  const [open, setOpen] = useState(false)
  const [currentDate, setCurrentDate] = useState(defaultDate || moment())
  const startDate = moment(currentDate).startOf("month")
  const endDate = moment(currentDate).endOf("month")
  const numDays = moment(endDate).diff(startDate, "days") + 1
  const prefixDays = startDate.day()
  const suffixDays = 6 - endDate.day()
  const yearsOptions = useMemo(
    () => generateJapaneseEraAndYears(1989, moment()?.year() + 10),
    []
  )
  const defaultYear = +moment(currentDate)?.year()
  const defaultMonth = +moment(currentDate)?.month() + 1
  const months = useMemo(
    () => generateMonthsAccordingToEra(defaultYear, defaultMonth),
    [defaultYear, defaultMonth]
  )
  const getYearValue = (y, m) => {
    if (y === 2019 && m <= 4) {
      return `2019-1`
    } else if (y === 2019 && m >= 5) {
      return `2019-2`
    }
    return `${y}`
  }
  const [defaultFormat, setDefaultFormat] = React.useState<any>(format)
  const [newDate, setNewDate] = React.useState<any>(currentDate)
  const [inputValue, setInputValue] = useState("")
  const [hasNoNewValue, setHasNoValues] = useState(false)
  const [invalidDatePicked, setInvalidateYearPicked] = useState(false)
  const reset = () => {
    setInputValue(null)
    setNewDate(null)
    setDefaultFormat(dateFormate)
    setInvalidateYearPicked(false)
  }
  const handleClickDate = (val: number) => {
    const year = currentDate?.year()
    const month = currentDate?.month()
    onDateChange(moment([year, month, val]))
    setOpen(false)
    reset()
    setDefaultFormat(dateFormate)
  }
  const prevMonth = () => {
    const d = moment(currentDate)?.subtract(1, "months")
    setCurrentDate(moment(d))
  }
  const nextMonth = () => {
    const d = moment(currentDate)?.add(1, "months")
    setCurrentDate(moment(d))
  }
  useEffect(() => {
    setCurrentDate(defaultDate || moment())
  }, [defaultDate])

  const content = () => {
    return (
      <Wrapper>
        <PickerHeader>
          <Button
            shape={"circle"}
            className={"prev_date"}
            size={"small"}
            onClick={prevMonth}
          >
            <CaretLeftOutlined
              style={{
                color: theme.base,
              }}
            />
          </Button>
          <div className={"date_selector"}>
            <div className={"content"}>
              <Select
                className={"year__selector"}
                options={yearsOptions}
                value={getYearValue(+defaultYear, +defaultMonth)}
                onChange={(value) => {
                  const newYear = value?.split("-")
                  const defaultMonth = currentDate?.month()
                  if (newYear[0] === "2019" && newYear[1] === "1") {
                    setCurrentDate(moment([newYear[0], 0, "1"]))
                  } else if (newYear[0] === "2019" && newYear[1] === "2") {
                    setCurrentDate(moment([newYear[0], 4, "1"]))
                  } else {
                    setCurrentDate(moment([value, defaultMonth, "1"]))
                  }
                }}
              />
            </div>
            <div className={"content"}>{t("Year")}</div>
            <div className={"content"}>
              <Select
                bordered={false}
                className={"month__selector"}
                options={months}
                value={`${currentDate?.month() + 1}`}
                onChange={(value) => {
                  const defaultYear = currentDate?.year()
                  setCurrentDate(moment([defaultYear, +value - 1, "1"]))
                }}
              />
            </div>
          </div>
          <Button
            shape={"circle"}
            className={"next_date"}
            size={"small"}
            onClick={nextMonth}
          >
            <CaretRightOutlined
              style={{
                color: theme.base,
              }}
            />
          </Button>
        </PickerHeader>
        <PickerContent>
          {weeks.map((week) => (
            <div className={"picker__weekday"} key={week}>
              {t(week)}
            </div>
          ))}
          {Array.from({ length: prefixDays }).map((_, index) => (
            <div key={index} />
          ))}
          {Array.from({ length: numDays }).map((_, index) => {
            const date = index + 1
            const today = moment()
            const isActive =
              inputValue?.length >= 8 && moment(newDate).isValid()
                ? +inputValue?.slice(-2) === date
                : moment(defaultDate)?.year() === moment(currentDate).year() &&
                  moment(defaultDate)?.month() ===
                    moment(currentDate).month() &&
                  date === moment(defaultDate).date()
            const isCurrentDate =
              moment(currentDate)?.year() === moment(today).year() &&
              moment(currentDate)?.month() === moment(today).month() &&
              moment()?.date() === date
            const isDisabled = disabledDate
              ? disabledDate(
                  moment([currentDate?.year(), currentDate?.month(), date])
                )
              : false
            return (
              <button
                key={date}
                onClick={() => {
                  setHasNoValues(false)
                  handleClickDate(date)
                }}
                className={`picker_date
                 ${isActive ? "active__picker_date" : ""}
                 ${isDisabled ? "disabled__picker_date" : ""}
                ${isCurrentDate ? "today__date" : ""}
                `}
                disabled={isDisabled}
                type={"button"}
              >
                {date}
              </button>
            )
          })}
          {Array.from({ length: suffixDays }).map((_, index) => (
            <div key={index} />
          ))}
        </PickerContent>
        <PickerFooter>
          <div className={"picker__footer-right"}>
            <Button
              onClick={() => {
                setCurrentDate(moment())
                onDateChange(moment())
                reset()
              }}
            >
              {t("Today")}
            </Button>
            <Button
              onClick={() => {
                onDateChange(null)
                reset()
              }}
            >
              {t("Clear")}
            </Button>
          </div>
          <div className={"picker__footer-right"}>
            <Button
              onClick={() => {
                setOpen(false)
                reset()
              }}
            >
              {t("Close")}
            </Button>
          </div>
        </PickerFooter>
      </Wrapper>
    )
  }

  return (
    <WarikeDatePickerContainer>
      <Popover
        content={content}
        overlayClassName={"eassy_date_picker"}
        showArrow={false}
        trigger={"click"}
        open={open}
        onOpenChange={(newOpen: boolean) => {
          setOpen(newOpen)
          if (!newOpen) {
            reset()
          }
        }}
        placement={placement}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {invalidDatePicked && (
          <p
            style={{
              padding: 0,
              margin: 0,
              color: theme.red2,
              fontSize: "14px",
            }}
          >
            {t("Date is incorrect")}
          </p>
        )}

        <Input
          value={
            hasNoNewValue
              ? ""
              : inputValue
              ? moment(newDate).isValid()
                ? defaultFormat
                  ? moment(newDate)?.format(defaultFormat)
                  : inputValue
                : inputValue || ""
              : defaultDate
              ? moment(defaultDate).isValid()
                ? moment(defaultDate)?.format(defaultFormat)
                : ""
              : ""
          }
          className={"picker__input"}
          suffix={Icon || <CalendarOutlined />}
          onKeyDown={(e) => {
            if (e?.key === "Enter") {
              if (moment(newDate).isValid()) {
                onDateChange(moment(currentDate))
                reset()
                setOpen(false)
                inputRef?.current?.blur()
                setDefaultFormat(dateFormate)
              } else {
                onDateChange(null)
                reset()
                setOpen(false)
                inputRef?.current?.blur()
              }
            } else {
              if (defaultDate) {
                setDefaultFormat("YYYYMMDD")
              } else {
                setDefaultFormat(null)
              }
            }
          }}
          onChange={({ target: { value } }) => {
            const year = value?.slice(0, 4)
            const month = value?.slice(4, 6)
            const date = value?.slice(6)
            const changedDate = moment([year, +month - 1, date])
            setInputValue(value)
            if (!value) {
              setHasNoValues(true)
            } else {
              if (
                year?.length === 4 &&
                (+year < 1989 || +year > +moment().year() + 10)
              ) {
                setInvalidateYearPicked(true)
                setHasNoValues(false)
              } else {
                setInvalidateYearPicked(false)
                setHasNoValues(false)
                if (value?.length <= 8) {
                  setTimeout(() => {
                    setNewDate(changedDate)
                    if (changedDate?.isValid() && value) {
                      setCurrentDate(changedDate)
                      setDefaultFormat(dateFormate)
                    }
                  }, 900)
                }
              }
            }
          }}
          {...rest}
          autoComplete={"off"}
          ref={inputRef}
        />
      </Popover>
      {error && <div className={"error__msg"}>{error || t("Required")}</div>}
    </WarikeDatePickerContainer>
  )
}
