import React, { useEffect, useState } from "react"
import { Upload, message, notification, Button, Space } from "antd"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import { CloseCircleFilled } from "@ant-design/icons"

import { fileUpload } from "../../../../services"
import { DisplayFile, Wrapper } from "./imageUpload.style"

interface ImageUploadProps {
  onChange?: (fileName?: any, value?: any) => void
  uploadHint?: string
  isLoading?: (value: boolean) => void
  currentImage?: any
  isWidthBigger?: boolean
  isEditable?: boolean
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  onChange,
  uploadHint,
  isLoading,
  currentImage,
  isWidthBigger,
}) => {
  const [file, setFile] = useState(null)
  const [originalFileName, setOriginalFileName] = useState(null)
  const [imageLoadError, setImageLoadError] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    if (currentImage != "") {
      setFile(currentImage)
      setOriginalFileName(
        currentImage?.file?.originFileObj?.name ||
          file?.file?.originFileObj?.name
      )
    }
  }, [currentImage])

  const { isLoading: uploadLoading, mutate } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        isLoading(false)
        onChange && onChange(result?.data, result?.data)
        setFile(result?.data)
      },
      onError: (error?: any) => {
        isLoading(false)
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    }
  )

  const handleDeleteClick = () => {
    setFile(null)
    onChange && onChange(null, null)
  }

  const beforeUpload = (file: any) => {
    const isPdf = file.type == "application/pdf"
    const isLt5M = +(file.size / 1000000).toFixed(2) < 5

    if (!isLt5M) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC image with less than 5MB"
        ),
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
    }
    if (isPdf) {
      return isPdf && isLt5M
    }
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/gif" ||
      file.type === "image/heic"
    if (!isJpgOrPng) {
      message.error({
        content: t("You can only upload JPG/PNG/GIF/HEIC file!"),
        key: "38",
        icon: <CloseCircleFilled onClick={() => message.destroy("38")} />,
      })
    }
    return isJpgOrPng && isLt5M
  }

  const handleChange = async (info) => {
    if (info?.file?.status === "done") {
      const bodyFormData = new FormData()
      bodyFormData.append("file", info?.file?.originFileObj)
      bodyFormData.append("model", "temp")
      mutate(bodyFormData)
      setOriginalFileName(info?.file?.originFileObj?.name)
    }
  }

  return (
    <Wrapper widthMore={isWidthBigger}>
      {!file && (
        <>
          <Upload
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={({ onSuccess }) => {
              onSuccess("ok")
            }}
            accept={"image/*"}
          >
            <div className={"file-area"}>
              <Button
                loading={uploadLoading}
                style={{
                  width: "fit-content",
                }}
              >
                {uploadLoading ? t("Uploading") : t("Select file")}
              </Button>
              <Space className={"min-width-class"}>
                {t("File not selected")}
              </Space>
            </div>
            <p className={"upload-hint"}>
              {uploadHint ||
                t("(Image: jpg,gif,png,HEIC/Size:300x222px/Maximum:640x640px)")}
            </p>
          </Upload>
        </>
      )}
      {file && (
        <DisplayFile>
          <div className={"file-name"}>
            {!imageLoadError ? (
              <img
                src={file}
                onLoad={() => setImageLoadError(false)}
                onError={() => setImageLoadError(true)}
              />
            ) : (
              <p>{originalFileName}</p>
            )}
          </div>
          <br />
          <div className={"delete-btn"} onClick={handleDeleteClick}>
            <Button>{t("Delete")}</Button>
          </div>{" "}
        </DisplayFile>
      )}
    </Wrapper>
  )
}

export { ImageUpload }
