import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { useTranslation } from "react-i18next"
import SignaturePad from "react-signature-canvas"
import styled from "styled-components"
import { theme } from "../../../theme"
import { DEFAULT_DATE_SIGNATURE_PAD_IMAGE } from "../../../../../owner/constants/fallbackImage"

const Wrapper = styled.div<{ signType: string }>`
  padding: 16px 0;
  width: 100% !important;
  .btns-container {
    border-top: 1px solid ${theme.gray2};
    margin-top: 10px;
    padding: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    button {
      padding: 5px 12px;
      width: fit-content;
    }
  }
  .pad-container .pad span {
    position: absolute;
    top: 50%;
    left: 40%;
  }
  .pad-container {
    padding: 0 10px;
    width: 100% !important;
    position: relative;

    .date__signature_pad_container {
      border: 2px solid black;
      border-radius: 5px;
      width: ${({ signType }) =>
        signType === "onlyDate" ? "100% !important" : "450px !important"};
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: ${({ signType }) =>
        signType === "onlyDate" ? "2px solid black" : "none !important"};
      border-bottom-left-radius: ${({ signType }) =>
        signType === "onlyDate" ? "5px" : "0"};
      border-bottom-right-radius: ${({ signType }) =>
        signType === "onlyDate" ? "5px" : "0"};

      span {
        font-weight: bold;
        font-size: 16px;
      }
      button {
        position: absolute;
        top: 8px;
        right: 4px;
        padding: 4px !important;
        width: 50px;
        height: 20px;
        z-index: 30000 !important;

        > span {
          font-size: 12px;
        }
      }

      canvas {
        z-index: 20;
      }
    }
    .pad {
      border: 2px solid black;
      border-radius: 5px;
      width: 100% !important;
      border-top-left-radius: 0px;
      position: relative;
      z-index: 1 !important;

      button {
        position: absolute;
        top: 8px;
        right: 4px;
        padding: 4px !important;
        width: 50px;
        height: 20px;
        > span {
          font-size: 12px;
          position: static;
        }
      }
    }
  }
`
interface IProps {
  onSave: (url: string, dateSign?: string) => void
  isLoading?: boolean
  sign?: string
  signType?: "date" | "name" | "onlyDate"
  dateSign?: string
  isDateSignatureChecked?: boolean
  width?: number
}
export const SignatureCanvas: React.FC<IProps> = ({
  onSave,
  isLoading,
  sign,
  dateSign,
  signType,
  isDateSignatureChecked,
}) => {
  const { t } = useTranslation()
  const signCanvasRef = React.useRef<any>()
  const dateCanvasRef = React.useRef<any>()
  const [isDateSign, setIsDateSign] = useState(
    signType == "date" || signType == "onlyDate"
  )
  const [clearSign, setClearSign] = useState({
    dateSign: false,
    sign: false,
  })
  const saveSignature = async () => {
    const sign = signCanvasRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png")

    if (isDateSign) {
      const dateSignature = dateCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png")

      onSave(
        clearSign?.sign ? "" : sign,
        clearSign?.dateSign ? "" : dateSignature
      )
    } else {
      onSave(
        clearSign?.sign ? "" : sign,
        clearSign?.dateSign || !isDateSignatureChecked ? "" : dateSign
      )
    }
    setIsDateSign(false)
  }
  const clearSignature = () => {
    if (signCanvasRef) {
      signCanvasRef?.current?.clear()
    }
    dateCanvasRef?.current?.clear()
    dateCanvasRef?.current?.fromDataURL(DEFAULT_DATE_SIGNATURE_PAD_IMAGE, {
      width: 425,
      height: 80,
    })
    setClearSign({
      dateSign: true,
      sign: true,
    })
    setIsDateSign(false)
  }
  const clearSignSignature = () => {
    if (signCanvasRef) {
      signCanvasRef?.current?.clear()
    }
    setClearSign({
      ...clearSign,
      sign: true,
    })
  }
  const clearDateSignature = () => {
    setClearSign({
      ...clearSign,
      dateSign: true,
    })
    dateCanvasRef?.current?.clear()
    dateCanvasRef?.current?.fromDataURL(DEFAULT_DATE_SIGNATURE_PAD_IMAGE, {
      width: 425,
      height: 80,
    })
  }
  useEffect(() => {
    if (signCanvasRef?.current != undefined) {
      signCanvasRef?.current.fromDataURL(sign, { width: 425, height: 270 })
    }
    dateCanvasRef?.current?.fromDataURL(
      dateSign || DEFAULT_DATE_SIGNATURE_PAD_IMAGE,
      {
        width: 425,
        height: 80,
      }
    )
  }, [sign, dateSign])

  return (
    <Wrapper signType={signType}>
      <div className={"pad-container"}>
        {signType === "date" || signType === "onlyDate" ? (
          <div className={"date__signature_pad_container"}>
            <SignaturePad
              ref={dateCanvasRef}
              canvasProps={{
                width: 425,
                height: 80,
                className: "sigCanvas",
              }}
              onEnd={() => {
                setIsDateSign(true)
                setClearSign({
                  ...clearSign,
                  dateSign: false,
                })
              }}
            />

            <Button onClick={clearDateSignature} type={"default"} danger>
              {t("Clear")}
            </Button>
          </div>
        ) : null}

        {signType === "onlyDate" ? null : (
          <div className={"pad"}>
            <SignaturePad
              ref={signCanvasRef}
              canvasProps={{
                width: 425,
                height: 270,
                className: "sigCanvas",
              }}
              onEnd={() => {
                setClearSign({
                  ...clearSign,
                  sign: false,
                })
              }}
            />
            <span
              style={{
                pointerEvents: "none",
              }}
            >
              {t("Sign")}
            </span>
            <Button onClick={clearSignSignature} type={"default"} danger>
              {t("Clear")}
            </Button>
          </div>
        )}
      </div>

      <div className={"btns-container"}>
        <Button onClick={clearSignature} type={"default"} danger>
          {t("Clear all")}
        </Button>
        <Button onClick={saveSignature} loading={isLoading}>
          {t("Save")}
        </Button>
      </div>
    </Wrapper>
  )
}
