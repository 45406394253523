/* eslint-disable no-unused-vars */
import React, { useCallback } from "react"
import { Input } from "antd"
import styled from "styled-components"
import { theme } from "../../../theme"
import { CharacterCounter } from "../../../utils"

export interface TextFieldProps {
  id?: string
  placeholder?: string
  type?: string
  onChange?: any
  className?: any
  value?: any
  disabled?: boolean
  prefix?: React.ReactNode
  rows?: number
  fullWidth?: boolean
  width?: string
  height?: string
  showCounting?: boolean
  maxLength?: number
  minLength?: number
  min?: number
  max?: number
  bordercolor?: string
  bgcolor?: string
  color?: string
  error?: any
  name?: string
  borderradius?: string
  placeholdercolor?: string
  label?: string
  addonBefore?: string
  disableboxshadow?: boolean
  required?: boolean
  indent?: string
  ref?: any
  autoComplete?: string
  onKeyDown?: (val: any) => void
  onKeyUp?: (val: any) => void
  onPressEnter?: (e: any) => void
  onKeyPress?: (e: any) => void
  hasListStyle?: boolean
  listStyleLabelColor?: string
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  padding?: string
  resizeable?: boolean
  autoFocus?: boolean
  [x: string]: any
}

const InputStyled = styled(Input)`
  min-height: auto;
  height: ${({ height }: TextFieldProps) => (height ? height : "auto")};
  background-color: ${({ bgcolor }: TextFieldProps) =>
    bgcolor ? bgcolor : " #f3f3f3"};

  box-shadow: none;
  outline: none;
  border: 1px solid #d2d1d1;
  border-radius: ${({ borderradius }: TextFieldProps) =>
    borderradius ? borderradius : "5px"};
  padding: ${({ padding }: TextFieldProps) =>
    padding ? padding : `13px 17px`};
  :hover,
  :focus {
    box-shadow: none;
    border-color: #d2d1d1 !important;
  }
`

const ErrorStyled = styled.span`
  font-size: 12px;
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`

const CountingStyled = styled.div<{ isRed: boolean }>`
  font-size: 12px;
  color: ${({ isRed }) => isRed && theme.alert};
`

const ErrorCounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
`

const TextFieldWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width, fullWidth }: TextFieldProps) =>
    fullWidth ? "100%" : width || "auto"};
  .ant-input {
    &:focus,
    &:hover,
    &:active {
      border-color: #8b94a5;
    }
  }
`

const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 8px;
`

const Label = styled.label<any>`
  ${theme.typography.label};
  margin-right: 10px;
  font-weight: 500;
  .list-style-label {
    font-size: 20px;
    margin-right: 10px;
    color: ${({ listColor }) => listColor || "black"};
  }
`

const RequiredLabel = styled.div`
  ${theme.typography.PackDesc};
  color: #f5222d;
  min-width: 40px;
  height: 22px;
  display: flex;
  justify-content: center;
  background: #fff1f0;
  border: 1px solid #ffa39e;
  border-radius: 2px;
  align-items: center;
`

const TextField: React.FC<TextFieldProps> = React.forwardRef((props, ref) => {
  const {
    id,
    showCounting,
    value,
    maxLength,
    type,
    rows,
    className,
    label,
    required,
    prefix,
    hasListStyle,
    listStyleLabelColor,
    resizeable,
    autoFocus,
    ...rest
  } = props
  const { TextArea } = Input

  const countingUI = useCallback(() => {
    return (
      showCounting &&
      maxLength && (
        <CountingStyled isRed={value?.length >= maxLength}>
          {`${
            value ? CharacterCounter(value) : 0
          }/${maxLength?.toLocaleString()}`}
        </CountingStyled>
      )
    )
  }, [value, maxLength])

  const LabelComponent = useCallback(() => {
    return (
      <LabelContainer>
        <Label listColor={listStyleLabelColor}>
          {hasListStyle && <span className={"list-style-label"}>{"■"}</span>}
          {label}
        </Label>
        {required && <RequiredLabel>{"必須"}</RequiredLabel>}
      </LabelContainer>
    )
  }, [label, required])

  const textFieldUI = () => {
    switch (type) {
      case "password":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && LabelComponent()}
            <InputStyled
              {...rest}
              type={"password"}
              value={value}
              maxLength={maxLength}
              id={id}
            />
            <ErrorCounterWrapper>
              {countingUI()}
              {props.error ? <ErrorStyled>{props.error}</ErrorStyled> : <div />}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
      case "textarea":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && LabelComponent()}
            <InputStyled
              as={TextArea}
              value={value}
              autoSize={!resizeable ? { minRows: rows } : false}
              rows={resizeable ? rows : 0}
              placeholder={props.placeholder}
              maxLength={maxLength}
              id={id}
              autoFocus={autoFocus}
              {...rest}
            />
            <ErrorCounterWrapper>
              {props.error ? <ErrorStyled>{props.error}</ErrorStyled> : <div />}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
      case "number":
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && LabelComponent()}
            <InputStyled
              prefix={prefix}
              value={value}
              type={"number"}
              maxLength={maxLength}
              showCount={showCounting}
              id={id}
              {...rest}
            />
            <ErrorCounterWrapper>
              {props.error ? <ErrorStyled>{props.error}</ErrorStyled> : <div />}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )

      default:
        return (
          <TextFieldWrapperStyled className={className} width={props.width}>
            {label && LabelComponent()}
            <InputStyled
              ref={ref}
              prefix={prefix}
              value={value}
              maxLength={maxLength}
              id={id}
              {...rest}
            />
            <ErrorCounterWrapper>
              {props.error ? <ErrorStyled>{props.error}</ErrorStyled> : <div />}
              {countingUI()}
            </ErrorCounterWrapper>
          </TextFieldWrapperStyled>
        )
    }
  }
  return textFieldUI()
})

export { TextField }
