import { Checkbox, Spin } from "antd"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"

export interface CheckboxOptions {
  label: string
  value: string | number
  disabled?: boolean
}

export interface CheckboxProps {
  name?: string
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void
  options: CheckboxOptions[]
  value?: any
  label?: string
  error?: string
  storeLabelAndValue?: boolean
  disabled?: boolean
  isLoading?: boolean
}

const Wrapper = styled.div`
  .ant-checkbox-wrapper {
    display: inline-flex;
    align-items: center;
    margin-bottom: 6px;
    :hover .ant-checkbox::after {
      visibility: hidden;
    }
  }
  .ant-checkbox {
    top: 0;
    :hover::after {
      visibility: hidden;
    }
    .ant-checkbox-inner {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      width: 25px;
      height: 25px;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background: #cde6f4;
        ::after {
          border-color: #000;
          transition: none;
          top: 50%;
          left: 50%;
          border-width: 0;
          content: url("/assets/icons/checkbox-arrow.svg");
          transform: rotate(0deg) scale(1) translate(-50%, -50%);
        }
      }
    }
  }
`
const ErrorStyled = styled.div`
  font-size: 12px;
  color: ${theme.alert};
  margin-top: 2px;
  margin-left: 2px;
`
const Checkboxes: React.FC<CheckboxProps> = ({
  name,
  onChange,
  options,
  error,
  label,
  value,
  storeLabelAndValue,
  disabled,
  isLoading,
}: CheckboxProps) => {
  const getLabelFromValue = (value: string): string => {
    let label
    options.forEach((option) => {
      if (String(option.value) === value) {
        label = option.label
      }
    })
    return label
  }

  if (isLoading) {
    return <Spin />
  }

  return (
    <Wrapper>
      {label && <p className={"label"}> {label} </p>}
      <div className={"checkbox-group"}>
        <Checkbox.Group
          name={name}
          disabled={disabled}
          onChange={(v) => {
            if (storeLabelAndValue) {
              const valueWithLabel = v.map((each) => {
                return {
                  label: getLabelFromValue(String(each)),
                  value: each,
                }
              })
              onChange(valueWithLabel)
              return
            }
            onChange(v)
          }}
          value={typeof value === "string" ? value.split(",") : value}
          options={options}
        />
        {error && <ErrorStyled>{error}</ErrorStyled>}
      </div>
    </Wrapper>
  )
}

export { Checkboxes }
