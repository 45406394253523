import React, { useCallback } from "react"
import { Button } from "../../atom"
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons"
import { CalendarPaginationWrapper } from "../CalendarPagination"
import { t } from "i18next"

interface ICalendarPagination {
  currentDate: Date
  onNext: (v: Date) => void
  onPrev: (v: Date) => void
  prevText?: string
  nextText?: string
  currentText?: string
  activeText?: string
  setActiveText: (v: any) => void
}

const CalendarPaginationForDay: React.FC<ICalendarPagination> = ({
  currentDate,
  onNext,
  onPrev,
  prevText,
  nextText,
  currentText,
  activeText,
  setActiveText,
}) => {
  const handleYearChange = useCallback(
    (type: string) => {
      const saveDate = currentDate || new Date()

      if (type === "previousYear") {
        setActiveText("previousYear")
        const _date = new Date(saveDate.setDate(saveDate.getDate() - 1))
        return onPrev(_date)
      } else if (type === "nextYear") {
        setActiveText("nextYear")

        const _date = new Date(saveDate.setDate(saveDate.getDate() + 1))
        onNext(_date)
      } else if (type === "currentYear") {
        setActiveText("currentYear")

        const _date = new Date()
        onNext(_date)
      }
    },

    [currentDate]
  )
  return (
    <CalendarPaginationWrapper>
      <div className={"year-pagination-wrapper"}>
        <Button
          onClick={() => handleYearChange("previousYear")}
          className={`left-btn common-btn-styling ${
            activeText === "previousYear" ? "active active-btn" : ""
          }`}
        >
          <DoubleLeftOutlined />
          <span className={"left-btn-label"}>{prevText || "前年"}</span>
        </Button>
        <Button
          onClick={() => handleYearChange("currentYear")}
          className={` active-btn common-btn-styling ${
            activeText === "currentYear" ? "active active-btn" : ""
          }`}
        >
          <span className={"current-btn-label"}>
            {t(currentText) || "今日"}
          </span>
        </Button>
        <Button
          onClick={() => handleYearChange("nextYear")}
          className={`right-btn common-btn-styling ${
            activeText === "nextYear" ? "active active-btn" : ""
          }`}
        >
          <span className={"right-btn-label"}>{nextText || "翌年"}</span>
          <DoubleRightOutlined />
        </Button>
      </div>
    </CalendarPaginationWrapper>
  )
}

export { CalendarPaginationForDay }
