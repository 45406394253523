import { createContext } from "react"

type ContextProps = {
  loading: boolean
  user: any | null
  authenticated: boolean
  setUser: any
  isOwner: boolean
  permissions: any
  facilities: any
  otherFacilities: any
  companyName: string
  refetchOtherFacilities?: () => void
  openKeys: string[]
  setOpenKeys: (val: string[]) => void
  activeMenuKeys: string[]
  setActiveMenuKeys: (val: string[]) => void
  facilitiesWithChildCount: any
  collapsed: boolean
  setCollapsed: (val: boolean) => void
  handleCollapse: (val?: boolean) => void
  notificationCount: number
  refetchNotificationCount: () => void
  unseenNotificationData: any
  refetchFacilities?: () => void
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  isOwner: boolean
  children: React.ReactNode
  permissions: any
  facilities: any
  otherFacilities: any
  companyName: string
  refetchOtherFacilities?: () => void
  openKeys: string[]
  setOpenKeys: (val: string[]) => void
  activeMenuKeys: string[]
  setActiveMenuKeys: (val: string[]) => void
  facilitiesWithChildCount: any
  collapsed: boolean
  setCollapsed: (val: boolean) => void
  handleCollapse: (val?: boolean) => void
  notificationCount?: number
  refetchNotificationCount?: () => void
  unseenNotificationData?: any
  refetchFacilities?: () => void
}

export const AuthProvider = (props: AuthProviderProps) => {
  const {
    loading,
    user,
    setUser,
    children,
    isOwner,
    permissions,
    facilities,
    otherFacilities,
    companyName,
    refetchOtherFacilities,
    openKeys,
    setOpenKeys,
    activeMenuKeys,
    setActiveMenuKeys,
    facilitiesWithChildCount,
    collapsed,
    setCollapsed,
    handleCollapse,
    notificationCount,
    unseenNotificationData,
    refetchNotificationCount,
    refetchFacilities,
  } = props
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        isOwner,
        permissions,
        facilities,
        otherFacilities,
        companyName,
        refetchOtherFacilities,
        openKeys,
        setOpenKeys,
        activeMenuKeys,
        setActiveMenuKeys,
        facilitiesWithChildCount,
        collapsed,
        setCollapsed,
        handleCollapse,
        notificationCount,
        refetchNotificationCount,
        unseenNotificationData,
        refetchFacilities,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
