import React, { FC } from "react"
import { Table as AntTable, TableProps, ConfigProvider } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { theme } from "../../../theme"

interface ITableProps extends TableProps<any> {
  emptyText?: string
  bordered?: boolean
  minWidth?: number
  hover?: boolean
  loading?: boolean
  columns?: any
  rowClassName?: any
}

const MainWrapper = styled.div<any>`
  .empty-cell {
    margin: auto;
  }
  .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th {
    text-align: center;
    border: ${(props) => (props.bordered ? "" : ` 0.5px solid #d2d1d1`)};
  }
  @media print {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      text-align: left;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table {
      visibility: visible;
      height: auto !important;
    }
  }
  .ant-table-expanded-row-fixed {
    @media print {
      width: auto !important;
      border-bottom: 1px solid ${theme.black};
    }
  }

  @media screen and (max-width: ${theme.breakpoints.sm}) {
    .ant-table-container {
      table {
        th,
        td {
          padding: 8px;
        }
      }
    }
  }
`

const Table: FC<ITableProps> = ({
  dataSource,
  columns,
  loading,
  locale,
  size,
  emptyText,
  scroll,
  bordered,
  minWidth,
  rowClassName = null,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <MainWrapper minWidth={minWidth} bordered={bordered}>
      <ConfigProvider
        renderEmpty={() => (
          <p className={"empty-cell"}>{emptyText || t("No Data Found")}</p>
        )}
      >
        <AntTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          locale={locale}
          size={size}
          scroll={scroll}
          bordered={bordered ? true : false}
          rowClassName={rowClassName}
          {...rest}
        />
      </ConfigProvider>
    </MainWrapper>
  )
}

export { Table }
