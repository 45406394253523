import {
  analytics,
  API,
  auth,
  clearLocalStorageItem,
  DEV_ENVIRONMENTS,
  i18next,
  LanguageOption,
} from "@project/shared"
import * as Sentry from "@sentry/node"
import { logEvent, setCurrentScreen } from "firebase/analytics"
import { onAuthStateChanged, signOut } from "firebase/auth"
import { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import NextNProgress from "nextjs-progressbar"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { QueryClient, QueryClientProvider } from "react-query"
import { getAllFacilitiesWithChildCount } from "../services/facility"
import { AuthProvider, catchExceptionCallback, GlobalStyles } from "../utils"
import "../utils/css-imports"

import { ConfigProvider } from "antd"
import jaJP from "antd/lib/locale/ja_JP"
import { FilterAccessibleFacilitiesByPage } from "../utils/PageAccessUtils"

const queryClient = new QueryClient({ defaultOptions: {} })

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `owner-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}
const fetchFacilities = async () =>
  API.get("/facilities?pageSize=Infinity&sort=CreateDateTime")
const fetchJointFacility = async () =>
  API.get("/joint-facility?pageSize=Infinity&page=1")

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter()
  const isCollapsed =
    typeof window !== "undefined"
      ? localStorage.getItem("collapse_menu") === "true"
      : false
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [isOwner, setIsOwner] = useState(false)
  const [permissions, setPermissions] = useState(null)
  const [facilities, setFacilities] = useState(null)
  const [otherFacilities, setOtherFacilities] = useState(null)
  const [facilitiesWithChildCount, setFacilitiesWithChildCount] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [activeMenuKeys, setActiveMenuKeys] = useState(["home"])
  const [openKeys, setOpenKeys] = useState(["home"])
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const { t } = useTranslation()
  const allFacilities = useRef(null)

  const ModalIssueUrlsCheckList = ["recipient-certificate-form"]

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logAnalyticsEvent = (url: string) => {
        setCurrentScreen(analytics, url)
        logEvent(analytics, "screen_view", {
          firebase_screen: url,
          firebase_screen_class: "skeleton-owner",
        })
      }

      routers.events.on("routeChangeComplete", (url) => {
        logAnalyticsEvent(url)
      })

      logAnalyticsEvent(window.location.pathname)
      return () => {
        routers.events.off("routeChangeComplete", logAnalyticsEvent)
      }
    }
  }, [])

  const dispatchUser = () => {
    signOut(auth)
    setUser(null)
  }
  const nonAuthPages = ["/login", "/forget-password"]

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const idToken = await user!.getIdTokenResult()
          if (
            idToken.claims["company_admin"] ||
            idToken.claims["isCompanyOwner"] ||
            idToken.claims["company_staff"]
          ) {
            setUser(user)
            if (
              idToken.claims["company_admin"] ||
              idToken.claims["isCompanyOwner"]
            ) {
              setIsOwner(true)
            }
            const permissionResponse = await API.get("/get_my_permissions")
            setPermissions(permissionResponse.data)
            const facilitiesResponse = await fetchFacilities()
            allFacilities.current = facilitiesResponse?.data
            setFacilities(facilitiesResponse?.data)
            const facilityOtherResponse = await fetchJointFacility()
            setOtherFacilities(facilityOtherResponse.data)
            const facilityWithCount = await getAllFacilitiesWithChildCount()
            setFacilitiesWithChildCount(facilityWithCount)
            const companyResponse = await API.get(
              `/company/${idToken.claims.companyId}`
            )
            setCompanyName(companyResponse.data?.company_name)
          }
        } else {
          clearLocalStorageItem()
          // show login screen if not authenticated
          if (!nonAuthPages.includes(window.location.pathname)) {
            window.location.href = "/login"
          }
        }
      } catch (error) {
        let message = error
        if (error?.status == 423) {
          message = t(
            "The account has been locked. Please contact administration"
          )
        }
        if (error?.status == 401) {
          message = "Access is congested. Please wait a while and try again"
        } else {
          // undefined error such as cancellation of request due to navigation is causing sign out in safari
          // so logout user only if error is defined
          error && dispatchUser()
        }
        catchExceptionCallback(message)
        Sentry.captureException(error)
      } finally {
        setLoading(false)
      }
    })

    return () => unsubscribe && unsubscribe()
  }, [])
  useEffect(() => {
    if (!collapsed) {
      if (activeMenuKeys?.length) {
        const activeMenus = [...activeMenuKeys].slice(
          0,
          activeMenuKeys.length - 1
        )
        setOpenKeys(activeMenus)
      }
    }
  }, [collapsed, activeMenuKeys])

  useEffect(() => {
    if (!isOwner && allFacilities.current && permissions) {
      setFacilities(
        FilterAccessibleFacilitiesByPage(
          allFacilities.current,
          permissions?.user_facility_permissions,
          routers.pathname
        )
      )
    }
  }, [routers.pathname, permissions, allFacilities.current, isOwner])

  const handleCollapse = (value: boolean) => {
    const val = value ?? !collapsed
    localStorage.setItem("collapse_menu", val ? "true" : "false")
    setCollapsed(val)
  }

  return (
    <>
      <Head>
        <title></title>
        <meta
          name={"viewport"}
          content={
            "width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          }
        />
        <link rel={"preconnect"} href={"https://fonts.gstatic.com"} />
        <link
          href={
            "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap"
          }
          rel={"stylesheet"}
        />
      </Head>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <NextNProgress
          color={"#F7CD48"}
          height={8}
          showOnShallow={true}
          options={{
            showSpinner: false,
          }}
        />
        <AuthProvider
          loading={loading}
          user={user}
          isOwner={isOwner}
          setUser={setUser}
          permissions={permissions}
          facilities={facilities}
          refetchFacilities={async () => {
            const facilitiesResponse = await fetchFacilities()
            setFacilities(facilitiesResponse?.data)
          }}
          otherFacilities={otherFacilities}
          companyName={companyName}
          refetchOtherFacilities={async () => {
            const other = await fetchJointFacility()
            setOtherFacilities(other?.data)
          }}
          setOpenKeys={setOpenKeys}
          openKeys={openKeys}
          activeMenuKeys={activeMenuKeys}
          setActiveMenuKeys={setActiveMenuKeys}
          facilitiesWithChildCount={facilitiesWithChildCount}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          handleCollapse={handleCollapse}
        >
          <ConfigProvider
            locale={i18next.language === "ja" && jaJP}
            getPopupContainer={(node) => {
              // check for specific pages only else issue on other pages
              if (
                node &&
                ModalIssueUrlsCheckList.find((item) =>
                  window.location.href.includes(item)
                )
              ) {
                return node.parentElement
              }
              return document.body
            }}
          >
            <Component {...pageProps} />
            {DEV_ENVIRONMENTS.includes(process.env.NEXT_PUBLIC_ENVIRONMENT) ? (
              <LanguageOption />
            ) : (
              <></>
            )}
          </ConfigProvider>
        </AuthProvider>
      </QueryClientProvider>
    </>
  )
}

export default MyApp
