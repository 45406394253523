import * as Sentry from "@sentry/node"
import { notification } from "antd"
import axios from "axios"
import { auth } from "./firebase"

axios.defaults.responseType = "json"

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
})

const openAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_APP_API_URL,
})

API.interceptors.request.use(
  async (config) => {
    if (typeof sessionStorage !== "undefined") {
      if (sessionStorage.getItem("X-Agent-User")) {
        config.headers["X-Agent-User"] = sessionStorage?.getItem("X-Agent-User")
      }
    }
    const token = await auth.currentUser?.getIdToken(true)

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }
    const idTokenResult = await auth.currentUser?.getIdTokenResult(true)
    config.headers["Company-ID"] =
      typeof idTokenResult.claims.companyId === "object"
        ? JSON.stringify(idTokenResult.claims.companyId)
        : idTokenResult.claims.companyId
    if (idTokenResult?.claims?.company_child) {
      config.headers["Child-ID"] =
        typeof idTokenResult.claims.childId === "object"
          ? JSON.stringify(idTokenResult.claims.childId)
          : idTokenResult.claims.childId
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    if (error?.response?.status === 401) {
      //auth.signOut()
      notification.error({
        message: "Access is congested. Please wait a while and try again.",
      })
    }
    Sentry.captureException(error)
    return Promise.reject(error?.response)
  }
)

const AdultAPI = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ADULT_APP_API_URL,
})

AdultAPI.interceptors.request.use(
  async (config) => {
    if (typeof sessionStorage !== "undefined") {
      if (sessionStorage.getItem("X-Agent-User")) {
        config.headers["X-Agent-User"] = sessionStorage?.getItem("X-Agent-User")
      }
    }
    const token = await auth.currentUser?.getIdToken(true)

    if (token) {
      config.headers["Authorization"] = "Bearer " + token
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

AdultAPI.interceptors.response.use(
  (response) => {
    return response?.data
  },
  (error) => {
    if (error?.response?.status === 401) {
      //auth.signOut()
      notification.error({
        message: "Access is congested. Please wait a while and try again.",
      })
    }
    Sentry.captureException(error)
    return Promise.reject(error?.response)
  }
)

export { AdultAPI, API, openAPI }
// adding comments to trigger deployment
