import { Select, Space } from "antd"
import moment from "moment"

import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import {
  generateJapaneseEraAndYears,
  generateMonthsAccordingToEra,
} from "../../../utils"

const StyledSpace = styled(Space)`
  .selector__container {
    .dob_selector {
      .ant-select-selector {
        border-radius: 6px !important;
      }
      margin-right: 4px;
    }
  }
`

const generateMonthDays = (year, month) => {
  const totalDays = +moment(`${year}-${month}`, "YYYY-MM").daysInMonth() || 31
  const days = []
  for (let index = 0; index <= totalDays - 1; index++) {
    days?.push({
      label: index + 1,
      value: `0${index + 1}`.slice(-2),
    })
  }
  return days
}
interface BirthdayPickerProps {
  year?: number | string | null
  month?: number | string | null
  day?: number | string | null
  onChange: (key: string, value: string | number, date?: moment.Moment) => void
  [x: string]: any
}
export const BirthdayPicker = ({
  month,
  year,
  day,
  onChange,
  ...rest
}: BirthdayPickerProps) => {
  const { t } = useTranslation()
  const eraYears = useMemo(() => generateJapaneseEraAndYears(1989), [])
  const months = useMemo(
    () => generateMonthsAccordingToEra(+year, +month),
    [year, month]
  )
  const getYearValue = (y, m) => {
    if (y === 2019 && m <= 4) {
      return `2019-1`
    } else if (y === 2019 && m >= 5) {
      return `2019-2`
    }
    return `${y}`
  }

  return (
    <StyledSpace wrap>
      <div className={"selector__container"}>
        <Select
          value={year ? getYearValue(+year, +month) : ""}
          onChange={(value) => {
            const newYear = value?.split("-")
            const date = moment(`${newYear[0]}-${month}-${day}`)
            if (newYear[0] === "2019" && newYear[1] === "1") {
              onChange("birth_month", 1, date)
            }
            if (newYear[0] === "2019" && newYear[1] === "2") {
              onChange("birth_month", 5, date)
            }
            onChange("birth_year", newYear[0], date)
          }}
          style={{ minWidth: 120 }}
          className={"dob_selector"}
          size={"large"}
          options={eraYears}
          showSearch
          optionFilterProp={"children"}
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          {...rest}
        />
        <span> {t("年")}</span>
      </div>

      <div className={"selector__container"}>
        <Select
          value={month}
          onChange={(value: string) => {
            const date = moment(`${year}-${value}-${day}`)
            onChange("birth_month", value, date)
          }}
          style={{ minWidth: 60 }}
          className={"dob_selector"}
          size={"large"}
          options={months}
          showSearch
          {...rest}
        />
        <span>{t("月")}</span>
      </div>
      <div className={"selector__container"}>
        <Select
          value={day}
          onChange={(value) => {
            const date = moment(`${year}-${month}-${value}`)
            onChange("birth_day", value, date)
          }}
          style={{ minWidth: 60 }}
          className={"dob_selector"}
          size={"large"}
          options={generateMonthDays(year, month)}
          showSearch
          {...rest}
        />
        <span>{t("日")}</span>
      </div>
    </StyledSpace>
  )
}
