/**
 *this is the function to convert normal text to
 *japanese half width text
 * @param value
 * @returns
 */
export const convertToHalfWidth = (value) => {
  return value.replace(/[！-～]/g, (halfwidthChar) =>
    String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0)
  )
}
