import { Card, Col, Row, Skeleton, Tooltip } from "antd"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import Link from "next/link"
import { useTranslation } from "react-i18next"

export type InfoGridderShape = {
  title: string
  iconPath?: string
  isActive?: boolean
  current?: number
  total?: number
  info?: string
  cancelWaiting?: string
  link?: string
  hasLink?: boolean
  tooltip?: string
}
interface InfoGridderProps {
  title: string
  items: InfoGridderShape[]
  isLoading?: boolean
  activeFacilityId?: string | number
}

interface StyledColProps {
  isActive: boolean
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-typography {
    font-size: 20px;
    font-weight: 500;
    color: #191919;
    margin-bottom: 20px;
  }

  .inactive-link {
    pointer-events: none;
    cursor: default;
  }

  .ant-card {
    cursor: default !important;
  }

  .active-link {
    cursor: default;
  }

  .text-underline {
    cursor: pointer;
    position: relative;
  }

  .text-underline::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 2px;
    background-color: #000;
  }
`
const StyledCol = styled(Col)<StyledColProps>`
  flex-basis: 50%;
  @media (max-width: 1030px) {
    flex-basis: 100%;
  }
  .ant-card {
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ isActive }) => (isActive ? "#fff4d1" : "#f3f3f3")};
    border-radius: 12px;
  }
  .ant-card-body {
    padding: 13px 3px;
    .ant-card-meta {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .ant-card-meta-avatar {
        padding-right: 0;
        .ant-avatar {
          justify-content: center;
          display: flex;
          align-items: center;
          background: ${({ isActive }) => (isActive ? "#fff4d1" : "#f3f3f3")};
          width: 50px;
          height: 50px;
          img {
            width: 50%;
            height: 50%;
            margin: 18px;
          }
        }
      }
      .ant-card-meta-title {
        margin-top: 13px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        white-space: normal;
        color: #191919;
      }
    }
  }
`
interface StyledCardProps {
  titleIconPath?: string
}
const StyledCard = styled(Card)<StyledCardProps>`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  .ant-card-head {
    margin: 0 30px;
    padding: 8px 0 0;
    border: none;
    .ant-card-head-wrapper {
      border-bottom: 1px solid #d2d1d1;
      padding-bottom: 8px;
    }
    .ant-card-head-title {
      padding: 0;
      padding-left: ${({ titleIconPath }) => (titleIconPath ? "23px" : 0)};
      background: ${({ titleIconPath }) =>
        titleIconPath
          ? `url(${titleIconPath}) no-repeat left center / 18px 18px`
          : ""};
    }
    .ant-card-extra {
      padding: 0;
    }
  }
  .ant-card-body {
    border-radius: 0 0 12px 12px;
    margin: 0 6px !important;
    padding: 5px 24px 10px;
    .flex-space {
      display: flex;
      div {
        align-self: center;
        flex: 44%;
        &.info-wrapper {
          color: #e00000;
          font-size: 14px;
          font-weight: 400;
          flex: 48%;
          text-indent: -20px;
          margin-left: -20px;
        }
      }
      .display-num {
        font-size: 22px;
        font-weight: 500;
        color: #e26827;
      }
      .display-cap {
        font-size: 22px;
        font-weight: 500;
      }
      .info-icon {
        margin-right: 5px;
      }
    }
  }
`

const InfoGridder: React.FC<InfoGridderProps> = ({ items, isLoading }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Row gutter={[20, 20]}>
        {items.map((item, index) => (
          <StyledCol key={index} isActive={item.isActive}>
            <StyledCard title={item.title} titleIconPath={item.iconPath}>
              {isLoading ? (
                <Skeleton.Input
                  active
                  size={"small"}
                  style={{
                    background: theme.gray,
                  }}
                />
              ) : (
                <div className={"flex-space"}>
                  {item.title === t("Capacity") ? (
                    <div>
                      <span className={"display-cap"}>{item.current}</span>
                      {" 名 "}

                      {Number(item?.total) > -1 && (
                        <>
                          <>
                            {" / "}
                            <span className={"display-cap"}>
                              {item?.total || 0}
                            </span>
                            {" 名 "}
                          </>
                        </>
                      )}
                    </div>
                  ) : (
                    <Link href={item?.link || ""} prefetch={item?.hasLink}>
                      <div
                        className={
                          item?.hasLink ? "active-link" : "inactive-link"
                        }
                      >
                        <Tooltip title={item.tooltip}>
                          <span className={"text-underline"}>
                            <span className={"display-num"}>
                              {item.current}
                            </span>
                            {" 名 "}

                            {Number(item?.total) > -1 && (
                              <>
                                <>
                                  {" / "}
                                  <span className={"display-num"}>
                                    {item?.total || 0}
                                  </span>
                                  {" 名 "}
                                </>
                              </>
                            )}
                          </span>
                        </Tooltip>
                      </div>
                    </Link>
                  )}

                  {Number(item?.current) > 0 && item?.info && (
                    <div className={"info-wrapper"}>
                      <img
                        src={"assets/icons/error-info.svg"}
                        className={"info-icon"}
                      />
                      {t(item.info)}
                    </div>
                  )}
                  {item?.cancelWaiting ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <span>{t("Cancel waiting")}</span>
                      {/* cancel waiting */}
                      <div>
                        <span className={"display-num"}>
                          {item?.cancelWaiting || 0}
                        </span>
                        {"名"}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </StyledCard>
          </StyledCol>
        ))}
      </Row>
    </Wrapper>
  )
}

export { InfoGridder }
