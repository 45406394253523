export const setItemToLocalstorage = (page: string, values: any) => {
  if (typeof window !== undefined && window.localStorage) {
    const previous = JSON.parse(localStorage.getItem("eassy_storage"))

    localStorage.setItem(
      "eassy_storage",
      JSON.stringify({
        ...previous,
        [page]: values,
      })
    )
  }
}
export const getLocalstorageItem = (page: string, key?: string) => {
  if (typeof window !== undefined && window.localStorage) {
    const items = JSON.parse(localStorage.getItem("eassy_storage"))?.[page]
    return items ? (key ? items[key] : items) : null
  }
}

export const clearLocalStorageItem = () => {
  localStorage.clear()
}
