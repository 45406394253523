import React, { useCallback } from "react"
import { Button } from "../../atom"
import { Pagination } from "../Pagination"
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { t } from "i18next"

interface ICalendarPagination {
  showMonth?: boolean
  currentYear: number
  currentMonth: number
  fixedCurrentYear?: number
  onMonthChange?: (month: number) => void
  onYearChange: (year: number) => void
  prevText?: string
  nextText?: string
  currentText?: string
}

export const CalendarPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .year-pagination-wrapper {
    button {
      background: white;
      color: black;
      border: 1px solid #d2d1d1;
      border-radius: 10px;
      height: 40px;
      margin-right: 5px;
      padding: 0px;
      width: 70px;
      &.active {
        background: rgba(7, 130, 200, 0.2);
      }
    }
    .common-btn-styling button {
      width: auto;
      height: auto;
      padding: 8px 14px;
      span {
        line-height: 100%;
      }
    }
    .right-btn {
      margin-right: 0px;
    }
  }
  @media (max-width: 900px) {
    .year-pagination-wrapper {
      display: flex;
    }
  }
  @media print {
    display: none;
  }
`

const CalendarPagination: React.FC<ICalendarPagination> = ({
  showMonth,
  currentYear,
  fixedCurrentYear,
  onMonthChange,
  onYearChange,
  currentMonth = 1,
  prevText,
  nextText,
  currentText,
}) => {
  const handleYearChange = useCallback(
    (type: string) => {
      if (type === "previousYear") {
        return onYearChange(currentYear - 1)
      } else if (type === "nextYear") {
        return onYearChange(currentYear + 1)
      } else if (type === "currentYear") {
        return onYearChange(new Date().getFullYear())
      }
      return onYearChange(fixedCurrentYear || currentYear)
    },

    [currentYear]
  )
  return (
    <CalendarPaginationWrapper>
      <div>
        {showMonth && (
          <Pagination
            current={currentMonth}
            pageSize={1}
            total={12}
            showTitle={false}
            onChange={onMonthChange}
            prevText={prevText}
            nextText={nextText}
          />
        )}
      </div>
      <div className={"year-pagination-wrapper"}>
        <Button
          onClick={() => handleYearChange("previousYear")}
          className={"left-btn"}
        >
          <DoubleLeftOutlined />
          <span className={"left-btn-label"}>{prevText || "前年"}</span>
        </Button>
        <Button
          onClick={() => handleYearChange("currentYear")}
          className={"active active-btn"}
        >
          <span className={"current-btn-label"}>
            {t(currentText) || "今年"}
          </span>
        </Button>
        <Button
          onClick={() => handleYearChange("nextYear")}
          className={"right-btn"}
        >
          <span className={"right-btn-label"}>{nextText || "翌年"}</span>
          <DoubleRightOutlined />
        </Button>
      </div>
    </CalendarPaginationWrapper>
  )
}

export { CalendarPagination }
