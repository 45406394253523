import React from "react"
import styled, { css } from "styled-components"
import { theme } from "../../../theme"

interface ITopRoundedWhiteCard {
  title?: string
  children: React.ReactNode
  className?: string
  headerElement?: JSX.Element
  noTitleOnPrint?: boolean
}

const Wrapper = styled.div<{ noTitle?: boolean }>`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  padding: 13px 30px 20px 30px;
  background: #ffffff;
  .title-wrapper {
    border-bottom: 1px solid #d2d1d1;
    p {
      color: #191919;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      padding-bottom: 11px;
      margin: 0px;
      word-wrap: break-word;
    }
    ${(props) =>
      props.noTitle &&
      css`
        @media print {
          display: none;
        }
      `}
  }

  /* This behavior seems to be common all over the design I'm changing it directly inside 
     of the shared component.
  */
  @media (max-width: ${theme.breakpoints.md}) {
    padding: 13px 20px 20px 20px;
  }
`

const TopRoundedWhiteCard: React.FC<ITopRoundedWhiteCard> = ({
  title,
  children,
  className,
  headerElement,
  noTitleOnPrint,
}) => {
  return (
    <Wrapper className={className} noTitle={noTitleOnPrint}>
      <div className={"title-wrapper"}>
        {!headerElement ? <p className={"title"}>{title}</p> : headerElement}
      </div>
      <div>{children}</div>
    </Wrapper>
  )
}

export { TopRoundedWhiteCard }
