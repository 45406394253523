import { Typography } from "antd"
import React from "react"
import { SelectInput } from "../SelectInput"
import styled from "styled-components"
import { theme } from "../../../theme"
import { Option } from "../../../utils/typesAndInterface"

export const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  .time_sep,
  .range_sep {
    font-size: 18px;
    color: ${theme.black};
    font-weight: bold;
  }
  .extra-text {
    font-size: 14px;
    color: ${theme.black};
    font-weight: 500;
    padding-left: 3px;
  }
`

interface TimeRangesProps {
  width?: number
  height?: number
  placeholder?: string
  handleChange?: (val: string, unit: string) => void
  hoursOptions: Option[]
  minutesOptions: Option[]
  range: "from" | "to"
  unit: ["hours", "minutes"]
  disabled?: boolean
  typoText?: string
  values?: string
  className?: string
}

export const TimeRanges: React.FC<TimeRangesProps> = ({
  hoursOptions,
  minutesOptions,
  handleChange,
  unit,
  disabled,
  typoText,
  values,
  className = "",
  ...rest
}) => {
  const TypoStyle = {
    fontSize: typoText ? "14px" : "18px",
    fontWeight: typoText ? "500" : "bold",
  }
  const splitVal = values?.split(":")
  return (
    <Container className={className}>
      <SelectInput
        height={40}
        options={hoursOptions}
        onChange={(val) => handleChange(val, unit[0])}
        disabled={disabled}
        value={splitVal?.length === 2 ? splitVal[0] : ""}
        {...rest}
        padding={"0 5px"}
        className={"left-align"}
      />{" "}
      <Typography.Text className={typoText ? "extra-text" : "time_sep"}>
        {typoText ? <>{typoText}</> : <span style={TypoStyle}>{":"}</span>}
      </Typography.Text>
      <SelectInput
        height={40}
        options={minutesOptions}
        {...rest}
        onChange={(val) => handleChange(val, unit[1])}
        disabled={disabled}
        value={splitVal?.length === 2 ? splitVal[1] : ""}
        padding={"0 5px"}
        className={"left-align"}
      />
      {typoText && (
        <Typography.Text className={"extra-text"}>{"分"}</Typography.Text>
      )}
    </Container>
  )
}
