type Opts = {
  label: string
  value: number
  furigana: string
}
export const filterChildByFurigana = (options: Opts[], furigana: string) => {
  let data = options
  if ((furigana && Number(furigana) !== 0) || (furigana && furigana !== "--")) {
    const regex = new RegExp(`^${furigana}`)
    data = options?.filter((option) => option?.furigana?.match(regex))
  }
  return data
}
