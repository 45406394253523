//japanese date(era-month)
const formatter = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
  era: "long",
  year: "numeric",
})
const getEraAndYears = (year) => {
  const eras = new Set()
  const years = new Set()
  for (let month = 0; month < 12; month++) {
    const date = new Date(Date.UTC(year, month, 1))
    const era = formatter.format(date)
    eras.add(era?.slice(0, -1))
    if (year === 2019) {
      years.add("2019-1")
      years.add("2019-2")
    } else {
      years.add(`${year}`)
    }
  }

  return {
    era: Array.from(eras),
    years: Array.from(years),
  }
}
export const generateJapaneseEraAndYears = (down?: number, up?: number) => {
  let startYear = down || 1989
  const endYear = up || new Date().getFullYear()
  const eras = []
  const years = []
  while (startYear <= endYear) {
    const data = getEraAndYears(startYear)
    eras.push(data?.era)
    years.push(data?.years)
    startYear++
  }
  const options = eras
    ?.flat()
    .slice(1)
    ?.map((val, index) => ({
      label: val,
      value: years?.flat()[index],
    }))
  return options || []
}
//generate months
export const generateMonthsAccordingToEra = (year, month) => {
  const months = []
  for (let index = 0; index <= 11; index++) {
    months?.push({
      label: index + 1,
      value: `0${index + 1}`.slice(-2),
    })
  }
  if (year === 2019 && month <= 4) {
    return months?.slice(0, 4)
  }
  if (year === 2019 && month >= 5) {
    return months?.slice(4)
  }
  return months
}
