import { t } from "i18next"
import React from "react"
import BillingManagementIcon from "../public/assets/icons/BillingManagementIcon.svg"
import RevenueManagementIcon from "../public/assets/icons/reveneu-management-icon.svg"
import FacilityOperationIcon from "../public/assets/icons/FacilityOperationIcon.svg"
import FormManagementIcon from "../public/assets/icons/FormManagementIcon.svg"
import HomeIcon from "../public/assets/icons/HomeIcon.svg"
import MasterAdminIcon from "../public/assets/icons/MasterAdminIcon.svg"
import MyPageSettingIcon from "../public/assets/icons/MyPageSettingIcon.svg"

import {
  ActualCostMasterPermission,
  BusinessListPermission,
  ActualCostSchedulePermission,
  ChildAttendanceManagementPermission,
  ChildCarePermission,
  ConsultationSupportMasterPermission,
  DailyBusinessReportPermission,
  FacilityInformationPermission,
  IndividualSupportPlanPermission,
  InstructorMasterPermission,
  JointFacilityMasterPermission,
  MonthActivitiesPermission,
  NurserySchoolMasterPermission,
  ProgramMasterPermission,
  ProgressManagementMasterPermission,
  ProvisionCityMasterPermission,
  RecipientCertificatePermission,
  ServiceProvisionMasterPermission,
  ServiceProvisionRecordPermission,
  ShiftMasterPermission,
  ShuttleCarMasterPermission,
  StaffAttendanceRecordPermission,
  TransportationManagementPermission,
  ProgramRecordPermission,
  MinutesAdditionPermission,
  ContractReportPermission,
  SelfEvalFormPermission,
  AdditionalItemChildPermission,
  MaximumAmountManagementResultInformationManagementPermission,
  AdditionalItemFacilityPermission,
  ActualCostBurdenPermission,
  NotesFromParentsPermission,
  ParentManagementPermission,
  MunicipalSubsidiaryPermission,
  JointFacilityStatusManagementPermission,
  FacilityTourAppManagementPermission,
  MyPagePermission,
  RevenueManagementPermission,
  ProgramProgressRecordPermission,
} from "./PermissionKeys"

interface ISideBarChildren {
  key: string
  label: string
  link?: string
  requiresPermission?: boolean
  permissionKeys?: Array<any>
  children?: Array<ISideBarChildren>
}

interface ISideBar {
  key: string
  icon: any
  label: string
  link?: string
  requiresPermission: boolean
  hasTopMenuPermission?: boolean
  children?: Array<ISideBarChildren>
  permissionKeys?: Array<any>
}

const READPERMISSION = "1"
const WRITEPERMISSION = "2"

type PermissionType = "write" | "both"

// if value is 1 or 2 permission is set for menu
const handleStringPermission = (
  value: string,
  permissionType: PermissionType
) => {
  switch (permissionType) {
    case "both":
      return value === READPERMISSION || value === WRITEPERMISSION
    case "write":
      return value === WRITEPERMISSION
  }
  return false
}

// if permission is object then further drill down is necessary
const handleObjectPermission = (currentPermission: any, nextKey: string) => {
  return currentPermission[nextKey]
}

// if permission is array then further drill down is necessary treating each element as object
const handleArrayPermission = (
  currentPermission: Array<any>,
  nextKeys: Array<any>,
  permissionType: PermissionType
) => {
  let newPermissionObject: any
  for (let i = 0; i < currentPermission.length; i++) {
    newPermissionObject = currentPermission[i]
    if (typeof newPermissionObject === "string")
      return handleStringPermission(newPermissionObject, permissionType)
    else if (typeof newPermissionObject === "object") {
      let currentLoop = 0
      while (
        typeof newPermissionObject === "object" &&
        currentLoop < nextKeys.length
      ) {
        newPermissionObject = handleObjectPermission(
          newPermissionObject,
          nextKeys[currentLoop]
        )
        currentLoop++
      }
      if (
        typeof newPermissionObject === "string" &&
        handleStringPermission(newPermissionObject, permissionType)
      )
        return true
    }
  }
  return false
}

const hasPermissionForMenu = (
  permissions: any,
  permissionsForMenu: Array<any>,
  permissionType: PermissionType = "both"
) => {
  if (typeof permissions !== "object" || !permissions) return false
  let permissionList = [],
    loopFor = 1

  if (Array.isArray(permissionsForMenu[0])) {
    loopFor = permissionsForMenu.length
    permissionList = permissionsForMenu
  } else {
    permissionList.push(permissionsForMenu)
  }

  let currentPermissionObject: any
  for (let eachPermission = 0; eachPermission < loopFor; eachPermission++) {
    const permissionKeys = permissionList[eachPermission]
    for (let i = 0; i < permissionKeys.length; i++) {
      const currentPermissionKey = permissions[permissionKeys[i]]
      if (!currentPermissionObject)
        currentPermissionObject = currentPermissionKey

      if (
        typeof currentPermissionObject === "string" &&
        handleStringPermission(currentPermissionObject, permissionType)
      )
        return true
      else if (
        Array.isArray(currentPermissionObject) &&
        handleArrayPermission(
          currentPermissionObject,
          permissionKeys.filter((key, index) => index > i),
          permissionType
        )
      ) {
        return true
      } else if (typeof currentPermissionObject === "object") {
        currentPermissionObject = handleObjectPermission(
          currentPermissionObject,
          i < permissionKeys.length ? permissionKeys[i + 1] : ""
        )
      }
    }
  }
  return false
}

const hasPermissionForFacility = (
  permissions: any,
  permissionsForMenu: Array<any>,
  permissionType: PermissionType = "both",
  facilityId: number | string
) => {
  const facilityPermissions = permissions?.user_facility_permissions?.find(
    (facilityPermission) => facilityPermission?.facility_id == facilityId
  )

  if (!facilityPermissions) return false

  return hasPermissionForMenu(
    { user_facility_permissions: [facilityPermissions] },
    permissionsForMenu,
    permissionType
  )
}

const sideBarItems: Array<ISideBar> = [
  {
    key: "home",
    icon: React.createElement(HomeIcon),
    label: t("Home"),
    link: "/",
    requiresPermission: false,
  },
  {
    key: "FacilityOperation",
    icon: React.createElement(FacilityOperationIcon),
    label: t("Facility operation"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "userAttendanceTable",
        label: t("userAttendanceTable"),
        link: "/user-attendance-table",
        requiresPermission: true,
        permissionKeys: ChildAttendanceManagementPermission,
      },
      {
        key: "userServiceProvision",
        label: t("User-Service provision record/Care record/Life record"),
        link: "/user-service-provision-result",
        requiresPermission: true,
        permissionKeys: ServiceProvisionRecordPermission,
      },
      {
        key: "userProgramProgressRecord",
        label: t("Program progress record"),
        link: "/program-progress-record-list",
        requiresPermission: true,
        permissionKeys: ProgramProgressRecordPermission,
      },
      {
        key: "userTransportManagement",
        label: t("User transport management"),
        link: "/user-transport-management",
        requiresPermission: true,
        permissionKeys: TransportationManagementPermission,
      },
      {
        key: "dailyBusinessReport",
        label: t("Daily Business Report"),
        link: "/daily-business-report",
        requiresPermission: true,
        permissionKeys: DailyBusinessReportPermission,
      },
      {
        key: "notesFromParents",
        label: t("Notes from parents"),
        link: "/contact",
        requiresPermission: true,
        permissionKeys: NotesFromParentsPermission,
      },
      {
        key: "attendanceSchedule",
        label: t("Attendance schedule"),
        link: "/attendance-schedule",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_operation_permissions",
          "attendance_schedule",
        ],
      },
      {
        key: "attendanceRecord",
        label: t("Attendance record table"),
        link: "/staff-attendance",
        requiresPermission: true,
        permissionKeys: StaffAttendanceRecordPermission,
      },

      {
        key: "userInfoManagement",
        label: t("Child management"),
        link: "/child",
        requiresPermission: true,
        permissionKeys: ChildCarePermission,
      },
      {
        key: "parentsInfoManagement",
        label: t("Parents-Info management"),
        link: "/parents",
        requiresPermission: true,
        permissionKeys: ParentManagementPermission,
      },
      {
        key: "businessDayManagement",
        label: t("Business day management"),
        link: "/business-list",
        requiresPermission: true,
        permissionKeys: BusinessListPermission,
      },
      {
        key: "userProgramManagement",
        label: t("User-Program management"),
        link: "/monthly-activities",
        requiresPermission: true,
        permissionKeys: MonthActivitiesPermission,
      },
      {
        key: "userProgramRecord",
        label: t("User’s program record"),
        link: "/program-record",
        requiresPermission: true,
        permissionKeys: ProgramRecordPermission,
      },
      {
        key: "facilityInformationHistory",
        label: t("Facility information ・ History"),
        link: "/facility",
        requiresPermission: true,
        permissionKeys: FacilityInformationPermission,
      },
      {
        key: "renewBeneficiaryCertificae",
        label: t("Users who need to renew their beneficiary certificate"),
        link: "/users-needing-certificate-renewal",
        requiresPermission: true,
        permissionKeys: RecipientCertificatePermission,
      },
      // {
      //   key: "userAbsenceInformation",
      //   label: t("User absence information"),
      //   link: "/user-attendance-table/absence-record",
      //   requiresPermission: true,
      //   permissionKeys: ChildAttendanceManagementPermission,
      // },
    ],
  },
  {
    key: "revenueManagement",
    icon: React.createElement(RevenueManagementIcon),
    label: t("Revenue management"),
    requiresPermission: true,
    children: [
      {
        key: "revenueManagement-sales",
        label: t("Revenue management"),
        link: "/revenue-management",
        requiresPermission: true,
        permissionKeys: RevenueManagementPermission,
      },

      {
        key: "facilityTourExperienceApplicationManagement",
        label: t("Facility tour/experience application management"),
        link: "/facility-tour-application-management",
        requiresPermission: true,
        permissionKeys: FacilityTourAppManagementPermission,
      },
      {
        key: "applicationStatus",
        label: t("applicationStatus"),
        link: "/application-status-facility",
        requiresPermission: false,
        permissionKeys: RevenueManagementPermission,
      },
      {
        key: "facilitySettings",
        label: t("Facility Application Settings"),
        link: "/facility-settings",
        requiresPermission: false,
        permissionKeys: RevenueManagementPermission,
      },
    ],
  },
  {
    key: "supportPlanManagement",
    icon: React.createElement(FormManagementIcon),
    label: t("Support plan management"),
    requiresPermission: true,
    children: [
      {
        key: "supportPlanList",
        label: t("Support plan list"),
        link: "/usage-status-list",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_operation_permissions",
          "system_status_management",
        ],
      },
      {
        key: "assessment",
        label: t("Assessment list"),
        link: "/assessment",
        requiresPermission: false,
      },
      {
        key: "individualSupportPlanningDraft",
        label: t("Individual support planning (draft)"),
        link: "/individual-support-draft-plans",
        requiresPermission: true,
        permissionKeys: IndividualSupportPlanPermission,
      },
      {
        key: "individualSupportPlanningSub",
        label: t("individualSupportPlanning"),
        link: "/individual-support-plans",
        requiresPermission: true,
        permissionKeys: IndividualSupportPlanPermission,
      },

      {
        key: "supportMeetingRecord",
        label: t("Support meeting record"),
        link: "/minutes",
        requiresPermission: false,
      },
      {
        key: "monitoring",
        label: t("Monitoring"),
        link: "/monitoring",
        requiresPermission: false,
      },
      {
        key: "userWhoNeedsMonitoring",
        label: t("User who needs monitoring"),
        link: "/users-who-need-monitoring",
        requiresPermission: false,
      },
    ],
  },
  {
    key: "invoiceManagement",
    icon: React.createElement(BillingManagementIcon),
    label: t("Invoice management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "additionalItemMenuSub",
        label: t("Additional item management"),
        link: "/additional-item-management",
        requiresPermission: true,
        permissionKeys: [
          AdditionalItemChildPermission,
          AdditionalItemFacilityPermission,
        ],
      },
      {
        key: "variousAdditionsManegementOfMeetingMinutes",
        label: t("Various additions ・Management of meeting minutes"),
        link: "/minutes-addition",
        requiresPermission: true,
        permissionKeys: MinutesAdditionPermission,
      },

      {
        key: "actualCostBurdenSheet",
        label: t("Actual cost burden sheet"),
        link: "/actual-cost-burden-schedule",
        requiresPermission: true,
        permissionKeys: ActualCostSchedulePermission,
      },
      {
        key: "actualCostBurdenRecordTable",
        label: t("Actual cost burden record table"),
        link: "/actual-cost-burden-table",
        requiresPermission: true,
        permissionKeys: ActualCostBurdenPermission,
      },

      {
        key: "jointFacilityStatusInvoiceManagementSub",
        label: t("Joint facility status management"),
        link: "/joint-facility-status-management",
        requiresPermission: true,
        permissionKeys: JointFacilityStatusManagementPermission,
      },
      {
        key: "maximumAmountManagementResultInformationManagement",
        label: t("Maximum amount management"),
        link: "/maximum-amount-management-result-information-management",
        requiresPermission: true,
        permissionKeys:
          MaximumAmountManagementResultInformationManagementPermission,
      },

      {
        key: "municipalSubsidiaryClaim",
        link: "/municipal-sub-component-billing-amount-information-management",
        label: t("Municipal Subsidiary Claim"),
        requiresPermission: true,
        permissionKeys: MunicipalSubsidiaryPermission,
      },
      {
        key: "nationalHealthInsuranceBillingForm",
        label: t("National Health Insurance Billing - Form/CSV output"),
        link: "/national-health-insurance-billing",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_billing_management_permissions",
          "bill_csv_management",
        ],
      },
    ],
  },
  {
    key: "masterManagement",
    icon: React.createElement(MasterAdminIcon),
    label: t("Master management"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "staffInformationMaster",
        label: t("Instructor Master"),
        link: "/instructors",
        requiresPermission: true,
        permissionKeys: InstructorMasterPermission,
      },
      {
        key: "shiftMaster",
        label: t("Staff shift"),
        link: "/shift-master",
        requiresPermission: true,
        permissionKeys: ShiftMasterPermission,
      },
      {
        key: "programMaster",
        label: t("Program content category entry"),
        link: "/program-category",
        requiresPermission: true,
        permissionKeys: ProgramMasterPermission,
      },
      {
        key: "serviceProvisionContentMaster",
        label: t("Service provision content category entry"),
        link: "/service-provisions",
        requiresPermission: true,
        permissionKeys: ServiceProvisionMasterPermission,
      },
      {
        key: "provisionCityMaster",
        label: t("Provision city entry"),
        link: "/provisional-cities",
        requiresPermission: true,
        permissionKeys: ProvisionCityMasterPermission,
      },
      {
        key: "dayNurserySchoolMaster",
        label: t("Day nursery/Schools"),
        link: "/day-nursery-schools",
        requiresPermission: true,
        permissionKeys: NurserySchoolMasterPermission,
      },
      {
        key: "jointFacilityMaster",
        label: t("Joint facility"),
        link: "/joint-facility",
        requiresPermission: true,
        permissionKeys: JointFacilityMasterPermission,
      },
      {
        key: "consultationSupportOfficeMaster",
        label: t("Consultation support office master"),
        link: "/consultation-support-office",
        requiresPermission: true,
        permissionKeys: ConsultationSupportMasterPermission,
      },
      {
        key: "carMaster",
        label: t("Vehicle info entry"),
        link: "/shuttle-car",
        requiresPermission: true,
        permissionKeys: ShuttleCarMasterPermission,
      },
      {
        key: "actualCostItemMaster",
        label: t("Actual items entry"),
        link: "/actual-cost-items",
        requiresPermission: true,
        permissionKeys: ActualCostMasterPermission,
      },
      {
        key: "progressManagementMaster",
        label: t("Progress management master "),
        link: "/progress-management",
        requiresPermission: true,
        permissionKeys: ProgressManagementMasterPermission,
      },
    ],
  },
  {
    key: "pageManagements",
    icon: React.createElement(MyPageSettingIcon),
    label: t("Page managements"),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "systemUserAccountManagement",
        label: t("System user account management"),
        link: "/users",
        requiresPermission: true,
        permissionKeys: [],
      },
      {
        key: "useGuardianOutputInformationCollectively",
        label: t("User/guardian - output information collectively"),
        link: "/user-guardian-output-information-collectively",
        requiresPermission: true,
        permissionKeys: ["output_parent_and_child"],
      },
      {
        key: "companyInformation",
        label: t("companyInformation"),
        link: "/company",
        requiresPermission: true,
        permissionKeys: [],
      },
      {
        key: "mypagedisplaysettings",
        label: t("Use screen for parents"),
        link: "/my-page",
        requiresPermission: true,
        permissionKeys: MyPagePermission,
      },
      {
        key: "automaticEmailSetting",
        label: t("Automatic email setting"),
        link: "/automatic-email",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_system_setting_permissions",
          "system_mail_settings",
        ],
      },
      {
        key: "DataBackup",
        label: t("Data Backup"),
        link: "/data-backup",
        requiresPermission: false,
        permissionKeys: [],
      },
    ],
  },
  {
    key: "requiredItemsForApplication",
    label: t("Required items for application"),
    icon: React.createElement(FormManagementIcon),
    requiresPermission: true,
    hasTopMenuPermission: false,
    children: [
      {
        key: "individualSupportRecordDownload",
        label: t("Individual support record download"),
        link: "/individual-support-plans",
        requiresPermission: true,
        permissionKeys: IndividualSupportPlanPermission,
      },
      {
        key: "userServiceProvisionRecord",
        label: t("User service provision record"),
        link: "/service-provision-record-sheet",
        requiresPermission: true,
        permissionKeys: ServiceProvisionRecordPermission,
      },
      {
        key: "monthlyUsageChart",
        label: t("Monthly usage chart"),
        link: "/monthly-usage-chart",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_form_management_permissions",
          "monthly_status_form",
        ],
      },
      {
        key: "contractReportNotification",
        label: t("Contract Report"),
        link: "/contract-report",
        requiresPermission: true,
        permissionKeys: ContractReportPermission,
      },
      {
        key: "selfAssessmentSheetParents",
        label: t("Self-assessment sheet parents"),
        link: "/self-assessment-sheet",
        requiresPermission: true,
        permissionKeys: SelfEvalFormPermission,
      },
      {
        key: "bulkOutputCareLifeRecords",
        label: t("Bulk output of care records and life records"),
        link: "/user-service-provision-result/bulk-print-life-care-record",
        requiresPermission: true,
        permissionKeys: ServiceProvisionRecordPermission,
      },
      {
        key: "serviceProvisionRecordBulkDownload",
        label: t("Service provision record bulk download"),
        link: "/service-provision-record-sheet/print",
        requiresPermission: true,
        permissionKeys: ServiceProvisionRecordPermission,
      },
      {
        key: "treatmentImprovementAdditionAmountResultTable",
        label: t("Treatment improvement addition amount result table"),
        link: "/treatment-additions",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_form_management_permissions",
          "treatment_improvement_form",
        ],
      },
      {
        key: "remunerationCalculationCategories",
        label: t("Remuneration calculation categories"),
        link: "/remuneration-calculation",
        requiresPermission: true,
        permissionKeys: [
          "user_facility_permissions",
          "facility_form_management_permissions",
          "remuneration_notification_form",
        ],
      },
    ],
  },
]

export { sideBarItems, hasPermissionForMenu, hasPermissionForFacility }
