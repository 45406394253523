import React from "react"
import styled from "styled-components"
import { adultTheme } from "../../../theme-adult"
const Wrapper = styled.footer`
  background-color: ${adultTheme.colors.white};
  height: 80px;
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4em;
    list-style: none;
    li {
      font-weight: 500;
      line-height: 23.17px;
    }
  }
`
const Footer = (): JSX.Element => {
  return (
    <Wrapper>
      <ul>
        <li>{"利用規約"}</li>
        <li>{"個人情報保護方針"}</li>
        <li>{"個人情報保護方針"}</li>
      </ul>
    </Wrapper>
  )
}
Footer.displayName = "Footer"
export { Footer }
