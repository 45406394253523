// types
import { AllProgramCategoryResponse } from "../services/programCategory"
import { ProgressRecPayloadDataProps } from "../services/programProgressRecordList"
import { FormInitialValues } from "../components/organisms/EditOrAddProgressRecordFormPage/types"

export const mapCreateProgramProgressPayload: (
  payload: FormInitialValues
) => ProgressRecPayloadDataProps = (payload) => {
  const {
    review,
    childId,
    yearMonth,
    facilityId,
    overallGoal,
    programCategoryId,
    goalAchievementRate,
  } = payload
  return {
    child_id: childId,
    facility_id: facilityId,
    year: +yearMonth.format("YYYY"),
    month: +yearMonth.month() + 1,
    program_category_id: programCategoryId,
    overall_goal: overallGoal,
    review_column: review,
    goal_achievement: `${goalAchievementRate}`,
  }
}

export const mapAllProgramCategoryResponse: (
  response: AllProgramCategoryResponse | null
) => API.CommonOption[] = (response) => {
  return response?.data.map((item) => ({
    label: item.program_category_name,
    value: item.id,
  }))
}
