import React from "react"
import { Space, Typography } from "antd"
import { ModalProps } from "antd/es/modal"
import { useTranslation } from "react-i18next"

import { Modal } from "../Modal"
import { Button } from "../../atom"
import { theme } from "../../../theme"
import styled from "styled-components"

const Wrapper = styled.div`
  .ant-btn:hover {
    color: ${theme.red1} !important;
  }
`

interface IProps extends ModalProps {
  radius?: number
  bgColor?: string
  btnRightMargin?: string
  msg?: string
  isLoading?: boolean
  isOkButtonText?: string
}
export const DeleteConfimationModal: React.FC<IProps> = ({
  onCancel,
  onOk,
  msg,
  isLoading,
  isOkButtonText,
  ...rest
}) => {
  const { t } = useTranslation()
  return (
    <Modal
      {...rest}
      onCancel={onCancel}
      onOk={onOk}
      footer={[
        <Space size={"middle"} key={"delete-footer"}>
          <Button
            bordercolor={theme.red1}
            background={"transparent"}
            onClick={onCancel}
            noradius
            color={theme.black}
            padding={"0px 10px"}
            disabled={isLoading}
          >
            {t("Cancel")}
          </Button>
          <Wrapper>
            <Button
              bordercolor={theme.red1}
              background={theme.red1}
              color={theme.base}
              onClick={onOk}
              noradius
              padding={"0px 10px"}
              loading={isLoading}
              disabled={isLoading}
            >
              {isOkButtonText ? t(isOkButtonText) : t("Delete")}
            </Button>
          </Wrapper>
        </Space>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          whiteSpace: "break-spaces",
        }}
      >
        <Typography.Title level={4}>
          {msg ? msg : t("Are you sure?")}
        </Typography.Title>
      </div>
    </Modal>
  )
}
