import React, { FC } from "react"
import { Table as AntTable, TableProps, ConfigProvider } from "antd"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

interface ITableProps extends TableProps<any> {
  emptyText?: string
  bordered?: boolean
  minWidth?: number
}

const MainWrapper = styled.div<any>`
  .empty-cell {
    margin: auto;
  }

  .ant-table-title {
    background: #f3f3f3;
    text-align: center;
    border-bottom: 1px solid #d2d1d1;
    border-radius: 12px 12px 0px 0px;
  }

  table {
    border-collapse: collapse;
    th {
      min-width: ${(props) =>
        props?.minWidth ? `${props?.minWidth}px` : "90px"};
      text-align: center;
      background: rgba(77, 162, 248, 0.1);
      border-bottom: 1px solid #d2d1d1;
    }
    td {
      text-align: center;
      border: 1px solid #d2d1d1;
    }
  }
  @media print {
    table {
      th {
        min-width: fit-content !important;
        text-align: center;
        outline: none !important;
        border-bottom: 0 !important;
      }
      td {
        text-align: center;
        border: 1px solid #d2d1d1;
        a {
          text-decoration: none !important;
          pointer-events: none !important;
          color: black !important;
        }
      }
      .ant-table-placeholder {
        td {
          height: 100px !important;
        }
      }
    }
  }
`

const AttendanceTable: FC<ITableProps> = ({
  dataSource,
  columns,
  loading,
  locale,
  size,
  emptyText,
  scroll,
  bordered,
  minWidth,
}) => {
  const { t } = useTranslation()
  return (
    <MainWrapper minWidth={minWidth}>
      <ConfigProvider
        renderEmpty={() => (
          <p className={"empty-cell"}>{emptyText || t("No Data Found")}</p>
        )}
      >
        <AntTable
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          locale={locale}
          size={size}
          scroll={scroll}
          bordered={bordered ? true : false}
          title={() => t("List of children attending")}
          className={"always-present-scrollbar"}
        />
      </ConfigProvider>
    </MainWrapper>
  )
}

export { AttendanceTable }
