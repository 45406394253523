export const truncate = (str: string, length: number) => {
  if (str && str.length > length) {
    return str.slice(0, length) + "..."
  }
  return str
}

export const StrPadd = (str: string) => {
  const arr = str.split("")
  const initialLength = arr.length

  if (arr.length < 10) {
    while (arr.length < 10) {
      arr.unshift("0")
    }
  } else {
    const targetLength = Math.ceil(initialLength / 10) * 10

    if (initialLength < targetLength) {
      const elementsToAdd = targetLength - initialLength
      for (let i = 0; i < elementsToAdd; i++) {
        arr.push(" ")
      }
    }
  }
  return arr
}
