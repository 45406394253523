// used to determine if changes appicable from april 2024 should be shown or not
// remove and logic when client confirms changes should be applicable or update env variable accordingly
export const isApril2024ChangeApplicable = (date: moment.Moment) =>
  process.env.NEXT_PUBLIC_SHOW_APRIL_CHANGE == "yes" &&
  date.isSameOrAfter("2024-04-01")

interface DifferenceInTime {
  hour: number
  minute: number
  totalMinute: number
  hourString: string
  minuteString: string
  error: boolean
}

export const timeDifference = (startTime, endTime): DifferenceInTime => {
  const difference: DifferenceInTime = {
    hour: 0,
    minute: 0,
    totalMinute: 0,
    hourString: "00",
    minuteString: "00",
    error: false,
  }
  const startHourMinute = startTime.split(":")
  if (startHourMinute.length < 2) {
    difference.error = true
    return difference
  }
  const endHourMinute = endTime.split(":")
  if (endHourMinute.length < 2) {
    difference.error = true
    return difference
  }
  const startHour = parseInt(startHourMinute[0])
  const startMinute = parseInt(startHourMinute[1])
  const endHour = parseInt(endHourMinute[0])
  const endMinute = parseInt(endHourMinute[1])

  if (
    isNaN(startHour) ||
    isNaN(startMinute) ||
    isNaN(endHour) ||
    isNaN(endMinute)
  ) {
    difference.error = true
    return difference
  }

  const endTimeInMinute = endHour * 60 + endMinute
  const startTimeInMinute = startHour * 60 + startMinute
  difference.totalMinute = endTimeInMinute - startTimeInMinute
  difference.hour = Math.floor(difference.totalMinute / 60)
  difference.minute = difference.totalMinute % 60

  difference.hourString = difference.hour.toString().padStart(2, "0")
  difference.minuteString = difference.minute.toString().padStart(2, "0")

  return difference
}

export const CHILD_SUPPORT_ATTACH_TABLE_DAY_ORDER = [
  "sun",
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
]
