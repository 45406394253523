import { Card } from "antd"
import React from "react"
import styled from "styled-components"

interface TopCardProps {
  title: React.ReactNode
  titleIconPath?: string
  extra?: React.ReactNode
  children: React.ReactNode
}

interface StyledCardProps {
  titleIconPath?: string
}

const StyledCard = styled(Card)<StyledCardProps>`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  .ant-card-head {
    margin: 0 30px;
    padding: 15px 0 0;
    border: none;
    .ant-card-head-wrapper {
      border-bottom: 1px solid #d2d1d1;
      padding-bottom: 8px;
    }
    .ant-card-head-title {
      padding: 0;
      padding-left: ${({ titleIconPath }) => (titleIconPath ? "23px" : 0)};
      background: ${({ titleIconPath }) =>
        titleIconPath
          ? `url(${titleIconPath}) no-repeat left center / 18px 18px`
          : ""};
    }
    .ant-card-extra {
      padding: 0;
      .display-num {
        color: #e00000;
      }
    }
  }
  .ant-card-body {
    border-radius: 0 0 12px 12px;
    margin: 0 6px !important;
    padding: 10px 24px 15px;
  }
`

const TopCard: React.FC<TopCardProps> = ({
  title,
  titleIconPath,
  extra,
  children,
}) => {
  return (
    <StyledCard title={title} titleIconPath={titleIconPath} extra={extra}>
      {children}
    </StyledCard>
  )
}

export { TopCard }
