import { Radio } from "antd"
import styled from "styled-components"

const StyledRadioWrapper = styled.div`
  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    margin-bottom: 6px;
    :hover .ant-radio::after {
      visibility: hidden;
    }
  }
  .ant-radio {
    top: 0;
    :hover::after {
      visibility: hidden;
    }
    .ant-radio-inner {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      width: 25px;
      height: 25px;
      box-shadow: none;
    }
    &.ant-radio-checked {
      .ant-radio-inner {
        ::after {
          background-color: #000;
          transform: scale(0.8);
          transition: none;
        }
      }
    }
    + span {
      line-height: 30px;
    }
  }
`

interface RadioProps {
  id?: string | number
  checked?: boolean
  onClick?: () => any
}

export const RadioButton: React.FC<RadioProps> = ({
  id,
  checked,
  onClick,
}: RadioProps) => {
  return (
    <StyledRadioWrapper>
      <Radio id={`${id}`} checked={checked} onClick={onClick} />
    </StyledRadioWrapper>
  )
}
