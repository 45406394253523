import { Card } from "antd"
import React from "react"
import styled, { css } from "styled-components"
import { theme } from "../../../theme"

interface PageCardProps {
  headStyleBg?: string
  bodyStyleBg?: string
  title?: React.ReactNode
  extra?: React.ReactNode
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
  toRight?: boolean
  isMinWidth?: boolean
  background?: string
  noBottomMargin?: boolean
  isPaddingTop?: boolean
}

const StyledCard = styled(Card)<any>`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  .ant-card-head {
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #d2d1d1;
    margin: 0 30px;
    padding: 8px 0;
  }
  .ant-card-body {
    border-radius: 0 0 12px 12px;
    margin: 0 6px !important;
    padding: 20px 24px 40px;
    display: flex;
    flex-wrap: wrap;
  }

  .width_fix {
    max-width: 120px;
    min-width: 120px;
  }
  .ant-card-head-wrapper {
    justify-content: flex-start;
    .ant-card-head-title {
      flex: auto 0;
      padding: 0;
      white-space: unset;
    }
    .ant-card-extra {
      padding: 0;
      ${(props) =>
        props?.toRight
          ? css``
          : css`
              display: flex;
              align-items: center;
              margin-left: 5px;
              > :first-child {
                color: #e00;
                margin-right: 127px;
              }
            `}
    }
  }
  .action-btns-group {
    display: flex;
  }
  &.company-information-page-card {
    a {
      color: #0a82c8;
    }
    .ant-card-head-title {
      font-weight: 400;
    }
    .ant-card-head-wrapper {
      .ant-card-extra {
        margin-left: auto;

        &.extra-btn {
          margin-left: 0;
        }
        > :first-child {
          color: inherit;
          margin: 0;
        }
      }
    }
  }

  &.recipient-certificate-information {
    .ant-card-head-wrapper {
      row-gap: 10px;
      column-gap: 20px;
    }
    .ant-card-extra .clear-information-button {
      margin: 0;
    }
    @media (max-width: 1236px) {
      .ant-card-head-wrapper {
        flex-direction: row !important;

        align-items: center !important;

        .clear-information-button {
          margin: 0 !important;
        }
      }
    }
  }

  &.remuneration-print {
    @media screen and (max-width: 510px) {
      .ant-card-head-wrapper {
        display: block;
      }
      .ant-card-extra {
        margin-top: 12px;
        margin-right: -20px;
      }
    }
  }
  .no-hover-effect {
    &:hover {
      color: #ffffff;
      background: ${theme.gray3};
    }
  }
  @media print {
    &.assessment-page {
      .ant-card-head {
        .ant-card-head-title {
          font-size: 24px;
        }
      }
      .ant-card-body .info-wrapper {
        margin-bottom: 20px !important;
      }
    }
  }

  @media (max-width: 1236px) {
    .ant-card-head-wrapper {
      display: flex;
      column-gap: 4px;
      .ant-card-extra {
        background: ${({ background }) =>
          background ? background : "inherit"};
        @media (max-width: 951px) {
          display: flex;
          flex-direction: column;
          margin-left: 0;
          flex-grow: 0;
          align-items: flex-end;
          min-width: ${({ isMinWidth }) => (isMinWidth ? "172px" : null)};
        }
        > :first-child {
          margin-right: 20px;
          margin-bottom: ${({ noBottomMargin }) =>
            noBottomMargin ? "0px" : "10px"};
        }
        > :last-child {
          max-width: 180px;
        }
      }
    }
  }

  @media (max-width: ${theme.breakpoints.md}) {
    .ant-card-body {
      padding: 8px 8px 10px !important;
    }
    &.company-information-page-card.extra-class {
      .ant-card-head-wrapper .ant-card-extra {
        .extra-btn {
          padding-inline: 20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    &.company-information-page-card.extra-class {
      .ant-card-head-wrapper {
        flex-direction: row !important;
        align-items: center !important;
        .ant-card-head-title {
          min-width: 66px;
        }
        .ant-card-extra {
          width: 100%;
          margin-top: 0px;
          align-items: flex-end;
          .extra-btn {
            padding-inline: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .ant-card-body {
      padding: 16px 16px;
      padding-top: ${({ isPaddingTop }) => isPaddingTop && "20px !important"};
    }
    .ant-card-head {
      margin: 0 16px;
    }
  }
  @media print {
    box-shadow: unset !important;
    border-radius: unset !important;

    .ant-card-body {
      border-radius: unset !important;
      padding: 0 !important;
    }
  }
`

const PageCard: React.FC<PageCardProps> = ({
  headStyleBg,
  bodyStyleBg,
  title,
  extra,
  children,
  style,
  className,
  toRight,
  background,
  noBottomMargin,
  isMinWidth,
  isPaddingTop,
}) => {
  return (
    <StyledCard
      title={title}
      extra={extra}
      bodyStyle={{ background: bodyStyleBg || "#fff" }}
      headStyle={{ background: headStyleBg || "#fff" }}
      style={style}
      className={className}
      toRight={toRight}
      background={background}
      noBottomMargin={noBottomMargin}
      isMinWidth={isMinWidth}
      isPaddingTop={isPaddingTop}
    >
      {children}
    </StyledCard>
  )
}

export { PageCard }
