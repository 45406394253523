import { Avatar, Card, Col, Row, Typography } from "antd"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"
import Link from "next/link"

export type GridderShape = {
  title: string
  iconPath?: string
  isActive?: boolean
  routes?: string
  requiresPermission?: boolean
  permissionKeys?: Array<string>
}
interface GridderProps {
  title: string
  items: GridderShape[]
  activeColor?: string
}

interface StyledColProps {
  isActive: boolean
  activeColor?: string
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ant-typography {
    font-size: 20px;
    font-weight: 500;
    color: #191919;
    margin-bottom: 20px;
  }
  .ant-card.ant-card-bordered:hover {
    transition: 0.5s ease-out;
    border: 1px solid #fdd95f;

    .ant-avatar.ant-avatar-circle.ant-avatar-image {
      transition: 0.5s ease-out;
      background-color: rgba(253, 217, 95, 0.3);
    }
  }
`
const StyledCol = styled(Col)<StyledColProps>`
  flex-basis: 20%;
  @media (max-width: 1201px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 820px) {
    flex-basis: 100%;
  }
  .ant-card {
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ isActive, activeColor }) =>
      isActive ? (activeColor ? theme.bgOrange : "#fff4d1") : "#f3f3f3"};
    border-radius: 12px;
    min-height: 100%;
  }
  .ant-card-body {
    padding: 13px 3px;
    min-height: 100%;
    .ant-card-meta {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      min-height: 100%;
      @media (max-width: 820px) {
        flex-direction: row;
        justify-content: flex-start;
        margin-left: 20px;
        margin-right: 20px;
        align-items: center;
      }
      .ant-card-meta-avatar {
        padding-right: 0;
        .ant-avatar {
          justify-content: center;
          display: flex;
          align-items: center;
          background: ${({ isActive, activeColor }) =>
            isActive ? activeColor || "#fff4d1" : "#f3f3f3"};
          width: 50px;
          height: 50px;
          img {
            width: 50%;
            height: 50%;
            margin: 13px;
            object-fit: contain;
          }
        }
      }
      .ant-card-meta-title {
        margin-top: 13px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;

        white-space: pre-line;
        color: #191919;
        @media (max-width: 820px) {
          margin-left: 13px;
          text-align: left;
          margin-top: 0;
        }
      }
    }
  }
`

const { Meta } = Card
const { Title } = Typography
const Gridder: React.FC<GridderProps> = ({ title, items, activeColor }) => {
  return (
    <Wrapper>
      <Title level={2}>{title}</Title>
      <Row gutter={[20, 20]}>
        {items.map((item, index) => (
          <StyledCol
            key={index}
            isActive={item.isActive}
            activeColor={activeColor}
          >
            <Link href={item.routes} key={index}>
              <a>
                <Card>
                  <Meta
                    avatar={<Avatar src={item.iconPath} />}
                    title={item.title}
                  />
                </Card>
              </a>
            </Link>
          </StyledCol>
        ))}
      </Row>
    </Wrapper>
  )
}

export { Gridder }
