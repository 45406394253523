/* stylelint-disable no-descending-specificity */
import { Card } from "antd"
import React from "react"
import styled from "styled-components"

interface GriddyProps {
  className?: string
  children: React.ReactNode
}

const StyledGrid = styled(Card.Grid)`
  padding: 0;

  box-shadow: none;
  width: auto;
  margin-right: 30px;
  font-size: 20px;
  font-weight: 500;
  line-height: 29px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  .display-num {
    font-size: 28px;
    line-height: 36px;
    color: #0782c8;
    margin-left: 8px;
  }
  @media (max-width: 1201px) {
    margin-right: 20px;
  }
  @media (max-width: 1025px) {
    font-size: 14px;
    .display-num {
      font-size: 24px;
    }
    &.lg-screen-only {
      display: none;
    }
  }
  @media (max-width: 450px) {
    .display-num {
      font-size: 18px;
    }
  }
`
const Griddy: React.FC<GriddyProps> = ({ className, children }) => (
  <StyledGrid className={className} hoverable={false}>
    {children}
  </StyledGrid>
)

export { Griddy }
