import React from "react"
import { Button } from "../../atom"
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import moment from "moment"

interface ICalendarPagination {
  className?: string
  currentYear: number
  currentMonth: number
  onMonthChange?: (value: number) => void
  showDate?: boolean
}

const CalendarPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .month-pagination-wrapper {
    button {
      background: white;
      color: black;
      border: 1px solid #d2d1d1;
      border-radius: 10px;
      height: 40px;
      margin-right: 10px;
      padding: 0px 10px;
      min-width: 75px;
      &.active {
        background: rgba(7, 130, 200, 0.2);
        width: auto;
      }
    }
  }
  @media (max-width: 900px) {
    .month-pagination-wrapper {
      display: flex;
    }
  }
`

const MonthlyCalendarPagination: React.FC<ICalendarPagination> = ({
  onMonthChange,
  currentYear,
  currentMonth,
  showDate = true,
  className,
}) => {
  const date = new Date()
  const { t } = useTranslation()
  return (
    <CalendarPaginationWrapper className={className}>
      <div className={"month-pagination-wrapper"}>
        <Button
          onClick={() => onMonthChange(currentMonth - 1)}
          className={"left-btn"}
        >
          <DoubleLeftOutlined />
          <span className={"left-btn-label"}>{t("Last month")}</span>
        </Button>
        <Button
          onClick={() => {
            if (showDate) {
              return null
            }
            onMonthChange(Number(moment().format("M")))
          }}
          className={"active active-btn"}
        >
          <span className={"current-btn-label"}>
            {showDate ? (
              <>
                {currentYear === date.getFullYear() &&
                currentMonth === date.getMonth() + 1
                  ? t("This Month")
                  : `${currentYear}年${currentMonth.toLocaleString("en-US", {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}月`}
              </>
            ) : (
              t("This Month")
            )}
          </span>
        </Button>
        <Button
          onClick={() => onMonthChange(currentMonth + 1)}
          className={"right-btn"}
        >
          <span className={"right-btn-label"}>{t("Next month")}</span>
          <DoubleRightOutlined />
        </Button>
      </div>
    </CalendarPaginationWrapper>
  )
}

export { MonthlyCalendarPagination }
