import {
  ActualCostMasterPermission,
  BusinessListPermission,
  ActualCostSchedulePermission,
  AttendanceSchedulePermission,
  ChildAttendanceManagementPermission,
  ChildCarePermission,
  ConsultationSupportMasterPermission,
  DailyBusinessReportPermission,
  FacilityAppManagementPermission,
  FacilityInformationPermission,
  IndividualSupportPlanPermission,
  InstructorMasterPermission,
  JointFacilityMasterPermission,
  MonthActivitiesPermission,
  NewRegistrationPermission,
  NurserySchoolMasterPermission,
  ProgramMasterPermission,
  ProgressManagementMasterPermission,
  ProvisionCityMasterPermission,
  RecipientCertificatePermission,
  ServiceProvisionMasterPermission,
  ServiceProvisionRecordPermission,
  ShiftMasterPermission,
  ShuttleCarMasterPermission,
  StaffAttendanceRecordPermission,
  TransportationManagementPermission,
  ProgramRecordPermission,
  ServiceProvisionRecordSheetPermission,
  MinutesAdditionPermission,
  ContractReportPermission,
  SelfEvalFormPermission,
  AdditionalItemChildPermission,
  MaximumAmountManagementResultInformationManagementPermission,
  AdditionalItemFacilityPermission,
  ActualCostBurdenPermission,
  NotesFromParentsPermission,
  ParentManagementPermission,
  MunicipalSubsidiaryPermission,
  JointFacilityStatusManagementPermission,
  FacilityTourAppManagementPermission,
  MyPagePermission,
  FacilityApplicationSettingPermission,
  RecurringSchedulePermission,
  ProgramProgressRecordPermission,
} from "./PermissionKeys"
import { hasPermissionForMenu } from "./SidebarUtils"

type PermissionType = "write" | "both"

interface IPageObj {
  routeLike: string
  activeKeys: Array<string>
  skipPermissions?: boolean
  premissionType?: PermissionType
  permissionKeys?: Array<any>
}

export const PageObj: Array<IPageObj> = [
  {
    activeKeys: ["invoiceManagement", "actualCostBurdenSheet"],
    routeLike: "/actual-cost-burden-schedule",
    premissionType: "both",
    permissionKeys: ActualCostSchedulePermission,
  },
  {
    activeKeys: [
      "requiredItemsForApplication",
      "serviceProvisionRecordBulkDownload",
    ],
    routeLike: "/service-provision-record-sheet/print",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["invoiceManagement", "actualCostBurdenSheet"],
    routeLike: "/facility/[id]/actual-cost-burden/create",
    premissionType: "write",
    permissionKeys: ActualCostSchedulePermission,
  },
  {
    activeKeys: ["invoiceManagement", "municipalSubsidiaryClaim"],
    routeLike: "/municipal-sub-component-billing-amount-information-management",
    premissionType: "both",
    permissionKeys: MunicipalSubsidiaryPermission,
  },
  {
    activeKeys: ["invoiceManagement", "municipalSubsidiaryClaim"],
    routeLike:
      "/municipal-sub-component-billing-amount-information-management/add",
    premissionType: "write",
    permissionKeys: MunicipalSubsidiaryPermission,
  },
  {
    activeKeys: ["invoiceManagement", "municipalSubsidiaryClaim"],
    routeLike:
      "/municipal-sub-component-billing-amount-information-management/edit/[id]",
    premissionType: "write",
    permissionKeys: MunicipalSubsidiaryPermission,
  },
  {
    activeKeys: ["invoiceManagement", "actualCostBurdenRecordTable"],
    routeLike: "/actual-cost-burden-table",
    premissionType: "both",
    permissionKeys: ActualCostBurdenPermission,
  },
  {
    activeKeys: ["invoiceManagement", "actualCostBurdenRecordTable"],
    routeLike: "/actual-cost-burden",
    premissionType: "both",
    permissionKeys: ActualCostBurdenPermission,
  },
  {
    activeKeys: ["invoiceManagement", "actualCostBurdenRecordTable"],
    routeLike: "/actual-cost-burden/add",
    premissionType: "write",
    permissionKeys: ActualCostBurdenPermission,
  },
  {
    activeKeys: ["masterManagement", "actualCostItemMaster"],
    routeLike: "/actual-cost-items",
    premissionType: "both",
    permissionKeys: ActualCostMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "actualCostItemMaster"],
    routeLike: "/actual-cost-items/add",
    premissionType: "write",
    permissionKeys: ActualCostMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "actualCostItemMaster"],
    routeLike: "/actual-cost-items/edit/[id]",
    premissionType: "write",
    permissionKeys: ActualCostMasterPermission,
  },
  {
    activeKeys: ["invoiceManagement", "additionalItemMenuSub"],
    routeLike: "/additional-item-management",
    premissionType: "both",
    permissionKeys: [
      AdditionalItemChildPermission,
      AdditionalItemFacilityPermission,
    ],
  },
  {
    activeKeys: ["invoiceManagement", "additionalItemMenuSub"],
    routeLike: "/additional-item-management/child",
    premissionType: "both",
    permissionKeys: AdditionalItemChildPermission,
  },
  {
    activeKeys: ["invoiceManagement", "additionalItemMenuSub"],
    routeLike: "/additional-item-management/child/bulk-edit/[facility_id]",
    premissionType: "write",
    permissionKeys: AdditionalItemChildPermission,
  },
  {
    activeKeys: ["invoiceManagement", "additionalItemMenuSub"],
    routeLike: "/additional-item-management-facility",
    premissionType: "both",
    permissionKeys: AdditionalItemFacilityPermission,
  },
  {
    activeKeys: ["invoiceManagement", "additionalItemMenuSub"],
    routeLike: "/additional-item-management/facility/[facility_id]",
    premissionType: "write",
    permissionKeys: AdditionalItemFacilityPermission,
  },
  {
    activeKeys: ["revenueManagement", "applicationStatus"],
    routeLike: "/application-status-facility",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "revenue_management",
    ],
  },
  {
    activeKeys: ["revenueManagement", "facilitySettings"],
    routeLike: "/facility-settings",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "revenue_management",
    ],
  },
  {
    activeKeys: ["supportPlanManagement", "assessment"],
    routeLike: "/assessment",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "assessment"],
    routeLike: "/assessment/add",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "assessment"],
    routeLike: "/assessment/details/[id]",
    skipPermissions: true,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceSchedule"],
    routeLike: "/attendance-schedule",
    premissionType: "both",
    permissionKeys: AttendanceSchedulePermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceSchedule"],
    routeLike: "/attendance-schedule/detail",
    premissionType: "write",
    permissionKeys: AttendanceSchedulePermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceSchedule"],
    routeLike: "/attendance-schedule/monthly-schedule",
    premissionType: "write",
    permissionKeys: AttendanceSchedulePermission,
  },
  {
    activeKeys: ["pageManagements", "automaticEmailSetting"],
    routeLike: "/automatic-email",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_system_setting_permissions",
      "system_mail_settings",
    ],
  },
  {
    activeKeys: ["pageManagements", "DataBackup"],
    routeLike: "/data-backup",
    premissionType: "both",
    permissionKeys: [],
  },

  {
    activeKeys: ["pageManagements", "automaticEmailSetting"],
    routeLike: "/automatic-email/edit/[id]",
    premissionType: "write",
    permissionKeys: [
      "user_facility_permissions",
      "facility_system_setting_permissions",
      "system_mail_settings",
    ],
  },
  {
    activeKeys: ["FacilityOperation", "businessDayManagement"],
    routeLike: "/business-day-registration",
    premissionType: "write",
    permissionKeys: BusinessListPermission,
  },
  {
    activeKeys: ["FacilityOperation", "businessDayManagement"],
    routeLike: "/business-list",
    premissionType: "both",
    permissionKeys: BusinessListPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child",
    premissionType: "both",
    permissionKeys: ChildCarePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/add",
    premissionType: "both", // NewRegistrationPermission has value 0 or 1
    permissionKeys: NewRegistrationPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/edit/[id]",
    premissionType: "write", // NewRegistrationPermission has value 0 or 1
    permissionKeys: ChildCarePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/individual-support-draft-plans/edit",
    premissionType: "write",
    permissionKeys: ChildCarePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/information/[id]",
    premissionType: "both",
    permissionKeys: ChildCarePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/profile/[child_id]",
    premissionType: "both",
    permissionKeys: ChildCarePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/profile/facility-application/[id]",
    premissionType: "both",
    permissionKeys: FacilityAppManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/profile/facility-application/[id]/edit",
    premissionType: "write",
    permissionKeys: FacilityAppManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/profile/[child_id]/recipient-certificate-form",
    premissionType: "both",
    permissionKeys: RecipientCertificatePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/profile/[child_id]/recipient-certificate-form/edit/[id]",
    premissionType: "write",
    permissionKeys: RecipientCertificatePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike: "/child/profile/recurring-schedule-setting-list/[id]",
    premissionType: "both",
    permissionKeys: RecurringSchedulePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userInfoManagement"],
    routeLike:
      "/child/profile/recurring-schedule-setting-list/change-form/[child_id]",
    premissionType: "write",
    permissionKeys: RecurringSchedulePermission,
  },
  {
    activeKeys: ["pageManagements", "useGuardianOutputInformationCollectively"],
    routeLike: "/user-guardian-output-information-collectively",
    premissionType: "both",
    permissionKeys: ["output_parent_and_child"],
  },

  {
    activeKeys: ["pageManagements", "companyInformation"],
    routeLike: "/company",
    premissionType: "both",
    permissionKeys: [],
  },
  {
    activeKeys: ["pageManagements", "companyInformation"],
    routeLike: "/company/edit",
    premissionType: "write",
    permissionKeys: [],
  },
  {
    activeKeys: ["masterManagement", "consultationSupportOfficeMaster"],
    routeLike: "/consultation-support-office",
    premissionType: "both",
    permissionKeys: ConsultationSupportMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "consultationSupportOfficeMaster"],
    routeLike: "/consultation-support-office/add",
    premissionType: "write",
    permissionKeys: ConsultationSupportMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "consultationSupportOfficeMaster"],
    routeLike: "/consultation-support-office/edit/[id]",
    premissionType: "write",
    permissionKeys: ConsultationSupportMasterPermission,
  },
  {
    activeKeys: ["FacilityOperation", "notesFromParents"],
    routeLike: "/contact",
    premissionType: "both",
    permissionKeys: NotesFromParentsPermission,
  },
  {
    activeKeys: ["FacilityOperation", "notesFromParents"],
    routeLike: "/contact/chat/[id]",
    premissionType: "write",
    permissionKeys: NotesFromParentsPermission,
  },
  {
    activeKeys: ["requiredItemsForApplication", "contractReportNotification"],
    routeLike: "/contract-report",
    premissionType: "both",
    permissionKeys: ContractReportPermission,
  },

  {
    activeKeys: ["requiredItemsForApplication", "monthlyUsageChart"],
    routeLike: "/monthly-usage-chart",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_form_management_permissions",
      "monthly_status_form",
    ],
  },

  {
    activeKeys: ["FacilityOperation", "dailyBusinessReport"],
    routeLike: "/daily-business-report",
    premissionType: "both",
    permissionKeys: DailyBusinessReportPermission,
  },
  {
    activeKeys: ["FacilityOperation", "dailyBusinessReport"],
    routeLike: "/daily-business-report/bulk-print",
    premissionType: "both",
    permissionKeys: DailyBusinessReportPermission,
  },
  {
    activeKeys: ["FacilityOperation", "dailyBusinessReport"],
    routeLike: "/daily-business-report/edit/[id]",
    premissionType: "write",
    permissionKeys: DailyBusinessReportPermission,
  },
  {
    activeKeys: ["FacilityOperation", "dailyBusinessReport"],
    routeLike: "/daily-business-report/register",
    premissionType: "write",
    permissionKeys: DailyBusinessReportPermission,
  },
  {
    activeKeys: ["FacilityOperation", "dailyBusinessReport"],
    routeLike: "/daily-business-report/view",
    premissionType: "both",
    permissionKeys: DailyBusinessReportPermission,
  },
  {
    activeKeys: ["masterManagement", "dayNurserySchoolMaster"],
    routeLike: "/day-nursery-schools",
    premissionType: "both",
    permissionKeys: NurserySchoolMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "dayNurserySchoolMaster"],
    routeLike: "/day-nursery-schools/add",
    premissionType: "write",
    permissionKeys: NurserySchoolMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "dayNurserySchoolMaster"],
    routeLike: "/day-nursery-schools/edit/[id]",
    premissionType: "both",
    permissionKeys: NurserySchoolMasterPermission,
  },
  {
    activeKeys: ["FacilityOperation", "facilityInformationHistory"],
    routeLike: "/facility",
    premissionType: "both",
    permissionKeys: FacilityInformationPermission,
  },
  {
    activeKeys: ["FacilityOperation", "facilityInformationHistory"],
    routeLike: "/facility/[id]/information",
    premissionType: "both",
    permissionKeys: FacilityInformationPermission,
  },
  {
    activeKeys: ["FacilityOperation", "facilityInformationHistory"],
    routeLike: "/facility/add",
    premissionType: "write",
    permissionKeys: FacilityInformationPermission,
  },
  {
    activeKeys: ["FacilityOperation", "facilityInformationHistory"],
    routeLike: "/facility/edit/[id]",
    premissionType: "write",
    permissionKeys: FacilityInformationPermission,
  },
  {
    activeKeys: ["FacilityOperation", "facilityInformationHistory"],
    routeLike: "/facility/system-status/[id]",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_operation_permissions",
      "system_status_management",
    ],
  },
  {
    activeKeys: ["revenueManagement", "facilitySettings"],
    routeLike: "/facility-settings",
    premissionType: "both",
    permissionKeys: FacilityApplicationSettingPermission,
  },
  {
    activeKeys: [
      "revenueManagement",

      "facilityTourExperienceApplicationManagement",
    ],
    routeLike: "/facility-tour-application-management",
    premissionType: "both",
    permissionKeys: FacilityTourAppManagementPermission,
  },
  {
    activeKeys: [
      "revenueManagement",
      "applicationManagement",
      "facilityTourExperienceApplicationManagement",
    ],
    routeLike: "/facility-tour-application-management/detail/[id]",
    premissionType: "both",
    permissionKeys: FacilityTourAppManagementPermission,
  },
  {
    activeKeys: [
      "revenueManagement",
      "applicationManagement",
      "facilityTourExperienceApplicationManagement",
    ],
    routeLike: "/facility-tour-application-management/detail/[id]/add",
    premissionType: "write",
    permissionKeys: FacilityTourAppManagementPermission,
  },
  {
    activeKeys: [
      "revenueManagement",
      "applicationManagement",
      "facilityTourExperienceApplicationManagement",
    ],
    routeLike:
      "/facility-tour-application-management/detail/[id]/edit/[historyId]",
    premissionType: "write",
    permissionKeys: FacilityTourAppManagementPermission,
  },
  {
    activeKeys: [
      "revenueManagement",
      "applicationManagement",
      "facilityTourExperienceApplicationManagement",
    ],
    routeLike: "/facility-tour-application-management/edit/[id]",
    premissionType: "write",
    permissionKeys: FacilityTourAppManagementPermission,
  },
  {
    activeKeys: [
      "revenueManagement",
      "applicationManagement",
      "facilityTourExperienceApplicationManagement",
    ],
    routeLike: "/facility-tour-application-management/register",
    premissionType: "write",
    permissionKeys: FacilityTourAppManagementPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningDraft"],
    routeLike: "/individual-support-draft-plans",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningDraft"],
    routeLike: "/individual-support-draft-plans/add",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningDraft"],
    routeLike: "/individual-support-draft-plans/detail/[id]",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningDraft"],
    routeLike: "/individual-support-draft-plans/edit/[id]",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningSub"],
    routeLike: "/individual-support-plans",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningSub"],
    routeLike: "/individual-support-plans/add",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningSub"],
    routeLike: "/individual-support-plans/detail/[id]",
    premissionType: "both",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "individualSupportPlanningSub"],
    routeLike: "/individual-support-plans/edit/[id]",
    premissionType: "write",
    permissionKeys: IndividualSupportPlanPermission,
  },
  {
    activeKeys: ["masterManagement", "staffInformationMaster"],
    routeLike: "/instructors",
    premissionType: "both",
    permissionKeys: InstructorMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "staffInformationMaster"],
    routeLike: "/instructors/add",
    premissionType: "write",
    permissionKeys: InstructorMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "staffInformationMaster"],
    routeLike: "/instructors/edit/[id]",
    premissionType: "write",
    permissionKeys: InstructorMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "jointFacilityMaster"],
    routeLike: "/joint-facility",
    premissionType: "both",
    permissionKeys: JointFacilityMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "jointFacilityMaster"],
    routeLike: "/joint-facility/add",
    premissionType: "write",
    permissionKeys: JointFacilityMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "jointFacilityMaster"],
    routeLike: "/joint-facility/edit/[id]",
    premissionType: "write",
    permissionKeys: JointFacilityMasterPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "jointFacilityStatusInvoiceManagementSub",
    ],
    routeLike: "/joint-facility-status-management",
    premissionType: "both",
    permissionKeys: JointFacilityStatusManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "jointFacilityStatusInvoiceManagementSub",
    ],
    routeLike: "/joint-facility-status-management/add",
    premissionType: "write",
    permissionKeys: JointFacilityStatusManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "jointFacilityStatusInvoiceManagementSub",
    ],
    routeLike: "/joint-facility-status-management/edit/[id]",
    premissionType: "write",
    permissionKeys: JointFacilityStatusManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "jointFacilityStatusInvoiceManagementSub",
    ],
    routeLike: "/joint-facility-status-management/detail/[id]",
    premissionType: "both",
    permissionKeys: JointFacilityStatusManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "jointFacilityStatusInvoiceManagementSub",
    ],
    routeLike: "/joint-facility-status-management/detail/bulk-edit",
    premissionType: "write",
    permissionKeys: JointFacilityStatusManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "maximumAmountManagementResultInformationManagement",
    ],
    routeLike: "/maximum-amount-management-result-information-management",
    premissionType: "both",
    permissionKeys:
      MaximumAmountManagementResultInformationManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "maximumAmountManagementResultInformationManagement",
    ],
    routeLike: "/maximum-amount-management-result-information-management/add",
    premissionType: "write",
    permissionKeys:
      MaximumAmountManagementResultInformationManagementPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "maximumAmountManagementResultInformationManagement",
    ],
    routeLike:
      "/maximum-amount-management-result-information-management/edit/[id]",
    premissionType: "write",
    permissionKeys:
      MaximumAmountManagementResultInformationManagementPermission,
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing/usage-record-rewrite",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing/change-upper-limit",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike:
      "/national-health-insurance-billing/prints/disabled-child-outpatient-benefits",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing/prints/print-summary-list",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike:
      "/national-health-insurance-billing/prints/all-bank-format-output",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike:
      "/national-health-insurance-billing/prints/print-user-billing-list",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike:
      "/national-health-insurance-billing/prints/print-invoice-customer",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing/prints/print-reciept-user",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike:
      "/national-health-insurance-billing/prints/print-proxy-acknowledgement",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing/prints/user-labels",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/print-list-users-fees",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["invoiceManagement", "nationalHealthInsuranceBillingForm"],
    routeLike: "/national-health-insurance-billing-form-csv-output",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "bill_csv_management",
    ],
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike:
      "/user-service-provision-result/service-provision-performance-record-sheet-print",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "supportMeetingRecord"],
    routeLike: "/minutes",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "supportMeetingRecord"],
    routeLike: "/minutes/create",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "supportMeetingRecord"],
    routeLike: "/minutes/detail/[id]",
    skipPermissions: true,
  },
  {
    activeKeys: [
      "invoiceManagement",

      "variousAdditionsManegementOfMeetingMinutes",
    ],
    routeLike: "/minutes-addition",
    premissionType: "both",
    permissionKeys: MinutesAdditionPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",
      "additionalItemMenu",
      "variousAdditionsManegementOfMeetingMinutes",
    ],
    routeLike: "/minutes-addition/edit/[id]",
    premissionType: "write",
    permissionKeys: MinutesAdditionPermission,
  },
  {
    activeKeys: [
      "invoiceManagement",
      "additionalItemMenu",
      "variousAdditionsManegementOfMeetingMinutes",
    ],
    routeLike: "/minutes-addition/register",
    premissionType: "write",
    permissionKeys: MinutesAdditionPermission,
  },
  {
    activeKeys: ["supportPlanManagement", "monitoring"],
    routeLike: "/monitoring",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "monitoring"],
    routeLike: "/monitoring/add",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "monitoring"],
    routeLike: "/monitoring/detail/[id]",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "monitoring"],
    routeLike: "/monitoring/edit/[id]",
    skipPermissions: true,
  },
  {
    activeKeys: ["supportPlanManagement", "userWhoNeedsMonitoring"],
    routeLike: "/users-who-need-monitoring",
    skipPermissions: true,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramManagement"],
    routeLike: "/bulk-program-schedule",
    premissionType: "both",
    permissionKeys: MonthActivitiesPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramManagement"],
    routeLike: "/monthly-activities",
    premissionType: "both",
    permissionKeys: MonthActivitiesPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramManagement"],
    routeLike: "/monthly-activities/add",
    premissionType: "write",
    permissionKeys: MonthActivitiesPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramManagement"],
    routeLike: "/monthly-activities/printing",
    premissionType: "both",
    permissionKeys: MonthActivitiesPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramManagement"],
    routeLike: "/monthly-activities/application-form",
    premissionType: "both",
    permissionKeys: MonthActivitiesPermission,
  },
  {
    activeKeys: ["invoiceManagement", "municipalSubsidiaryClaim"],
    routeLike: "/municipal-billing-amount-information-management",
    premissionType: "both",
    permissionKeys: MunicipalSubsidiaryPermission,
  },
  {
    activeKeys: ["pageManagements", "mypagedisplaysettings"],
    routeLike: "/my-page",
    premissionType: "both",
    permissionKeys: MyPagePermission,
  },
  {
    activeKeys: ["FacilityOperation", "parentsInfoManagement"],
    routeLike: "/parents",
    premissionType: "both",
    permissionKeys: ParentManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "parentsInfoManagement"],
    routeLike: "/parents/add",
    premissionType: "write",
    permissionKeys: ParentManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "parentsInfoManagement"],
    routeLike: "/parents/comment",
    premissionType: "both",
    permissionKeys: ParentManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "parentsInfoManagement"],
    routeLike: "/parents/detail/[id]",
    premissionType: "both",
    permissionKeys: ParentManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "parentsInfoManagement"],
    routeLike: "/parents/edit/[id]",
    premissionType: "write",
    permissionKeys: ParentManagementPermission,
  },
  {
    activeKeys: ["masterManagement", "programMaster"],
    routeLike: "/program-category",
    premissionType: "both",
    permissionKeys: ProgramMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "programMaster"],
    routeLike: "/program-category/add",
    premissionType: "write",
    permissionKeys: ProgramMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "programMaster"],
    routeLike: "/program-category/edit/[id]",
    premissionType: "write",
    permissionKeys: ProgramMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "programMaster"],
    routeLike: "/program-list",
    premissionType: "both",
    permissionKeys: ProgramMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "programMaster"],
    routeLike: "/program-list/add",
    premissionType: "write",
    permissionKeys: ProgramMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "programMaster"],
    routeLike: "/program-list/edit/[id]",
    premissionType: "write",
    permissionKeys: ProgramMasterPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramRecord"],
    routeLike: "/program-record",
    premissionType: "both",
    permissionKeys: ProgramRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramProgressRecord"],
    routeLike: "/program-progress-record-list",
    premissionType: "both",
    permissionKeys: ProgramProgressRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramProgressRecord"],
    routeLike: "/program-progress-record-list/add",
    premissionType: "write",
    permissionKeys: ProgramProgressRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramProgressRecord"],
    routeLike: "/program-progress-record-list/edit/[prog_progress_rec_id]",
    premissionType: "write",
    permissionKeys: ProgramProgressRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramRecord"],
    routeLike: "/program-record/add",
    premissionType: "write",
    permissionKeys: ProgramRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userProgramRecord"],
    routeLike: "/program-record/edit/[id]",
    premissionType: "write",
    permissionKeys: ProgramRecordPermission,
  },
  {
    activeKeys: ["masterManagement", "progressManagementMaster"],
    routeLike: "/progress-management",
    premissionType: "both",
    permissionKeys: ProgressManagementMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "progressManagementMaster"],
    routeLike: "/progress-management/add",
    premissionType: "write",
    permissionKeys: ProgressManagementMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "progressManagementMaster"],
    routeLike: "/progress-management/edit/[id]",
    premissionType: "write",
    permissionKeys: ProgressManagementMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "provisionCityMaster"],
    routeLike: "/provisional-cities",
    premissionType: "both",
    permissionKeys: ProvisionCityMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "provisionCityMaster"],
    routeLike: "/provisional-cities/add",
    premissionType: "write",
    permissionKeys: ProvisionCityMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "provisionCityMaster"],
    routeLike: "/provisional-cities/edit/[id]",
    premissionType: "write",
    permissionKeys: ProvisionCityMasterPermission,
  },
  {
    activeKeys: [
      "requiredItemsForApplication",
      "remunerationCalculationCategories",
    ],
    routeLike: "/remuneration-calculation",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_form_management_permissions",
      "remuneration_notification_form",
    ],
  },
  {
    activeKeys: [
      "requiredItemsForApplication",
      "remunerationCalculationCategories",
    ],
    routeLike: "/remuneration-calculation/print",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_form_management_permissions",
      "remuneration_notification_form",
    ],
  },
  {
    activeKeys: ["revenueManagement", "revenueManagement-sales"],
    routeLike: "/revenue-management",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "revenue_management",
    ],
  },
  {
    activeKeys: ["revenueManagement", "revenueManagement-sales"],
    routeLike: "/revenue-management/graph",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_billing_management_permissions",
      "revenue_management",
    ],
  },
  {
    activeKeys: ["requiredItemsForApplication", "selfAssessmentSheetParents"],
    routeLike: "/self-assessment-result-details/[id]",
    premissionType: "both",
    permissionKeys: SelfEvalFormPermission,
  },

  {
    activeKeys: ["requiredItemsForApplication", "selfAssessmentSheetParents"],
    routeLike: "/self-assessment-sheet",
    premissionType: "both",
    permissionKeys: SelfEvalFormPermission,
  },
  {
    activeKeys: ["requiredItemsForApplication", "selfAssessmentSheetParents"],
    routeLike: "/self-assessment-sheet/add",
    premissionType: "write",
    permissionKeys: SelfEvalFormPermission,
  },
  {
    activeKeys: ["requiredItemsForApplication", "selfAssessmentSheetParents"],
    routeLike: "/self-assessment-sheet/[id]/details",
    premissionType: "both",
    permissionKeys: SelfEvalFormPermission,
  },
  {
    activeKeys: ["requiredItemsForApplication", "selfAssessmentSheetParents"],
    routeLike: "/self-assessment-sheet/aggregation/[year]/[facilityId]",
    premissionType: "both",
    permissionKeys: SelfEvalFormPermission,
  },
  {
    activeKeys: ["requiredItemsForApplication", "selfAssessmentSheetParents"],
    routeLike: "/self-assessment-sheet/[id]/results",
    premissionType: "both",
    permissionKeys: SelfEvalFormPermission,
  },
  {
    activeKeys: ["masterManagement", "serviceProvisionContentMaster"],
    routeLike: "/service-provision-items",
    premissionType: "both",
    permissionKeys: [
      "common_facility_permissions",
      "master_management_permissions",
      "service_provision_content_master",
    ],
  },
  {
    activeKeys: ["masterManagement", "serviceProvisionContentMaster"],
    routeLike: "/service-provision-items/add",
    premissionType: "write",
    permissionKeys: [
      "common_facility_permissions",
      "master_management_permissions",
      "service_provision_content_master",
    ],
  },
  {
    activeKeys: ["masterManagement", "serviceProvisionContentMaster"],
    routeLike: "/service-provision-items/edit/[id]",
    premissionType: "write",
    permissionKeys: [
      "common_facility_permissions",
      "master_management_permissions",
      "service_provision_content_master",
    ],
  },
  {
    activeKeys: ["requiredItemsForApplication", "userServiceProvisionRecord"],
    routeLike: "/service-provision-record-sheet",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordSheetPermission,
  },
  {
    activeKeys: ["requiredItemsForApplication", "userServiceProvisionRecord"],
    routeLike: "/service-provision-record-sheet/detail/[id]",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordSheetPermission,
  },
  {
    activeKeys: ["masterManagement", "serviceProvisionContentMaster"],
    routeLike: "/service-provisions",
    premissionType: "both",
    permissionKeys: ServiceProvisionMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "serviceProvisionContentMaster"],
    routeLike: "/service-provisions/add",
    premissionType: "write",
    permissionKeys: ServiceProvisionMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "serviceProvisionContentMaster"],
    routeLike: "/service-provisions/edit/[id]",
    premissionType: "write",
    permissionKeys: ServiceProvisionMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "shiftMaster"],
    routeLike: "/shift-master",
    premissionType: "both",
    permissionKeys: ShiftMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "shiftMaster"],
    routeLike: "/shift-master/add",
    premissionType: "write",
    permissionKeys: ShiftMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "shiftMaster"],
    routeLike: "/shift-master/edit/[id]",
    premissionType: "write",
    permissionKeys: ShiftMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "carMaster"],
    routeLike: "/shuttle-car",
    premissionType: "both",
    permissionKeys: ShuttleCarMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "carMaster"],
    routeLike: "/shuttle-car/add",
    premissionType: "write",
    permissionKeys: ShuttleCarMasterPermission,
  },
  {
    activeKeys: ["masterManagement", "carMaster"],
    routeLike: "/shuttle-car/edit/[id]",
    premissionType: "both",
    permissionKeys: ShuttleCarMasterPermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceRecord"],
    routeLike: "/staff-attendance",
    premissionType: "both",
    permissionKeys: StaffAttendanceRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceRecord"],
    routeLike: "/staff-attendance/bulk-edit",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceRecord"],
    routeLike: "/staff-attendance/detail",
    premissionType: "write",
    permissionKeys: StaffAttendanceRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceRecord"],
    routeLike: "/staff-attendance/edit/[id]",
    premissionType: "both",
    permissionKeys: StaffAttendanceRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "attendanceRecord"],
    routeLike: "/staff-attendance/monthly-record",
    premissionType: "both",
    permissionKeys: StaffAttendanceRecordPermission,
  },
  {
    activeKeys: [
      "requiredItemsForApplication",
      "treatmentImprovementAdditionAmountResultTable",
    ],
    routeLike: "/treatment-additions",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_form_management_permissions",
      "treatment_improvement_form",
    ],
  },
  {
    activeKeys: ["supportPlanManagement", "supportPlanList"],
    routeLike: "/usage-status-list",
    premissionType: "both",
    permissionKeys: [
      "user_facility_permissions",
      "facility_operation_permissions",
      "system_status_management",
    ],
  },
  {
    activeKeys: ["FacilityOperation", "userAttendanceTable"],
    routeLike: "/user-attendance-table",
    premissionType: "both",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userAttendanceTable"],
    routeLike: "/user-attendance-table/add/[facilityId]",
    premissionType: "write",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userAbsenceInformation"],
    routeLike: "/user-attendance-table/absence-record",
    premissionType: "both",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userAttendanceTable"],
    routeLike: "/user-attendance-table/bulk-edit",
    premissionType: "write",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userAttendanceTable"],
    routeLike: "/user-attendance-table/edit/[id]",
    premissionType: "write",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userAttendanceTable"],
    routeLike: "/user-attendance-table/day-list",
    premissionType: "write",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userAttendanceTable"],
    routeLike: "/user-attendance-table/delete-form",
    premissionType: "write",
    permissionKeys: ChildAttendanceManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "renewBeneficiaryCertificae"],
    routeLike: "/users-needing-certificate-renewal",
    premissionType: "write",
    permissionKeys: RecipientCertificatePermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/bulk-print",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/print",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/bulk-print-life-care-record",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/print-life-care-record",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/bulk-print-today-situation",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/print-today-situation",
    premissionType: "both",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userServiceProvision"],
    routeLike: "/user-service-provision-result/[id]",
    premissionType: "write",
    permissionKeys: ServiceProvisionRecordPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userTransportManagement"],
    routeLike: "/user-transport-management",
    premissionType: "both",
    permissionKeys: TransportationManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userTransportManagement"],
    routeLike: "/user-transport-management/[date]",
    premissionType: "both",
    permissionKeys: TransportationManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userTransportManagement"],
    routeLike: "/user-transport-management/[date]/drivers",
    premissionType: "write",
    permissionKeys: TransportationManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userTransportManagement"],
    routeLike: "/user-transport-management/edit/[date]",
    premissionType: "write",
    permissionKeys: TransportationManagementPermission,
  },
  {
    activeKeys: ["FacilityOperation", "userTransportManagement"],
    routeLike: "/user-transport-management/bulk-edit",
    premissionType: "write",
    permissionKeys: TransportationManagementPermission,
  },
  {
    activeKeys: ["pageManagements", "systemUserAccountManagement"],
    routeLike: "/users",
    premissionType: "both",
    permissionKeys: [],
  },
  {
    activeKeys: ["pageManagements", "systemUserAccountManagement"],
    routeLike: "/users/add",
    premissionType: "write",
    permissionKeys: [],
  },
  {
    activeKeys: ["pageManagements", "systemUserAccountManagement"],
    routeLike: "/users/edit/[id]",
    premissionType: "both",
    permissionKeys: [],
  },
  {
    activeKeys: ["FacilityOperation", "attendanceSchedule"],
    routeLike: "/work-style",
    premissionType: "both",
    permissionKeys: [
      [
        "user_facility_permissions",
        "facility_operation_permissions",
        "attendance_schedule",
      ],
      StaffAttendanceRecordPermission,
    ],
  },
  {
    activeKeys: ["FacilityOperation", "businessDayManagement"],
    routeLike: "/bulk-business-day",
    premissionType: "write",
    permissionKeys: [
      "user_facility_permissions",
      "facility_operation_permissions",
      "business_hour_management",
    ],
  },
]

export const FilterAccessibleFacilitiesByPage = (
  facilities,
  facilitySpecificPermissions,
  route
) => {
  const thisPageObj = PageObj.find((o) => o.routeLike == route)
  // apply filter for only facility permission related pages
  // i.e. first key of permission is user_facility_permissions
  if (
    thisPageObj &&
    thisPageObj?.permissionKeys?.length > 0 &&
    ((typeof thisPageObj.permissionKeys[0] == "string" &&
      thisPageObj.permissionKeys[0] == "user_facility_permissions") ||
      (Array.isArray(typeof thisPageObj.permissionKeys[0]) &&
        thisPageObj.permissionKeys[0][0] == "user_facility_permissions"))
  )
    return facilities?.filter((facility) => {
      for (let i = 0; i < facilitySpecificPermissions?.length; i++) {
        if (
          facility?.id == facilitySpecificPermissions[i]?.facility_id &&
          hasPermissionForMenu(
            { user_facility_permissions: facilitySpecificPermissions[i] },
            thisPageObj?.permissionKeys,
            thisPageObj?.premissionType
          )
        ) {
          return true
        }
      }
      return false
    })
  return facilities
}
