import { t } from "i18next"
import { Version, getCurrentVersion } from "../../utils"
import { Moment } from "moment"

type Option = {
  label: string
  value: string
}

// TODO: want to global...
enum ServiceType {
  AFTER_SCHOOL_DAY_SERVICE = 1,
  CHILD_DEVELOPMENT_SUPPORT = 2,
}

export enum RemarksFlg {
  SEVERE_BEHAVIORAL_DISORDERS1 = "1",
  INDIVIDUAL_SUPPORT_ADDITION1 = "2",
  INDIVIDUAL_SUPPORT_ADDITION2 = "3",
  SEVERE_BEHAVIORAL_DISORDERS2 = "4",
  INDIVIDUAL_SUPPORT_ADDITION3 = "5",
  SELF_PLAN = "6",
}

/**
 * Version management: Child Disorder Type Options
 * @param serviceType
 * @return {Option[]}
 */
export function getChildDisorderTypeOptions(date: Moment, serviceType: number) {
  if (!date) {
    return []
  }
  const { version } = getCurrentVersion(date.format("YYYY-MM-DD"))

  switch (version) {
    case Version.BASELINE:
      return baseline
    default:
      return versionR6April(serviceType)
  }
}

/**
 * --------------------------------
 * @version R6_APRIL
 * --------------------------------
 */

function versionR6April(serviceType: number) {
  if (serviceType === ServiceType.AFTER_SCHOOL_DAY_SERVICE) {
    return r6AprilAfterSchoolDayService
  }
  if (serviceType === ServiceType.CHILD_DEVELOPMENT_SUPPORT) {
    return r6AprilChildDevelopmentSupport
  }

  throw new Error(`The version is undefined. ${serviceType}`)
}

/**
 * @ja 備考欄（児童発達支援）
 * @en Remarks field(Child development support)
 */
const r6AprilChildDevelopmentSupport: Option[] = [
  {
    label: t("children with severe behavioral disorders"),
    value: RemarksFlg.SEVERE_BEHAVIORAL_DISORDERS1,
  },
  {
    label: t("Individual support addition (I) applicable"),
    value: RemarksFlg.INDIVIDUAL_SUPPORT_ADDITION1,
  },
  {
    label: t("Individual support addition (II) applicable"),
    value: RemarksFlg.INDIVIDUAL_SUPPORT_ADDITION2,
  },
  {
    // TODO: translate
    label: "個別サポート加算(III)該当",
    value: RemarksFlg.INDIVIDUAL_SUPPORT_ADDITION3,
  },
  {
    // TODO: translate
    label: "セルフプラン対象",
    value: RemarksFlg.SELF_PLAN,
  },
]

/**
 * @ja 備考欄（放課後等デイサービス）
 * @en Remarks field(After school day service)
 */
const r6AprilAfterSchoolDayService: Option[] = [
  {
    // TODO: translate
    label: t("children with severe behavioral disorders") + "(I)",
    value: RemarksFlg.SEVERE_BEHAVIORAL_DISORDERS1,
  },
  {
    // TODO: translate
    label: t("children with severe behavioral disorders") + "(II)",
    value: RemarksFlg.SEVERE_BEHAVIORAL_DISORDERS2,
  },
  {
    label: t("Individual support addition (I) applicable"),
    value: RemarksFlg.INDIVIDUAL_SUPPORT_ADDITION1,
  },
  {
    label: t("Individual support addition (II) applicable"),
    value: RemarksFlg.INDIVIDUAL_SUPPORT_ADDITION2,
  },
  {
    // TODO: translate
    label: "個別サポート加算(III)該当",
    value: RemarksFlg.INDIVIDUAL_SUPPORT_ADDITION3,
  },
  {
    // TODO: translate
    label: "セルフプラン対象",
    value: RemarksFlg.SELF_PLAN,
  },
]

/**
 * --------------------------------
 * @version BASELINE
 * --------------------------------
 */

const baseline: Option[] = [
  {
    label: t("children with severe behavioral disorders"),
    value: "1",
  },
  {
    label: t("Individual support addition (I) applicable"),
    value: "2",
  },
  {
    label: t("Individual support addition (II) applicable"),
    value: "3",
  },
]
