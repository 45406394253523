import { Tag } from "antd"
import React from "react"
import styled from "styled-components"

const RequiredTag = styled(Tag)`
  width: 40px;
  height: 24px;
  border-radius: 5px;
`
export interface iRequiredProps {
  style?: any
}

const Required = ({ style }: iRequiredProps) => {
  return (
    <RequiredTag color={"#E00000"} style={style}>
      {"必須"}
    </RequiredTag>
  )
}

export { Required }
