/* stylelint-disable no-descending-specificity */
import { Card } from "antd"
import React from "react"
import styled from "styled-components"
import { theme } from "../../../theme"

interface ContainerProps {
  className?: string
  background?: boolean | string
  children?: React.ReactNode
}

const StyledGrid = styled(Card.Grid)`
  position: relative;
  border: 1px solid #d2d1d1;
  box-shadow: none;
  padding: 14px 20px;
  &.grid-header {
    width: 100px !important;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 7px 10px 10px;
    &.grid-header {
      width: 100% !important;
      :first-child {
        border-bottom: 0;
      }
      ~ div:not(.grid-header) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.grid-row-last {
      padding-bottom: 30px;
      @media (max-width: ${theme.breakpoints.md}) {
        border-bottom: 0;
      }
    }
    &.last-table-grid {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }
  &.no-padding {
    padding: 0 !important;
  }
  &.flex-full {
    flex: 1 0 auto;
  }
  &.flex-break {
    flex-basis: 100%;
    border: 0;
    padding: 0;
    + .ant-card-grid {
      border-left: 1px solid #d2d1d1;
    }
    ~ .ant-card-grid {
      border-top: 0;
    }
  }
  .grid-content {
    display: flex;
    align-items: center;
    height: 100%;
    & .inner-grid-content {
      width: 100%;
      color: #ff8200;
    }
  }
  + .ant-card-grid {
    border-left: 0;
  }
  .password-show-hide {
    position: absolute;
    right: 50px;
    top: 25%;
    cursor: pointer;

    @media (max-width: 767px) {
      top: 20px;
      right: 15px;
    }
  }
  .eye-icon {
    transform: scale(1.3);
  }
`
const Container: React.FC<ContainerProps> = ({
  className,
  background,
  children,
}) => (
  <StyledGrid
    className={className}
    style={{
      background: background
        ? background === true
          ? "#f3f3f3"
          : background
        : "#fff",
    }}
    hoverable={false}
  >
    <div className={"grid-content"}>
      <div className={"inner-grid-content"}>{children || null}</div>
    </div>
  </StyledGrid>
)

export { Container }
