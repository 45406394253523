import { Radio as RadioButton } from "antd"
import React from "react"
import styled from "styled-components"

export interface RadioOptions {
  label: string
  value: string | number | boolean
  disabled?: boolean
}

export interface RadioProps {
  name?: string
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void
  options?: RadioOptions[]
  value?: string | number | boolean | any
  label?: string
  error?: any
  disabled?: boolean
  className?: string
  defaultValue?: string | number | boolean | any
}

const Wrapper = styled.div`
  .test {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    margin-bottom: 6px;
    :hover .ant-radio::after {
      visibility: hidden;
    }
  }
  .ant-radio {
    top: 0;
    :hover::after {
      visibility: hidden;
    }
    .ant-radio-inner {
      background: #ffffff;
      border: 1px solid #d2d1d1;
      width: 25px;
      height: 25px;
      box-shadow: none;
    }
    &.ant-radio-checked {
      .ant-radio-inner {
        ::after {
          background-color: #000;
          transform: scale(0.8);
          transition: none;
        }
      }
    }
    + span {
      line-height: 30px;
    }
  }

  .radio-group p {
    color: #e00000;
    font-size: 14px;
  }
`
const RadioButtons: React.FC<RadioProps> = ({
  name,
  onChange,
  options,
  value,
  error,
  label,
  disabled,
  className = "",
  defaultValue,
}: RadioProps) => {
  const radioButtons = []
  for (let id = 0; id < options.length; id++) {
    radioButtons.push(
      <RadioButton
        key={options[id]?.value?.toString()}
        value={options[id].value}
        disabled={options[id].disabled || false}
      >
        {options[id].label}
      </RadioButton>
    )
  }

  return (
    <Wrapper className={className}>
      {label && <p className={"label"}> {label} </p>}
      <div className={"radio-group"}>
        <RadioButton.Group
          name={name}
          onChange={onChange}
          value={value}
          disabled={disabled}
          className={className}
          defaultValue={defaultValue}
        >
          {radioButtons}
        </RadioButton.Group>
        {error && <p>{error}</p>}
      </div>
    </Wrapper>
  )
}

export { RadioButtons }
