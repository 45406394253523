import React from "react"
import { Modal as AntdModal } from "antd"
import { ModalProps } from "antd/es/modal"
import styled from "styled-components"

const ModalWrapper = styled(AntdModal)<IProps>`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-close {
    right: ${(props) => (props?.btnRightMargin ? props?.btnRightMargin : ``)};
  }
  .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ant-modal-body {
    padding: ${(props) => (props?.noPadding ? "0px" : "24px 22px")};
  }
  .ant-btn-primary,
  .ant-btn-default {
    justify-content: center;
    align-items: center;
    line-height: 0;
    border-radius: 100px;
    padding: 20px;
  }
  .ant-btn-primary {
    background-color: #0782c8;
  }
`
interface IProps extends ModalProps {
  children: React.ReactNode
  radius?: number
  bgColor?: string
  btnRightMargin?: string
  noPadding?: boolean
}
export const Modal: React.FC<IProps> = ({ children, ...rest }) => {
  return <ModalWrapper {...rest}>{children}</ModalWrapper>
}
