import { t } from "i18next"
import moment from "moment"
import { getLanguage } from "./getLanguage"

export const MONTH_OPTIONS = Array.from(new Array(12), (_, i) => i + 1).map(
  (n) => ({
    label: n.toString(),
    value: +n,
  })
)
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]
export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

/**
 * All purpose padded month generator
 * @param {number | string} month - Accepts in month format: Month name(January...December), 0-11 (from Date#getMonth), or 1月, 2月, ... 12月
 * @returns {string} Padded numbering like 01, 02, ... 12
 */
export const getPaddedMonth = (month: number | string) => {
  if (isNaN(+month)) {
    if (month && (month as string)?.includes("月")) {
      month = (month as string).replace("月", "")
      month = +month - 1
    } else {
      month = MONTHS.indexOf(month as string)
    }
  }
  month = `${+month + 1}`
  return month.padStart(2, "0")
}

// Gets padded day. E.g. 1 => 01, numbers from 10 are unchanged
export const getPaddedDay = (day: number | string) => {
  if (+day < 10) {
    return `0${day}`
  }
  return day
}

export const DAY_OPTIONS = DAYS.map((day) => ({
  label: t(day),
  value: day,
}))

export const PAST_YEAR_START = 1989
export const PAST_YEAR_OPTIONS = Array.from(
  new Array(new Date().getFullYear() + 1 - PAST_YEAR_START),
  (_, i) => i + PAST_YEAR_START
).map((n) => ({
  label: n.toString(),
  value: n,
}))
export const CREATED_NUMBER_MONTH_OPTIONS = Array.from(
  new Array(50),
  (_, i) => i + 1
).map((n) => ({
  label: n.toString(),
  value: +n,
}))

export const getDateString = (year, month) => {
  const date = new Date(year, month - 1)
  const formatter = new Intl.DateTimeFormat("ja-JP", {
    year: "numeric",
    month: "2-digit",
  })
  const parts = formatter.formatToParts(date)
  const yearStr = parts.find((part) => part.type === "year").value
  const monthStr = parts.find((part) => part.type === "month").value
  return `${yearStr}年${monthStr}月`
}

export const getDaysInMonth = (
  year: number,
  month: number,
  activites: any,
  timeZone: string
) => {
  const date = new Date(year, month, 1)
  let days = {}
  while (date.getMonth() === month) {
    const _key = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}T00:00:00Z`
    setTimetoZeroInISOString(date.toISOString(), timeZone)

    days = {
      ...days,
      [_key]: [{ ...activites, uid: `${Math.random()}` }],
    }
    date.setDate(date.getDate() + 1)
    date.setTime(date.getTime())
  }
  return days
}

const setTimetoZeroInISOString = (date, timeZone: string) => {
  return `${date.split("T")[0]}${timeZone}`
}

export const timeToSQLDate = (date: string) =>
  date
    ? new Date(date).toISOString().slice(0, 19).replace("T", " ")
    : "1900-01-01" // placeholder for empty

export const getHolidayClassName = (day) => {
  switch (day) {
    case "土":
      return "saturday"
    case "日":
      return "sunday"
    default:
      return ""
  }
}

export const getDay = (day) => {
  return new Date(day).toLocaleDateString("ja-JP", {
    weekday: "short",
  })
}

export const getDate = (day) => {
  return new Date(day).getDate()
}
export const getYearRange = (down?: number, up?: number) => {
  let startYear = down
    ? new Date().getFullYear() - down
    : new Date().getFullYear()
  const endYear = up ? new Date().getFullYear() + up : new Date().getFullYear()
  const years = []
  while (startYear <= endYear) {
    years.push(startYear++)
  }
  return years?.map((val) => ({
    label: `${val}`,
    value: val,
  }))
}
export const getDateStringWithDay = (date, lang?: string) => {
  const local = getLanguage()
  const language = lang || local === "ja" ? "ja-JP" : "en-US"
  moment.locale(language)
  const year = moment(date).format("YYYY")
  const month =
    language === "ja-JP"
      ? moment(date).format("MM")
      : moment(date).format("MMMM")
  const day = `${moment(date).format("DD")}`
  return t("{{ month }} {{day}}, {{year}}", {
    month,
    day,
    year,
  })
}

export const JAPANESE_YEARS_OPTIONS = [
  { label: "平成1年", value: 1989 },
  { label: "平成2年", value: 1990 },
  { label: "平成3年", value: 1991 },
  { label: "平成4年", value: 1992 },
  { label: "平成5年", value: 1993 },
  { label: "平成6年", value: 1994 },
  { label: "平成7年", value: 1995 },
  { label: "平成8年", value: 1996 },
  { label: "平成9年", value: 1997 },
  { label: "平成10年", value: 1998 },
  { label: "平成11年", value: 1999 },
  { label: "平成12年", value: 2000 },
  { label: "平成13年", value: 2001 },
  { label: "平成14年", value: 2002 },
  { label: "平成15年", value: 2003 },
  { label: "平成16年", value: 2004 },
  { label: "平成17年", value: 2005 },
  { label: "平成18年", value: 2006 },
  { label: "平成19年", value: 2007 },
  { label: "平成20年", value: 2008 },
  { label: "平成21年", value: 2009 },
  { label: "平成22年", value: 2010 },
  { label: "平成23年", value: 2011 },
  { label: "平成24年", value: 2012 },
  { label: "平成25年", value: 2013 },
  { label: "平成26年", value: 2014 },
  { label: "平成27年", value: 2015 },
  { label: "平成28年", value: 2016 },
  { label: "平成29年", value: 2017 },
  { label: "平成30年", value: 2018 },
  { label: "令和1年", value: 2019 },
  { label: "令和2年", value: 2020 },
  { label: "令和3年", value: 2021 },
  { label: "令和4年", value: 2022 },
  { label: "令和5年", value: 2023 },
  { label: "令和6年", value: 2024 },
  { label: "令和7年", value: 2025 },
  { label: "令和8年", value: 2026 },
  { label: "令和9年", value: 2027 },
  { label: "令和10年", value: 2028 },
]

export const getCurrentJapaneseDate = () => {
  const japaneseDateWithEra = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
    era: "long",
    year: "numeric",
    month: "2-digit",
  }).format(Date.now())
  //Above date is in the format of 令和5年 where the characters before the numeric value 5 represents era, split the string and get era.
  return t(japaneseDateWithEra)
}

export const getCurrentJapaneseEra = () => {
  const japaneseDateWithEra = new Intl.DateTimeFormat("ja-JP-u-ca-japanese", {
    era: "long",
    year: "numeric",
  }).format(Date.now())
  //Above date is in the format of 令和5年 where the characters before the numeric value 5 represents era, split the string and get era.
  return t(japaneseDateWithEra?.split(/[0-9]/)[0])
}

export const genderType = {
  1: t("Male"),
  2: t("Female"),
}

export const formatChangedDate = (year: number, month: number) => {
  return moment(`${year}-${month}`)
}

export const WEEKDAYS = ["日", "月", "火", "水", "木", "金", "土"]
export const WEEKLONGERDAYS = [
  "日曜日",
  "月曜日",
  "火曜日",
  "水曜日",
  "木曜日",
  "金曜日",
  "土曜日",
]
