import React from "react"
import { Breadcrumb } from "antd"
import Link from "next/link"
import styled from "styled-components"
import { RightOutlined } from "@ant-design/icons"
import { BreadcrumbComponentProps, BreadcrumbItem } from "../../../interface"

const { Item } = Breadcrumb

const BreadcrumbStyled = styled(Breadcrumb)`
  word-break: keep-all;
  min-height: 35px;
  margin: auto;
  width: 100%;
  & a {
    color: #0782c8;
    text-decoration: underline;
  }
  .anticon svg {
    width: 10px;
    height: 10px;
    color: #444748;
  }

  .no-link {
    text-decoration: none;
    cursor: default;
  }
  @media print {
    display: none;
  }
`

const ItemStyled = styled(Item)`
  .display-name {
    font-size: 12px;
    line-height: 37px;
  }
`

const renderBreadcrumbItem = (items: BreadcrumbItem[]): JSX.Element[] => {
  const breadcrumbItems: JSX.Element[] = []
  for (const item of items) {
    breadcrumbItems.push(
      <ItemStyled key={items.indexOf(item)} onClick={item.onClick}>
        {item.href ? (
          <Link href={item?.href}>
            <a className={"display-name"} style={{ cursor: "cursor" }}>
              {item.name}
            </a>
          </Link>
        ) : (
          <span
            className={"display-name  no-link"}
            style={{ cursor: "default" }}
          >
            {item.name}
          </span>
        )}
      </ItemStyled>
    )
  }
  return breadcrumbItems
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({ items }) => {
  return (
    <BreadcrumbStyled separator={<RightOutlined width={8} height={10} />}>
      {items && renderBreadcrumbItem(items)}
    </BreadcrumbStyled>
  )
}

export { BreadcrumbComponent }
