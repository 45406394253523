import React from "react"
import styled from "styled-components"

// Replace with ant dropdown
const StyledDropdown = styled.div`
  border: 1px solid #d2d1d1;
  padding: 14px;
  width: 100%;
  margin-bottom: 8px;
  text-align: left;
  cursor: pointer;
`

interface DropdownProps {
  defaultValue: string
  options?: {
    key: string
    value: string
  }[]
}

const Dropdown = ({ defaultValue }: DropdownProps) => {
  return <StyledDropdown>{defaultValue}</StyledDropdown>
}

export { Dropdown }
