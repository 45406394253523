import React, { ReactNode } from "react"
import Head from "next/head"
import * as yup from "yup"
import { useTranslation } from "react-i18next"
import { Button } from "antd"
import { useFormik } from "formik"
import Link from "next/link"
import {
  Container,
  InputFieldWrapper,
  LoginWrapper,
  TextFieldWrapper,
  Wrapper,
} from "./login.style"
import { TextField } from "../../atom"

import { Footer } from "../layout/Footer"
import { Header } from "../layout/Header"
interface LoginType {
  email: string
  password: string
}

interface LoginComponentProps {
  handleLogin: (values: LoginType) => void
  loading: boolean
  logo: ReactNode
}
const LoginComponent = ({
  logo,
  handleLogin,
  loading,
}: LoginComponentProps) => {
  const { t } = useTranslation()

  const validationSchema = yup.object().shape({
    email: yup.string().email(t("Enter Email")).required(t("Enter Email")),
    password: yup.string().required(t("Enter Password")),
  })
  const formik = useFormik<LoginType>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLogin,
  })
  return (
    <>
      <Head>
        <title>{t("施設管理画面ログイン")}</title>
      </Head>
      <Wrapper>
        <Header />
        <Container style={{ flex: 1 }}>
          <div className={"top-content-wrapper"}>
            <div className={"logo-wrapper"}>{logo}</div>
            <h3>{t("Facility management login")}</h3>
          </div>
          <LoginWrapper>
            <TextFieldWrapper>
              <form onSubmit={formik.handleSubmit}>
                <InputFieldWrapper>
                  <TextField
                    name={"email"}
                    error={formik.touched.email && formik.errors.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Email")}
                    placeholder={t("Enter Email")}
                    className={"text-field"}
                    height={"48px"}
                    width={"368px"}
                    borderradius={"8px"}
                    bgcolor={"transparent"}
                  />
                </InputFieldWrapper>

                <InputFieldWrapper>
                  <TextField
                    name={"password"}
                    type={"password"}
                    error={formik.touched.password && formik.errors.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("Password")}
                    placeholder={t("Enter Password")}
                    className={"text-field"}
                    height={"48px"}
                    width={"368px"}
                    borderradius={"8px"}
                    bgcolor={"transparent"}
                  />
                  <p className={"password-info-text"}>
                    {t("Password must be more than 6 characters")}
                  </p>
                </InputFieldWrapper>

                <div className={"login-button-wrapper"}>
                  <Button htmlType={"submit"} loading={loading} shape={"round"}>
                    {t("Login")}
                  </Button>
                </div>

                <div className={"forget-password-wrapper"}>
                  <p>{t("Forgot your password ? ")}</p>
                  <Link href={"/forget-password"}>{t("Reset")}</Link>
                </div>
              </form>
            </TextFieldWrapper>
          </LoginWrapper>
        </Container>
        <Footer />
      </Wrapper>
    </>
  )
}
LoginComponent.displayName = "LoginComponent"
export { LoginComponent }
