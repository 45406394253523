import { t } from "i18next"
import moment from "moment"
import { JAPANESE_YEARS_OPTIONS } from "./date"
import wareki from "wareki"

/**
 * @param moment birthday
 *this function takes birthday and return school grade in string
 */

export const renderSchoolYear = (birthday?: moment.Moment | null) => {
  const birthDate = new Date(birthday.format("YYYY-MM-DD"))
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const aprilFirstThisYear = new Date(currentYear, 3, 1) // Month is 0-based, so 3 = April

  // If today is before April 1st, the current school year hasn't started yet
  if (currentDate < aprilFirstThisYear) {
    aprilFirstThisYear.setFullYear(currentYear - 1) // Go back to the previous April 1st
  }

  let ageOnAprilFirst =
    aprilFirstThisYear.getFullYear() - birthDate.getFullYear()

  if (
    aprilFirstThisYear.getMonth() < birthDate.getMonth() ||
    (aprilFirstThisYear.getMonth() === birthDate.getMonth() &&
      aprilFirstThisYear.getDate() < birthDate.getDate())
  ) {
    ageOnAprilFirst--
  }

  // Grades in Japan are as follows:
  // Age 5: Not in primary school yet
  //1 Age 6: Elementary school 1Grader
  //2 Age 7: Elementary school 2Grader
  //3 Age 8: Elementary school 3Grader
  //4 Age 9: Elementary school 4Grader
  //5 Age 10: Elementary school 5Grader
  //6 Age 11: Elementary school 6Grader
  //7 Age 12: Junior high 1Grader
  //8 Age 13: Junior high 2Grader
  //9 Age 14: Junior high 3Grader
  //10 Age 15: High school 1Grader
  //11 Age 16: High school 2Grader
  //12 Age 17: High school 3Grader
  //13 Age 18: Out of School

  let grade = "---"

  if (ageOnAprilFirst >= 6 && ageOnAprilFirst <= 17) {
    const gradeNumber = ageOnAprilFirst - 5
    if (gradeNumber < 7)
      grade = `${t("Elementary school")} ${gradeNumber}${t("Grader")}`
    else if (gradeNumber < 10)
      grade = `${t("Junior high")} ${gradeNumber - 6}${t("Grader")}`
    else if (gradeNumber < 13)
      grade = `${t("High school")} ${gradeNumber - 9}${t("Grader")}`
  }

  return grade
}

/**
 * @param date
 *this function takes date and return it in in format of
 * @returns 平成23年02月03日 (11歳) (小学6年生)
 */

//
export const getDOB = (date: string, hideGrade?: boolean) => {
  const year = JAPANESE_YEARS_OPTIONS.find(
    (item) => item.value === Number(moment(date).format("YYYY"))
  )?.label
  const dobMonthDay = moment(date).format("MM月DD日")
  const age = Math.floor(moment(new Date()).diff(moment(date), "years", true))
  const grade = renderSchoolYear(moment(date))
  if (hideGrade) {
    return `${year}${dobMonthDay}`
  }
  if (grade === "--" || !grade) {
    return `${year}${dobMonthDay} (${age}歳)`
  }
  return `${year}${dobMonthDay} (${age}歳) (${grade})`
}

export const getWarekiDOB = (
  date: string,
  hideGrade?: boolean,
  type?: "AD" | "JP"
) => {
  const year = wareki(
    `${Number(moment(date).format("YYYY"))}-${Number(
      moment(date).format("MM")
    )}`,
    { unit: true }
  )
  const dobMonthDay = moment(date).format("MM月DD日")
  const age = Math.floor(moment(new Date()).diff(moment(date), "years", true))
  const grade = renderSchoolYear(moment(date))
  if (hideGrade) {
    return `${year}${dobMonthDay}`
  }
  if (grade === "---" || !grade) {
    return `${year}${dobMonthDay} (${age}歳)`
  }
  if (type === "AD") {
    return `${moment(date).format("YYYY年MM月DD日")} (${age}歳) (${grade})`
  }

  return `${year}${dobMonthDay} (${age}歳) (${grade})`
}
