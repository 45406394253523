import styled from "styled-components"
export const Wrapper = styled.div<{ widthMore?: boolean }>`
  .delete-btn button {
    color: red;
  }

  .file-area {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-btn {
      color: #888787;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      width: ${(props) => (props.widthMore ? "150px !important" : "130px")};
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      @media (max-width: 360px) {
        margin-right: 0px;
      }
      .ant-space-item {
        font-size: 16px;
        font-weight: 400;
      }
    }
    & .min-width-class {
      @media (max-width: 411px) {
        min-width: 98px;
      }
    }
  }
  .upload-hint {
    font-size: 14px;
    font-weight: 400;
    color: #191919;
    word-break: break-all;
  }
`

export const DisplayFile = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  img {
    min-width: 200px;
    object-fit: cover;
  }
  & > :first-child {
    margin-right: 20px;
  }
`
