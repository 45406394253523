import styled from "styled-components"
import { theme } from "../../../theme"

export const Wrapper = styled.div`
  width: 300px;
  max-width: 300px;
  background-color: white;
  padding: 0.2em;
  @media screen and (max-width: ${theme.breakpoints.xs}) {
    width: 100%;
    max-width: 100%;
  }
`
export const PickerHeader = styled.div`
  display: flex;
  background-color: #e6e6e6;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.5rem;
  border-radius: 4px;
  gap: 0.3rem;
  .prev_date,
  .next_date {
    background-color: ${theme.black};
  }
  .date_selector {
    display: flex;
    align-items: center;
    gap: 0.1rem;
    .content {
      &:last-child {
        margin-left: 0.3rem;
      }
    }
    .year__selector,
    .month__selector {
      background-color: white !important;
      .ant-select-selector {
        padding: 0 12px !important;
      }
    }
  }
  select {
    &:focus,
    &:active {
      outline: none;
    }
  }
`
export const PickerContent = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.2rem;
  .picker__weekday {
    padding: 0.7em 0.3em;
    text-align: center;
    font-weight: bold;
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      font-size: 12px !important;
    }
  }
  .picker_date {
    border: 1px solid #d3d3d3;
    background: #e6e6e6 50% 50% repeat-x;
    font-weight: normal;
    color: #555555;
    padding: 0.2em;
    text-align: right;
    cursor: pointer;
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      font-size: 12px !important;
    }
  }
  .active__picker_date {
    background-color: ${theme.red6} !important;
    border-color: ${theme.red5} !important;
  }
  .disabled__picker_date {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }
  .today__date {
    background-color: ${theme.red5} !important;
    border-color: ${theme.red6} !important;
  }
`
export const PickerFooter = styled.div`
  margin-top: 0.5rem;
  border-top: 1px solid #d3d3d3;
  display: flex;
  padding-top: 0.5rem;
  justify-content: space-between;
  .picker__footer-right {
    display: flex;
    gap: 0.5rem;
  }
  button {
    background-color: #e6e6e6 !important;
    border-radius: 4px !important;
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      font-size: 12px !important;
      padding: 2px 5px;
    }
  }
`

export const WarikeDatePickerContainer = styled.div`
  .picker__input {
    border-radius: 4px;
    width: 155px;
    padding: 0.5rem;
  }
  .error__msg {
    color: ${theme.red4};
    font-size: 12px;
  }
`
