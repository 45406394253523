import { t } from "i18next"

export const FuriganaAlphabetsOption = [
  {
    label: "---",
    value: 0,
  },
  {
    label: "あ",
    value: "あ",
  },
  {
    label: "い",
    value: "い",
  },
  {
    label: "う",
    value: "う",
  },
  {
    label: "え",
    value: "え",
  },
  {
    label: "お",
    value: "お",
  },
  {
    label: "か",
    value: "か",
  },
  {
    label: "き",
    value: "き",
  },
  {
    label: "く",
    value: "く",
  },
  {
    label: "け",
    value: "け",
  },
  {
    label: "こ",
    value: "こ",
  },
  {
    label: "さ",
    value: "さ",
  },
  {
    label: "し",
    value: "し",
  },
  {
    label: "す",
    value: "す",
  },
  {
    label: "せ",
    value: "せ",
  },
  {
    label: "そ",
    value: "そ",
  },
  {
    label: "た",
    value: "た",
  },
  {
    label: "ち",
    value: "ち",
  },
  {
    label: "つ",
    value: "つ",
  },
  {
    label: "て",
    value: "て",
  },
  {
    label: "と",
    value: "と",
  },
  {
    label: "な",
    value: "な",
  },
  {
    label: "に",
    value: "に",
  },
  {
    label: "ぬ",
    value: "ぬ",
  },
  {
    label: "ね",
    value: "ね",
  },
  {
    label: "の",
    value: "の",
  },
  {
    label: "は",
    value: "は",
  },
  {
    label: "ひ",
    value: "ひ",
  },
  {
    label: "ふ",
    value: "ふ",
  },
  {
    label: "へ",
    value: "へ",
  },
  {
    label: "ほ",
    value: "ほ",
  },
  {
    label: "ま",
    value: "ま",
  },
  {
    label: "み",
    value: "み",
  },
  {
    label: "む",
    value: "む",
  },
  {
    label: "め",
    value: "め",
  },
  {
    label: "も",
    value: "も",
  },
  {
    label: "や",
    value: "や",
  },
  {
    label: "ゆ",
    value: "ゆ",
  },
  {
    label: "よ",
    value: "よ",
  },
  {
    label: "ら",
    value: "ら",
  },
  {
    label: "り",
    value: "り",
  },
  {
    label: "る",
    value: "る",
  },
  {
    label: "れ",
    value: "れ",
  },
  {
    label: "ろ",
    value: "ろ",
  },
  {
    label: "わ",
    value: "わ",
  },
  {
    label: "を",
    value: "を",
  },
  {
    label: "ん",
    value: "ん",
  },
]

export const CREATE_STATUS_TYPE_OPTIONS = [
  {
    label: "---",
    value: "",
  },
  {
    label: t("Assessment"),
    value: "assessment",
  },
  {
    label: t("Individual support plan (draft)"),
    value: "support_plan_draft",
  },
  {
    label: t("Individual support plan"),
    value: "support_plan",
  },
  {
    label: t("Service Personal Meeting(Support Meeting) Minutes"),
    value: "addition_meeting",
  },
  {
    label: t("Monitoring"),
    value: "monitoring",
  },
]

export const CREATE_STATUS_OPTIONS = [
  {
    label: t("Created"),
    value: "1",
  },
  {
    label: t("Draft"),
    value: "2",
  },
  {
    label: t("Not created"),
    value: "3",
  },
]

export const FACILITY_BILLING_METHOD = {
  "1": "normal billing",
  "2": "Reduced user fees and billing",
  "3": "Maximum monthly user fee (1)",
}
