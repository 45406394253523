import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { t } from "i18next"
import React from "react"
import styled from "styled-components"

interface BusinessCalendarPaginationProps {
  year: number
  currentYear: number
  activeYear: "current" | "next" | "prev"
  currentMonth: number
  handleYearChange: (e: any, year: number, active: string) => void
  handleMonthChange: (e: any, month: number) => void
  hideNextButtons?: boolean
  hideMonths?: boolean
  noFb?: boolean
}

const Wrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  flex-basis: ${({ noFb }) => (!noFb ? " 100%" : "unset")};
  overflow-x: scroll;
  /* show scrollbar all time*/
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    /* color of the scroll thumb */
    border-radius: 10px;
    /* roundness of the scroll thumb */
  }
  /* show scrollbar all time*/
  div {
    position: relative;
    display: flex;
    text-align: center;
    a {
      width: 40px;
      height: 40px;
      line-height: 37px;
      text-decoration: none;
      border: 1px solid #d2d1d1;
      :not(:last-child) {
        border-right: 0;
      }
      :first-child {
        border-radius: 10px 0 0 10px;
        width: 75px;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
        width: 75px;
      }
      &.active {
        background-color: #cde6f4;
      }
    }
    &.year-pagination {
      margin-left: 20px;
      a {
        width: fit-content !important;
        padding: 0 2px;
      }
    }
  }
`

const BusinessCalendarPagination: React.FC<BusinessCalendarPaginationProps> = ({
  year,
  handleYearChange,
  handleMonthChange,
  currentYear,
  activeYear,
  currentMonth,
  hideNextButtons = false,
  hideMonths = false,
  noFb,
}) => {
  return (
    <Wrapper noFb={noFb}>
      {!hideMonths && (
        <div className={"month-pagination"}>
          <a
            href={"#"}
            onClick={(e) => handleYearChange(e, currentYear - 1, "prev")}
          >{`${currentYear - 1}年`}</a>
          {[...Array(12)].map((_, i) => (
            <a
              key={i}
              href={"#"}
              className={i + 1 == currentMonth ? "active" : ""}
              onClick={(e) => handleMonthChange(e, i + 1)}
            >
              {i + 1}
            </a>
          ))}
          <a
            href={"#"}
            onClick={(e) => handleYearChange(e, currentYear + 1, "next")}
          >{`${currentYear + 1}年`}</a>
        </div>
      )}
      {!hideNextButtons && (
        <div className={"year-pagination"}>
          <a
            className={activeYear === "prev" ? "active" : ""}
            href={"#"}
            onClick={(e) => handleYearChange(e, currentYear - 1, "prev")}
          >
            <DoubleLeftOutlined />
            {t("Last year")}
          </a>
          <a
            className={activeYear === "current" ? "active" : ""}
            href={"#"}
            onClick={(e) => handleYearChange(e, year, "current")}
          >
            {t("This year")}
          </a>
          <a
            className={activeYear === "next" ? "active" : ""}
            href={"#"}
            onClick={(e) => handleYearChange(e, currentYear + 1, "next")}
          >
            {t("Next year")}
            <DoubleRightOutlined />
          </a>
        </div>
      )}
    </Wrapper>
  )
}

export { BusinessCalendarPagination }
