import React from "react"

// packages
import { t } from "i18next"
import styled from "styled-components"

// theme
import { theme } from "../../../theme"

// styled components
const StyledUnderMaintenanceCard = styled.div<{
  $cardTheme?: "CHILD" | "OWNER"
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${theme.base};
  padding: 66px 24px;
  border-radius: 12px;
  .card-icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-radius: 50%;
    background-color: ${({ $cardTheme }) =>
      $cardTheme === "CHILD" ? "#FCE9CE" : "#FEECAF"};
  }
  .body-content {
    margin-top: 32px;
    max-width: 493px;
    .title {
      font-size: 20px;
      font-weight: 500;
    }
    .description {
      font-size: 20px;
      font-weight: 400;
    }
  }

  p,
  h3 {
    margin: 0;
    padding: 0;
  }
`

// icons
export const UnderMaintenanceCardIcon = () => {
  return (
    <svg
      width={"75"}
      height={"75"}
      viewBox={"0 0 75 75"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
    >
      <g clipPath={"url(#clip0_19861_364952)"}>
        <path
          d={"M41.8809 39.5703L47.6621 45.3516"}
          stroke={"black"}
          strokeWidth={"1.875"}
          strokeMiterlimit={"10"}
        />
        <path
          d={
            "M66.1624 70.7888L73.0996 63.8516L51.1311 41.8831L44.1939 48.8203L66.1624 70.7888Z"
          }
          stroke={"black"}
          strokeWidth={"1.875"}
          strokeMiterlimit={"10"}
        />
        <path
          d={
            "M14.1309 18.7578L21.0684 11.8203L9.50586 4.88281L7.19336 7.19531L14.1309 18.7578Z"
          }
          stroke={"black"}
          strokeWidth={"1.875"}
          strokeMiterlimit={"10"}
        />
        <path
          d={"M32.6309 30.3203L17.5996 15.2891"}
          stroke={"black"}
          strokeWidth={"1.875"}
          strokeMiterlimit={"10"}
        />
        <path
          d={
            "M67.3184 14.7084L58.0684 19.0443L53.4434 14.2748L57.4902 4.95313C45.9277 4.95197 43.0371 10.6615 43.0371 16.4428V21.0678L4.30273 60.3803L2.2793 67.3178L4.73633 69.6303L11.7455 67.8959L51.1309 29.1615H55.7559C61.5371 29.1615 67.3184 26.2709 67.3184 14.7084Z"
          }
          stroke={"black"}
          strokeWidth={"1.875"}
          strokeMiterlimit={"10"}
        />
      </g>
      <defs>
        <clipPath id={"clip0_19861_364952"}>
          <rect
            width={"74"}
            height={"74"}
            fill={"white"}
            transform={"translate(0.255859 0.257812)"}
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const UnderMaintenanceCard = ({
  cardTheme,
  ...rest
}: {
  cardTheme?: "CHILD" | "OWNER"
} & React.ComponentPropsWithRef<"div">) => {
  return (
    <StyledUnderMaintenanceCard $cardTheme={cardTheme} {...rest}>
      <div className={"card-icon-content"}>
        <UnderMaintenanceCardIcon />
      </div>

      <div className={"body-content"}>
        <h3 className={"title"}>
          <span>{t("Sorry, This page is under maintenance.")}</span>
        </h3>
        <p className={"description"}>
          <span>
            {t(
              "We appreciate your patience and will notify soon about new updates. Thank you!"
            )}
          </span>
        </p>
      </div>
    </StyledUnderMaintenanceCard>
  )
}
