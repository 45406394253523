export const handleScrollBodyBehavior = (val?: boolean, size?: number) => {
  if (typeof window !== undefined) {
    const windowSize = size || window.innerWidth
    const mBody = document.getElementsByClassName("ant-modal-body")[0]
    if (windowSize <= 768) {
      if (val) {
        document.body.style.overflow = "hidden"
        if (mBody) {
          //@ts-ignore
          mBody.style.overflowY = "hidden"
        }
      } else {
        document.body.style.overflow = ""
        if (mBody) {
          //@ts-ignore
          mBody.style.overflowY = "auto"
        }
      }
    } else {
      document.body.style.overflow = ""
      if (mBody) {
        //@ts-ignore
        mBody.style.overflowY = "auto"
      }
    }
  }
}

/**
 *
 * @param isPopupOpen // boolean to check if the popup is open or not
 * @param scrollPreventEl // element that needs to be prevented from scrolling
 * @description This function is used to handle the scroll behavior of the table when the popup is open
 *
 * [Note] This function is only for one specific use case, i.e.
 * 1. If we have a table within a modal, and the table has element like
 *    (selectInput, datepicker [basically any element that opens a popup])
 *    then we need to handle the scroll behavior of the table
 * 2. When the popup is open, the table should not be scrollable
 * 3. When the popup is closed, the table should be scrollable
 *
 */

export const handleScrollTableBehavior = (
  isPopupOpen: boolean,
  scrollPreventEl?: HTMLElement | undefined | null
) => {
  if (!scrollPreventEl) return
  if (isPopupOpen) {
    scrollPreventEl.style.overflow = "hidden"
  } else {
    scrollPreventEl.style.overflow = "auto"
  }
}

export const checkForWidth = (val?: boolean) => {
  if (val) {
    if (typeof window !== undefined) {
      const windowSize = window.innerWidth
      return windowSize <= 768
    }
    return false
  }
  return false
}

export const handleScrollModalBehavior = (val?: boolean, size?: number) => {
  if (typeof window !== undefined) {
    const windowSize = size || window.innerWidth
    const mBody = document.getElementsByClassName("ant-modal-body")[0]
    if (windowSize <= 768) {
      if (val) {
        if (mBody) {
          //@ts-ignore
          mBody.style.overflowY = "hidden"
        }
      } else {
        if (mBody) {
          //@ts-ignore
          mBody.style.overflowY = "auto"
        }
      }
    } else {
      if (mBody) {
        //@ts-ignore
        mBody.style.overflowY = "auto"
      }
    }
  }
}

/**
 * @returns HTMLElement
 * @description This function returns the element that is used as the popup container for the select input
 * When passed as the popupContainer prop to the select input, it will render select input's popup within the element
 *
 * This is used to fix the issue of the select input's popup being scrolled along with the modal and table
 *
 * Specifically used in the case of select input within a modal and table [http://localhost:3000/instructors/edit/133: modal]
 */
export const anchorSelInputAreaAsPopupContainer = () => {
  return document.getElementById("select_input_area")
}
