import { flattenObjectValues } from "./utilityFunction"

export const createRequestBodyForMonthlyActivities = (dates) => {
  // if dates have program category id, then its valid.
  let requestBody = {}
  Object.keys(dates || {}).forEach((date) => {
    const activites = []
    dates[date].forEach((activity) => {
      if (activity?.program_id || activity?.program_text) {
        activites.push({
          ...activity,
        })
      }
    })
    if (activites.length) {
      requestBody = {
        ...requestBody,
        [date]: activites,
      }
    }
  })
  return requestBody
}

export const getProgramFacilities = (data) => {
  return new Set(flattenObjectValues(data)?.map((data) => data.facility_id))
}
