import { API, removeBlankAttributes } from "@project/shared"
import { t } from "i18next"

interface IFacilitiesWithCountParams {
  year?: string
  month?: string
}

export const saveFacility = async (values: any) => {
  return API.post("/facilities/create", values)
}

export const getAllFacilityInformationHistory = async () => {
  return API.get("/facilities/information-history?status=ALL")
}

export const getOneFacility = async (id) => {
  return API.get(`/facilities/${id}`)
}

export const getOneFacilityAdmins = async (id, queries = "") => {
  return API.get(`/facilities/${id}/admins?${queries}`)
}

export const updateFacility = async (id, values: any) => {
  return API.put(`/facilities/${id}`, values)
}

export const deleteFacility = (id: string | number) =>
  API.delete(`/facilities/${id}`)

export const getAllFacilitiesWithChildCount = async (
  params?: IFacilitiesWithCountParams
) => {
  try {
    const nonBlankParams = removeBlankAttributes(params)
    const queryParams: any = {}
    for (const keys in nonBlankParams) {
      queryParams[keys] = nonBlankParams[keys]?.toString()
    }
    const response = await API.get("/facilities_with_child_count", {
      params: queryParams,
    })

    return [
      ...(response?.data?.facilities || []),
      {
        id: 0,
        facility_name: t("No contract facility"),
        facility_name_short: t("No contract facility"),
        total: response?.data?.none_contracted_child_count || 0,
      },
    ]
  } catch (error) {
    return []
  }
}
