import React, { FC } from "react"
import { Button, Pagination as AntPagination, PaginationProps } from "antd"
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons"
import styled from "styled-components"
import { theme } from "../../../theme"

interface IPaginationProps extends PaginationProps {
  prevText?: string
  nextText?: string
  currentYear?: number
  removeMt?: boolean
}

const Wrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => (props?.removeMt ? "0 0" : "15px 0px")};

  @media (max-width: 500px) {
    overflow-x: scroll;
  }

  overflow-y: hidden;

  p {
    color: #191919;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    margin: auto;
  }
  .ant-pagination {
    display: flex;
    border-radius: 10px;
    li {
      margin: 0px;
      min-width: 40px;
      height: 40px;
      border-radius: 0px;
      button {
        min-width: 40px;
        height: 40px;
      }
      a {
        padding-top: 2px;
        height: 40px;
        color: black !important;
      }
      &.ant-pagination-item-active {
        background: #cde6f4;
        border: 1px solid #d2d1d1;
        a {
          color: black !important;
        }
      }
      :last-child {
        button {
          border-radius: 0px 10px 10px 0px !important;
        }
      }
      :first-child {
        button {
          border-radius: 10px 0px 0px 10px !important;
        }
      }
    }
  }
  @media screen and (max-width: ${theme.breakpoints.md}) {
    margin: ${(props) => (props?.removeMt ? "0 0" : "10px 0px")};
  }
  @media print {
    display: none;
  }

  .wrapper {
    margin-left: auto;
  }
`

const itemRender: PaginationProps["itemRender"] = (
  _,
  type,
  originalElement
) => {
  if (type === "prev") {
    return (
      <Button>
        <DoubleLeftOutlined />
      </Button>
    )
  }
  if (type === "next") {
    return (
      <Button>
        <DoubleRightOutlined />
      </Button>
    )
  }
  return originalElement
}

const Pagination: FC<IPaginationProps> = ({
  prevText,
  nextText,
  currentYear,
  ...rest
}) => {
  return (
    <Wrapper {...rest} className={"pagination"}>
      <div className={"wrapper"}>
        <AntPagination
          showQuickJumper={false}
          showSizeChanger={false}
          itemRender={(_, type, originalElement) => {
            let customElement: any
            let customType: any
            if (type === "prev" && prevText) {
              customElement = <Button>{prevText}</Button>
              customType = "customPrev"
            } else if (type === "next" && nextText) {
              customElement = (
                <Button onClick={() => currentYear + 1}>{nextText}</Button>
              )
              customType = "customNext"
            } else {
              customElement = originalElement
              customType = type
            }
            return itemRender(_, customType, customElement)
          }}
          {...rest}
        />
      </div>
    </Wrapper>
  )
}

export { Pagination }
