import React from "react"
import styled from "styled-components"
import { Badge } from "antd"

export type BadgeGroupShape = {
  text: string
  count: number
}

interface BadgeGroupProps {
  items: BadgeGroupShape[]
  background?: string
  color?: string
  countColor?: string
}

interface StyleBadgeProps {
  background?: string
  color?: string
  countColor?: string
}

const Wrapper = styled.div<StyleBadgeProps>`
  display: flex;
  flex-wrap: wrap;
  .ant-badge {
    display: flex;
    align-items: center;
    margin-right: 30px;
    .ant-badge-count {
      background-color: ${({ background }) => background || "#fcede5"};
      color: ${({ color }) => color || "#191919"};
      font-size: 16px;
      font-weight: 500;
      padding: 4px 14px 6px;
      height: auto;
      border-radius: 100px;
    }
    .ant-badge-status-text {
      color: ${({ countColor }) => countColor || "#e26827"};
      font-size: 22px;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      span {
        color: #191919;
        font-size: 16px;
        font-weight: 400;
        margin-left: 3px;
      }
      @media (max-width: 1526px) {
        height: 2.5rem;
      }
      @media (max-width: 1200px) {
        height: 0;
      }
    }
  }
`
const BadgeGroup: React.FC<BadgeGroupProps> = ({ items }) => (
  <Wrapper>
    {items.map((item, index) => (
      <Badge
        key={index}
        count={item.text}
        text={
          <>
            {item.count}
            <span>{"名"}</span>
          </>
        }
      />
    ))}
  </Wrapper>
)

export { BadgeGroup }
