import { t } from "i18next"

export const dynamicLangString = (string: string[] | string | undefined) => {
  const currentLang = localStorage.getItem("i18nextLng")

  if (typeof string === "string") {
    return t(string)
  }

  if (typeof string === "undefined") {
    return ""
  }

  if (typeof string === "object") {
    if (currentLang === "en") {
      // is current language is english then
      // join the string with space
      return string.map((str) => t(str)).join(" ")
    }

    // else join the string with no space
    return string.map((str) => t(str)).join("")
  }
}
