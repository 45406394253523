import { CameraOutlined, CloseCircleFilled } from "@ant-design/icons"
import { Button, Spin, Upload, message, notification } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "react-query"
import styled from "styled-components"
import { fileUpload } from "../../../../services"
import { blobToFile } from "../ImageUpload/ImageUploadWithEditFeature"

interface FileUploaderProps {
  onChange?: (fileName?: any, value?: any) => void
  uploadHint?: string
  isLoading?: (value: boolean) => void
  currentImage?: string
  fileName?: string
  setFileName?: React.Dispatch<any>
  setType?: React.Dispatch<any>
  allowAllFileTypes?: boolean
}

const Wrapper = styled.div`
  .file-area {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .ant-btn {
      color: #888787;
      border: 1px solid #d2d1d1;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      width: 145px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      .ant-space-item {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .upload-hint {
      font-size: 14px;
      font-weight: 400;
      color: #191919;
      word-break: break-all;
    }
  }
`
const DisplayFile = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 20px;
  }
`

const FileUploader: React.FC<FileUploaderProps> = ({
  onChange,
  isLoading,
  currentImage,
  setFileName,
  setType,
  allowAllFileTypes = false,
}) => {
  const [file, setFile] = useState(null)
  const [originalFileName, setOriginalFileName] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (currentImage != "") {
      setFile(currentImage)
    }
    setFileName(originalFileName)
  }, [currentImage])

  const { mutate, isLoading: uploadLoading } = useMutation(
    "fileUpload",
    fileUpload,
    {
      onSuccess: (result) => {
        isLoading(false)
        onChange && onChange(result?.data, result?.data)
        setFile(result?.data)
      },
      onError: (error?: any) => {
        isLoading(false)
        setFile(null)
        setFileName(null)
        const msg = error?.data?.error?.message
        notification.error({
          message: msg ? t(`${msg}`) : t("An error has occurred"),
        })
      },
    }
  )

  const handleDeleteClick = () => {
    setFile(null)
    setOriginalFileName(null)
    onChange && onChange(null, null)
    setType("text")
  }

  const beforeUpload = (file: any) => {
    const isPdf = file.type == "application/pdf"
    const isExcel =
      file.type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.ms-excel"
    const isDoc =
      file.type ==
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"

    const isLt5M = +(file.size / 1000000).toFixed(2) < 5

    if (!isLt5M) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC/PDF/WORD/EXCEL image with less than 5MB"
        ),
        key: "37",
        icon: <CloseCircleFilled onClick={() => message.destroy("37")} />,
      })
      setFileName(null)
      setFile(null)
      return false
    }

    if (isPdf) {
      setType("file")
      return isPdf && isLt5M
    }

    if (isExcel) {
      setType("file")
      return isExcel && isLt5M
    }

    if (isDoc) {
      setType("file")
      return isDoc && isLt5M
    }

    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/gif" ||
      file.type === "image/heic"
    if (isJpgOrPng) {
      setType("image")
      return isJpgOrPng && isLt5M
    }
    if (!allowAllFileTypes && !isJpgOrPng && !isDoc && !isExcel && !isPdf) {
      message.error({
        content: t(
          "You can only upload JPG/PNG/GIF/HEIC/PDF, Word or Excel file!"
        ),
        key: "38",
        icon: <CloseCircleFilled onClick={() => message.destroy("38")} />,
      })
    }

    return isJpgOrPng && isLt5M
  }

  const handleChange = async (info) => {
    if (info.file.status === "done") {
      isLoading(true)
      const bodyFormData = new FormData()

      if (info.file.type === "image/heic") {
        const heic2any = (await import("heic2any")).default
        const blobHeicFile = new Blob([info.file.originFileObj])
        const convertedFile = (await heic2any({
          blob: blobHeicFile,
          toType: "image/jpeg",
          quality: 1,
        })) as Blob
        bodyFormData.append("file", blobToFile(convertedFile, info.file.name))
      } else {
        bodyFormData.append("file", info?.file?.originFileObj)
      }
      bodyFormData.append("model", "temp")
      mutate(bodyFormData)
      setOriginalFileName(info?.file?.originFileObj?.name)
    }
  }

  return (
    <Wrapper>
      {!file && (
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          accept={"*"}
          customRequest={({ onSuccess }) => {
            onSuccess("ok")
          }}
        >
          <div className={"file-area"}>
            <Button
              icon={uploadLoading ? <Spin /> : <CameraOutlined />}
              style={{
                backgroundColor: "rgba(7, 130, 200, 0.2)",
                border: "1px solid #0782C8",
                borderRadius: "100px",
                color: "#000000",
                height: "40px",
                margin: "0px 4px",
              }}
            >
              <span style={{ margin: "0px 4px" }}> {t("Add file")}</span>
            </Button>
          </div>
        </Upload>
      )}
      {file && (
        <DisplayFile>
          <div className={"file-name"}>{originalFileName}</div>
          <div
            className={"delete-btn"}
            onClick={handleDeleteClick}
            style={{
              textDecoration: "underline",
              color: "red",
              cursor: "pointer",
            }}
          >
            {t("Delete")}
          </div>
        </DisplayFile>
      )}
    </Wrapper>
  )
}

export { FileUploader }
