import { notification } from "antd"

const PERMISSION_KEYS = {
  "Child Info": "automatic_info_facilty_permissions",
  "Facility operation": "facility_operation_permissions",
  "Contents manage": "facility_content_management_permissions",
  "Form management": "facility_form_management_permissions",
  "Billing management": "facility_billing_management_permissions",
  "System settings": "facility_system_setting_permissions",
  "My page settings": "my_page_permissions",
  "Common info": "company_info_permissions",
  "Invoice management": "invoice_permissions",
  "Master management": "master_management_permissions",
  "My page setting": "facility_my_page_settings_permissions",
  "Contents management": "content_management_permissions",
  "Child care": "child_care",
  "Parents Management": "parents_management",
  "Recurring Schedule": "recurring_schedule",
  "Attendance schedule": "attendance_schedule",
  "Instructor master": "instructor_master",
  "Shift master": "shift_master",
  "Program master": "program_master",
  "Service provision content master": "service_provision_content_master",
  "Actual cost items master": "actual_cost_items_master",
  "Provision city master": "provision_city_master",
  "Child Care": "child_care",
  "Recipient's certificate/contract supply amount": "recepient_certificate",
  "Service provision record": "service_provision_record",
  "Individual support plan": "individual_support_plan",
  "Activity management": "activity_management",
  "Attendance record table": "attendance_record_table",
  "Business hour management": "business_hour_management",
  "Facility information/history": "facility_information_history",
  "Regular holidays management": "regular_holidays_management",
  "System status management": "system_status_management",
  "Facility application management": "facility_application_management",
  "Transportation management": "transportation_management",
  "Daily Business Report": "daily_business_report",
  "Actual cost burden schedule": "actual_cost_burden_schedule",
  "Service provision record sheet": "service_provision_form",
  "contract report": "contract_report_form",
  "Notification form regarding remuneration calculation categories":
    "remuneration_notification_form",
  "List of self-evaluation charts for parents": "self_eval_form",
  "Monthly use status table (support for children with disabilities)":
    "monthly_status_form",
  "Treatment improvement addition amount result table":
    "treatment_improvement_form",
  "Various additions and minutes management": "addition_minutes_form",
  "Activity record": "activity_record",
  "Revenue management": "revenue_management",
  "Additional item management(facility)": "additional_facility_management",
  "Addition item management(children)": "additional_children_item_management",
  "Actual cost burden record table": "actual_cost_burden_table",
  "Upper limit management result information management":
    "cap_intelligence_management",
  "Municipal sub-component billing amount information management":
    "municipal_billing_info_management",
  "国保連請求 帳票・CSV出力": "bill_csv_management",
  "System mail settings": "system_mail_settings",
  "Notes from parents": "notes_from_parents",
  "Attendance management": "attendance_management",
  "Daily Business Report ": "daily_business_report",
  "Child info": "automatic_info_facilty_permissions",
  "Joint facility management": "joint_facility_management",
  "Joint facility": "joint_facility_master",
  "Facility tour application management": "facility_tour_management",
  "Facility application setting": "facility_application_setting",
  "My page display setting": "my_display_setting",
  "Shuttle car master": "shuttle_car_master",
  "Day nursery/Schools": "day_nursery_schools_master",
  "Consultation support office": "consultation_support_office_master",
  "Progress management master": "progress_management_master",
  "Program progress record": "program_progress_record",
}

export const getLabelFromId = (id) => {
  return Object.keys(PERMISSION_KEYS).find((key) => PERMISSION_KEYS[key] === id)
}

export const createResponseBodyForUserManagement = (permissions, id?) => {
  const response: any = {}
  if (id) {
    response.facility_id = id
  }
  Object.keys(permissions || []).forEach((eachSection) => {
    let _permissions = {}
    permissions[eachSection].forEach((eachPermission) => {
      _permissions = {
        ..._permissions,
        [eachPermission.id]: String(eachPermission.permission),
      }
    })
    response[PERMISSION_KEYS[eachSection]] = _permissions
  })
  return response
}

export const getAffiliationIds = (permission) => {
  const ids = permission?.map((each) => {
    return each.facility_id
  })
  return ids
}

export const getPermissionFromRequest = (request) => {
  const response = {}
  const sections = Object.keys(request)

  sections.forEach((eachSection) => {
    if (
      eachSection !== "facility_id" &&
      eachSection !== "facility_name" &&
      eachSection !== "id" &&
      eachSection !== "facility_name_short"
    ) {
      const eachSectionsPermissionKey = Object.keys(request[eachSection])
      const array = []
      eachSectionsPermissionKey.forEach((permission) => {
        array.push({
          label: getLabelFromId(permission),
          id: permission,
          permission: Number(request[eachSection][permission]),
        })
      })
      response[getLabelFromId(eachSection)] = array
    }
  })
  return response
}

export const getFacilityNameFromId = (allFacilities, facilityId) => {
  let _name = ""
  allFacilities.forEach((facility) => {
    if (facility.id == facilityId) {
      _name = facility.facility_name_short
    }
  })
  return _name
}

export const scrollToID = (id: string) => {
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "start",
  })
}
export const getAffiliationFacilityTabsFromRequest = (request) => {
  return request?.map((facility) => {
    return { label: facility.facility_name_short, value: facility.facility_id }
  })
}

export const catchExceptionCallback = (error: any) => {
  let error1 = error
  if (error?.response && error?.response?.data) {
    if (error?.response?.data?.message) {
      error1 = error.response.data.message
    } else {
      error1 = error.response.data
    }
  } else if (error?.data) {
    error1 = error?.data?.error
  } else if (error?.message) {
    error1 = error?.message
  }
  notification.error({
    message: error1 || "Something went wrong. Please contact administrator",
  })
  return error
}

export const getServiceUseByID = (id?: number) => {
  switch (id) {
    case 1:
      return "After School day service"
    case 2:
      return "Child Development support"
    default:
      return ""
  }
}

export const getDayOfWeek = (day) => {
  switch (day) {
    case 0:
      return "Sun"
    case 1:
      return "Mon"
    case 2:
      return "Tue"
    case 3:
      return "Wed"
    case 4:
      return "Thu"
    case 5:
      return "Fri"
    case 6:
      return "Sat"
    default:
      return ""
  }
}

// Flattens objects whose values are array
export const flattenObjectValues = (obj) => {
  let result = []
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      result = result.concat(obj[key])
    } else if (typeof obj[key] === "object") {
      result = result.concat(flattenObjectValues(obj[key]))
    }
  }
  return result
}

export const removeDuplicates = (array: any) => {
  return (
    array &&
    array
      .map(JSON.stringify)
      .reverse()
      .filter(function (item, index, arr) {
        return arr.indexOf(item, index + 1) === -1
      })
      .reverse()
      .map(JSON.parse)
  ) // revert it to original state
}

export const convertToTwoDigitFormatWithSeperator = (digit = 0) =>
  digit?.toLocaleString(undefined, { maximumFractionDigits: 2 })

export const getDateStringFromDate = (date: Date) => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}`
}

export const getDateTimeStringFromDate = (passedDate: Date) => {
  // for those dates that need to be passed to backend as time.Time
  const date = new Date(passedDate)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return `${year}-${month?.toString()?.padStart(2, "0")}-${day
    ?.toString()
    ?.padStart(2, "0")}T00:00:00Z`
}

export const truncateText = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "..."
  }
  return str
}
