import moment from "moment"

export enum Version {
  BASELINE = 1, // Release Version
  R6_APRIL = 2, // 令和6年度 報酬改定
  R6_JUNE = 3, // 令和6年度 報酬改定 処遇改善加算
  R7_JANUARY = 4, // 令和6年度 報酬改定 処遇改善加算 猶予期間終了
  R7_APRIL = 5, // 令和6年度 報酬改定 支援プログラム未公表減算 適用
}

export type VersionRow = {
  startDate: Date | null
  version: Version
}

const VERSIONS: VersionRow[] = [
  {
    startDate: new Date("2025-04-01T00:00:00+09:00"),
    version: Version.R7_APRIL,
  },
  {
    startDate: new Date("2025-01-01T00:00:00+09:00"),
    version: Version.R7_JANUARY,
  },
  {
    startDate: new Date("2024-06-01T00:00:00+09:00"),
    version: Version.R6_JUNE,
  },
  {
    startDate: new Date("2024-04-01T00:00:00+09:00"),
    version: Version.R6_APRIL,
  },
  { startDate: null, version: Version.BASELINE },
]

// Latest -> Oldest
const sortedLatestVersions = VERSIONS.sort(
  (a, b) => (b.startDate?.getTime() ?? 0) - (a.startDate?.getTime() ?? 0)
)

/**
 * @param {string|Date} date
 * @return {VersionRow}
 */
export function getCurrentVersion(date: string | Date) {
  return sortedLatestVersions.find(
    (r) =>
      r.startDate === null || moment(date).isSameOrAfter(moment(r.startDate))
  )
}

export class UndefinedVersionError extends Error {
  constructor(message = "") {
    super(`The version is undefined. ${message}`)
    this.name = "UndefinedVersionError"
  }
}
