/* eslint-disable @typescript-eslint/no-unused-vars */
export const removeBlankAttributes = (obj) =>
  Object.entries(obj).reduce((a, [k, v]) => {
    if (
      v !== null &&
      v !== "" &&
      v != undefined &&
      !(Array.isArray(v) && v.length === 0)
    ) {
      a[k] = v
    }
    return a
  }, {})
