const typographyH1 = {
  fontWeight: "bold",
  fontSize: "44px",
  lineHeight: "73px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyMap = {
  fontWeight: "bold",
  fontSize: "14px",
  lineHeight: "30px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto,'Noto Sans JP'",
}

const typographyH2 = {
  fontWeight: 900,
  fontSize: 30,
  lineHeight: "38px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyH2EN = {
  fontWeight: 900,
  fontSize: 18,
  lineHeight: "16px",
  letterSpacing: "0.05em",
  fontFamily: "Roboto",
}

const typographyH3 = {
  fontSize: "20px",
  fontWeight: "bold",
  lineHeight: "15px",
  letterSpacing: "0.04em",
  fontFamily: "Roboto",
}

const typographyPrimary = {
  fontSize: 16,
  lineHeight: "30px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}
const typographyPrimaryBold = {
  fontSize: 16,
  lineHeight: "30px",
  fontWeight: "bold",
  letterSpacing: "0.03 em",
  fontFamily: "Roboto",
}

const typographySecondary = {
  fontSize: 14,
  lineHeight: "20px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}

const typographySecondaryBold = {
  fontSize: 14,
  fontWeight: "bold",
  lineHeight: "20px",
  letterSpacing: "0.03em",
  fontFamily: "Roboto",
}

const typographyTertiary = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
}

const typographyTertiaryBold = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "bold",
}
const typographyTag = {
  fontSize: 11,
  lineHeight: "13px",
  fontFamily: "Roboto",
  fontWeight: "normal",
}
const typographyMapTag = {
  fontSize: 12,
  lineHeight: "30px",
  fontFamily: "Roboto,'Noto Sans JP'",
  fontWeight: "bold",
}

const label = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "23.17px",
  fontFamily: "'Noto Sans JP',sans-serif",
}

const typographyMB = {
  fontWeight: 900,
  fontSize: 26,
  lineHeight: "28px",
  letterSpacing: "0.02em",
  fontFamily: "Roboto",
}
const typographyMBH2 = {
  fontWeight: 900,
  fontSize: 22,
  lineHeight: "35px",
  letterSpacing: "0.01em",
  fontFamily: "Roboto",
}

const PackDesc = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "22px",
  fontFamily: "'Noto Sans JP',sans-serif",
}
const breakpoints = {
  xxs: "300px",
  xs: "400px",
  sm: "480px",
  md: "767px",
  xmd: "800px",
  lg: "1024px",
  xlg: "1100px",
  xl: "1200px",
}

export const theme = {
  gray7: "#8C8C8C",
  gray8: "#595959",
  gray9: "#262626",
  gray10: "#444748",
  primary: "#121212",
  borderColorBase: "#d9d9d9",
  placeholder: "#9f9f9f",
  link: "#1890ff",
  signupLink: "#19389C",
  secondaryLight: "#C2C2C2",
  secondaryDark: "#8E8E8E",
  red1: "#CA3030",
  red2: "#E00000",
  red3: "#E26827",
  red4: "#E8450B",
  red5: "#F8E6E6",
  red6: "#f5b492",
  yellow1: "#F7CD48",
  yellow2: "#EAB82D",
  yellow3: "#FDD95F",
  yellow4: "#FFE695",
  yellowLight: "#feecaf",
  blue1: "#19389C",
  blue2: "#1751D5",
  blue4: "#2B478B",
  blue5: "#0782C8",
  childPrimaryBlue: "#00B6C2",
  disabledBlue: "#0782C866",
  blue6: "#CDE6F4",
  blue7: "#4DA2F8",
  accentMid: "#F5FAFF",
  base: "#FFFFFF",
  darkGrey: "#E7E9ED",
  black: "#191919",
  black2: "rgba(0, 0, 0, 0.85)",
  black3: "rgba(0, 0, 0, 1)",
  primaryLine: "#DCDCDC",
  face1: "#FFB27D",
  face2: "#E2915A",
  gray: "#F8F8F8",
  gray2: "#D2D1D1",
  gray3: "#858686",
  gray4: "#F6F7F8",
  gray5: "#888787",
  gray6: "#b1abab",
  alert: "#EB5757",
  lightBlue: "#B9CBF2",
  lightBlue2: "#cde6f4",

  bgColor: "#f3f4f6",
  bgColor2: "#F3F3F3",
  green1: "#4CB476",
  orange1: "rgba(239, 130, 55, 1)",
  bgOrange: "#FF8200",
  orangeLight: "#FCE9CE",
  blueLight: "#C7E9EB",
  typography: {
    typographyH1,
    typographyH2,
    typographyH2EN,
    typographyH3,
    typographyPrimary,
    typographyPrimaryBold,
    typographySecondary,
    typographySecondaryBold,
    typographyTertiary,
    typographyTertiaryBold,
    typographyMB,
    typographyMBH2,
    typographyTag,
    typographyMapTag,
    typographyMap,
    PackDesc,
    label,
  },
  breakpoints,
  rgba: {
    blue: "rgba(7, 130, 200, 0.2)",
    gray: "rgba(0, 0, 0, 0.04)",
    reddish: `rgba(224, 0, 0, 0.1)`,
  },
  secondaryBg: "#F8F5F2",
  green2: "#444748",
  orange: "#EF8237",
  skyblue: "#DCF4FF",
  pink: "#fcede5",
  lightPink: "rgba(202, 48, 48, 0.12)",
  maroon: "#8c0e2b",
  darkBlue: "#0E4B8C",
  midBlue: "#1876DA",
  green3: "#3CAD6E",
  orange2: "#ef8236",
  lightorange: "#fff4ee",
  ligtblue: "#edfafe",
  orange3: "#CA4C30",
  orange4: "#E26828",
}

export const ChildServiceColors = {
  afterSchool: "#e4a40d",
  childDevelopmentSupport: "#4da2f8",
}
