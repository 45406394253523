import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const Container = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 20px;
  flex: 1;
  .top-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      margin: 17px 0;
      font-size: 18px;
      font-weight: 500;
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 133px;
      width: 133px;
      background: white;
      border-radius: 50%;
    }
  }
`
export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d2d1d1;
  background: #ffffff;
  padding: 1rem 2rem;
  border-radius: 16px;
  height: auto;
`
export const TextFieldWrapper = styled.div`
  margin-top: 35px;
  .forget-password-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 27px;
    p {
      margin: auto 0px;
      font-size: 14px;
    }
    a {
      text-decoration: underline;
      margin-left: 5px;
      font-size: 14px;
      color: #0782c8;
    }
  }
  .login-button-wrapper {
    button {
      width: 100%;
      background-color: #0782c8;
      border: none;
      padding: 10px 20px 10px 20px;
      border-radius: 8px;
      color: #ffffff;
      font-size: 18px;
    }
  }
  .password-info-text {
    font-size: 10px;
    color: #191919;
  }
  .text-field {
    label {
      font-weight: 100 !important;
      font-size: 14px !important;
      color: #191919 !important;
    }
    input {
      ::-ms-input-placeholder {
        color: #191919 !important;
        font-size: 18px !important;
      }
      ::placeholder {
        color: #191919 !important;
        font-size: 18px !important;
      }
    }
  }
`
export const InputFieldWrapper = styled.div`
  margin-bottom: 25px;
`
