export const InstructorMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "instructor_master",
]

export const ShiftMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "shift_master",
]

export const ProgramMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "program_master",
]

export const ServiceProvisionMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "service_provision_content_master",
]

export const ActualCostMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "actual_cost_items_master",
]

export const ProvisionCityMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "provision_city_master",
]

export const NurserySchoolMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "day_nursery_schools_master",
]

export const JointFacilityMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "joint_facility_master",
]

export const ConsultationSupportMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "consultation_support_office_master",
]

export const ShuttleCarMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "shuttle_car_master",
]

export const ProgressManagementMasterPermission: Array<string> = [
  "common_facility_permissions",
  "master_management_permissions",
  "progress_management_master",
]

export const NewRegistrationPermission: Array<string> = ["auth_new_delete_flag"]

export const ChildCarePermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "child_care",
]

export const ChildAttendanceManagementPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "attendance_management",
]

export const IndividualSupportPlanPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "individual_support_plan",
]

export const RecipientCertificatePermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "recepient_certificate",
]

export const ServiceProvisionRecordPermission: Array<string> = [
  "user_facility_permissions",
  "automatic_info_facilty_permissions",
  "service_provision_record",
]

export const MonthActivitiesPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "activity_management",
]

export const FacilityInformationPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "facility_information_history",
]

export const StaffAttendanceRecordPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "attendance_record_table",
]

export const BusinessListPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "business_hour_management",
]

export const DailyBusinessReportPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "daily_business_report",
]

export const FacilityAppManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "facility_application_management",
]

export const ActualCostSchedulePermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "actual_cost_burden_schedule",
]
export const AttendanceSchedulePermission: string[] = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "attendance_schedule",
]

export const TransportationManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "transportation_management",
]

export const ProgramRecordPermission: Array<string> = [
  "user_facility_permissions",
  "facility_content_management_permissions",
  "activity_record",
]

export const ServiceProvisionRecordSheetPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "service_provision_form",
]

export const MinutesAdditionPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "addition_minutes_form",
]

export const ContractReportPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "contract_report_form",
]

export const SelfEvalFormPermission: Array<string> = [
  "user_facility_permissions",
  "facility_form_management_permissions",
  "self_eval_form",
]

export const AdditionalItemChildPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "additional_children_item_management",
]

export const MaximumAmountManagementResultInformationManagementPermission: Array<string> =
  [
    "user_facility_permissions",
    "facility_billing_management_permissions",
    "cap_intelligence_management",
  ]

export const AdditionalItemFacilityPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "additional_facility_management",
]

export const ActualCostBurdenPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "actual_cost_burden_table",
]

export const NotesFromParentsPermission: Array<string> = [
  "user_facility_permissions",
  "facility_my_page_settings_permissions",
  "notes_from_parents",
]

export const ParentManagementPermission: Array<string> = [
  "common_facility_permissions",
  "company_info_permissions",
  "parents_management",
]

export const MunicipalSubsidiaryPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "municipal_billing_info_management",
]

export const JointFacilityStatusManagementPermission: Array<string> = [
  "common_facility_permissions",
  "invoice_permissions",
  "joint_facility_management",
]

export const MyPagePermission: Array<string> = [
  "common_facility_permissions",
  "my_page_permissions",
  "my_display_setting",
]

export const FacilityApplicationSettingPermission: Array<string> = [
  "common_facility_permissions",
  "my_page_permissions",
  "facility_application_setting",
]

export const FacilityTourAppManagementPermission: Array<string> = [
  "common_facility_permissions",
  "content_management_permissions",
  "facility_tour_management",
]

export const RevenueManagementPermission: Array<string> = [
  "user_facility_permissions",
  "facility_billing_management_permissions",
  "revenue_management",
]

export const RecurringSchedulePermission: Array<string> = [
  "common_facility_permissions",
  "company_info_permissions",
  "recurring_schedule",
]

export const ProgramProgressRecordPermission: Array<string> = [
  "user_facility_permissions",
  "facility_operation_permissions",
  "program_progress_record",
]
