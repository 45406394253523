import { t } from "i18next"
import moment from "moment"
import { theme } from "../theme"
import { Option } from "../utils/typesAndInterface"
export const BUTTON_DISPLAY_LIST = [
  {
    label: t("Service Provision Record"),
    value: "service_provision_record",
  },
  { label: t("Activity recode"), value: "activity_record" },
  {
    label: t("Individual support planning"),
    value: "individual_support_plan_list",
  },
  {
    label: t("Individual support planning(Draft)"),
    value: "individual_support_plan_draft_list",
  },
  { label: t(" Monitoring"), value: "monitoring_list" },
  {
    label: t("Service provision record sheet"),
    value: "service_provision_record_sheet",
  },
  { label: t("Contact facility "), value: "contact_facility" },
  { label: t("Mail setting"), value: "mail_setting" },
  { label: t("Password change"), value: "password_change" },
]

export const BUTTON_DISPLAY_LIST1 = [
  {
    label: t("Consent Date"),
    value: "consent_date",
  },
  { label: t("Parent's sign field"), value: "parent_sign" },
  {
    label: t("Agreement text"),
    value: "agreement_text",
  },
  { label: t("Child sign field"), value: "child_sign" },
  {
    label: t("PIC of child development support management consent"),
    value: "pic_child_development_support_management_consent",
  },
  { label: t("Parents seal"), value: "parent_seal" },
]

export const BUTTON_DISPLAY_LIST2 = [
  {
    label: t("Consent Date"),
    value: "consent_date",
  },
  { label: t("Parent's sign field"), value: "parent_sign" },
  {
    label: t("Agreement text"),
    value: "agreement_text",
  },
  { label: t("Child sign field"), value: "child_sign" },
  {
    label: t("PIC of child development support management consent"),
    value: "pic_child_development_support_management_consent",
  },
  { label: t("Parents seal"), value: "parent_seal" },
]

export const BUTTON_DISPLAY_LIST3 = [
  {
    label: t("Consent Date"),
    value: "consent_date",
  },
  { label: t("Parent's sign field"), value: "parent_sign" },
  {
    label: t("Consent description"),
    value: "consent_description",
  },
  { label: t("Child sign field"), value: "child_sign" },
  { label: t("Parents seal"), value: "parent_seal" },
]

export const DO_DONOT_LIST = [
  {
    label: t("Do not"),
    value: "0",
  },
  { label: t("Do"), value: "1" },
]

export const PROVISION_RECORD_LIST = [
  {
    label: t("Facility"),
    value: "1",
  },
  { label: t("山武"), value: "2" },
  {
    label: t("東松山"),
    value: "3",
  },
  {
    label: t("(放) 宮子"),
    value: "4",
  },
  { label: t("(放) 小泉"), value: "5" },
  { label: t("本庄"), value: "6" },
  { label: t("吉岡"), value: "7" },
  { label: t("熊谷"), value: "8" },
  { label: t("太田"), value: "9" },
  { label: t("宮子"), value: "10" },
  { label: t("小泉"), value: "11" },
]

export const FOOTER_INFORMATION_LIST = [
  {
    label: t("Company information"),
    value: "company-info-1",
  },
  { label: t("つくば"), value: "company-info-2" },
  {
    label: t("山武"),
    value: "company-info-3",
  },
  {
    label: t("東松山"),
    value: "company-info-4",
  },
  { label: t("(放) 宮子"), value: "company-info-5" },
  { label: t("(放) 小泉"), value: "company-info-6" },
  { label: t("本庄"), value: "company-info-16" },
  { label: t("熊谷"), value: "company-info-7" },
  { label: t("太田"), value: "company-info-8" },
  { label: t("宮子"), value: "company-info-9" },
  { label: t("小泉"), value: "company-info-10" },
  { label: t("吉岡"), value: "company-info-11" },
]

export const USING_LIST = [
  {
    label: t("Use"),
    value: 1,
  },
  { label: t("Don't use"), value: 0 },
]

export const YES_NO_LIST = [
  {
    label: t("Yes"),
    value: "1",
  },
  { label: t("No"), value: "0" },
]

export const HEADER_INFORMATION_LIST = [
  {
    label: t("Don't display"),
    value: 0,
  },
  { label: t("Display text"), value: 1 },
  { label: t("Company information"), value: 2 },
  { label: t("Facility"), value: 3 },
  { label: t("山武"), value: 4 },
  { label: t("東松山"), value: 5 },
  { label: t("(放) 宮子"), value: 6 },
  { label: t("小泉"), value: 7 },
  { label: t("本庄"), value: 8 },
  { label: t("吉岡"), value: 9 },
  { label: t("熊谷"), value: 10 },
  { label: t("太田"), value: 11 },
  { label: t("宮子"), value: 12 },
]
export const SHUTTLE_CAR_AFFICATED_FACILITY = [
  {
    label: t("Facility"),
    value: 420,
  },
  { label: t("山武"), value: 100 },
  {
    label: t("東松山"),
    value: 60,
  },
  {
    label: t("(放) 宮子"),
    value: 20,
  },
]
export const BUSINESS_REGISTRATION_FACILITY = [
  {
    label: t("Child development support"),
    value: "facility-1",
  },
  {
    label: t("Day service"),
    value: "facility-2",
  },
]
export const BUSINESS_REGISTRATION_SUBJECTED_OCCUPATION = [
  {
    label: t("Administrator"),
    key: "administrator",
    value: "1",
  },
  {
    label: t("Child Support Development Manager"),
    key: "child_support_development_manager",
    value: "1",
  },
  {
    label: t("Child Instructor"),
    key: "child_instructor",
    value: "1",
  },
  {
    label: t("Child Instructor High Experience"),
    key: "child_instructor_high_exp",
    value: "1",
  },
  {
    label: t("Child Minder"),
    key: "child_minder",
    value: "1",
  },
  {
    label: t("Nursery Teacher High Experience"),
    key: "nursery_teacher_high_exp",
    value: "1",
  },
  {
    label: t("Disabled Welfare Service Experience"),
    key: "disabled_welfare_service_experience",
    value: "1",
  },
  {
    label: t("Instructor Other"),
    key: "instructor_other",
    value: "1",
  },
  {
    label: t("Physical Therapist"),
    key: "physical_therapist",
    value: "1",
  },
  {
    label: t("Occupational Therapist"),
    key: "occupational_therapist",
    value: "1",
  },
  {
    label: t("Speech Therapist"),
    key: "speech_therapist",
    value: "1",
  },
  {
    label: t("Psychological Guidance Staff"),
    key: "psychological_guidance_staff",
    value: "1",
  },
  {
    label: t("Nursing Staff"),
    key: "nursing_staff",
    value: "1",
  },
  {
    label: t("Visit Support Member"),
    key: "visit_support_member",
    value: "1",
  },
  {
    label: t("Licensed Psychologist"),
    key: "licensed_psychologist",
    value: "1",
  },
  {
    label: t("Clinical Psychologist"),
    key: "clinical_psychologist",
    value: "1",
  },
  {
    label: t("Judo Therapisterapist"),
    key: "judo_therapist",
    value: "1",
  },
  {
    label: t("Acupuncturist"),
    key: "acupuncturist",
    value: "1",
  },
  {
    label: t("Anma Massage Shiastsu Therapist"),
    key: "anma_massage_shiastsu_therapist",
    value: "1",
  },
  {
    label: t("Contract Doctor"),
    key: "contract_doctor",
    value: "1",
  },
  {
    label: t("Nutritionist"),
    key: "nutritionist",
    value: "1",
  },
  {
    label: t("Cook"),
    key: "cook",
    value: "1",
  },
  {
    label: t("Other"),
    key: "other",
    value: "1",
  },
]
export const WEEK_DAYS = [
  {
    label: t("Sun"),
    value: "6",
  },
  {
    label: t("Mon"),
    value: "0",
  },
  {
    label: t("Tue"),
    value: "1",
  },
  {
    label: t("Wed"),
    value: "2",
  },
  {
    label: t("Thu"),
    value: "3",
  },
  {
    label: t("Fri"),
    value: "4",
  },
  {
    label: t("Sat"),
    value: "5",
  },
]

export const DIVISION = [
  {
    label: t("School"),
    value: 1,
    name: "division_school",
  },
  { label: t("Nursery school"), value: 1, name: "division_nursery" },
  { label: t("Day nursery"), value: 1, name: "division_day_nursery" },
  {
    label: t("Kindergarten"),
    value: 1,
    name: "division_kindergarden",
  },
  {
    label: t("Elementary school"),
    value: 1,
    name: "division_elementary",
  },
  { label: t("Junior high school"), value: 1, name: "division_junior_nursery" },
  { label: t("High school"), value: 1, name: "division_high_school" },
]

export const getBusinessRegistrationYears = () => {
  const current_year = new Date().getFullYear()
  const year_options: { label: string; value: number }[] = []
  for (let i = 2015; i < current_year + 4; i++) {
    year_options.push({ label: "" + i, value: i })
  }
  return year_options
}
export const getBusinessRegistrationMonths = () => {
  const month_options: { label: string; value: number }[] = []
  for (let i = 1; i <= 12; i++) {
    month_options.push({ label: "" + i, value: i })
  }
  return month_options
}

export const BUSINESS_REGISTRATION_BULK_SERVICE = [
  { label: t("中学校"), value: "junior high school" },
]
export const GENDERS = ["", "Male", "Female"]
export const CALANDER_HOURS_MINUTES: { hours: Option[]; minutes: Option[] } = {
  hours: [
    {
      value: "",
      label: "--",
    },
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
  ],
  minutes: [
    {
      value: "",
      label: "--",
    },
    {
      value: "00",
      label: "00",
    },
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "11",
    },
    {
      value: "12",
      label: "12",
    },
    {
      value: "13",
      label: "13",
    },
    {
      value: "14",
      label: "14",
    },
    {
      value: "15",
      label: "15",
    },
    {
      value: "16",
      label: "16",
    },
    {
      value: "17",
      label: "17",
    },
    {
      value: "18",
      label: "18",
    },
    {
      value: "19",
      label: "19",
    },
    {
      value: "20",
      label: "20",
    },
    {
      value: "21",
      label: "21",
    },
    {
      value: "22",
      label: "22",
    },
    {
      value: "23",
      label: "23",
    },
    {
      value: "24",
      label: "24",
    },
    {
      value: "25",
      label: "25",
    },
    {
      value: "26",
      label: "26",
    },
    {
      value: "27",
      label: "27",
    },
    {
      value: "28",
      label: "28",
    },
    {
      value: "29",
      label: "29",
    },
    {
      value: "30",
      label: "30",
    },
    {
      value: "31",
      label: "31",
    },
    {
      value: "32",
      label: "32",
    },
    {
      value: "33",
      label: "33",
    },
    {
      value: "34",
      label: "34",
    },
    {
      value: "35",
      label: "35",
    },
    {
      value: "36",
      label: "36",
    },
    {
      value: "37",
      label: "37",
    },
    {
      value: "38",
      label: "38",
    },
    {
      value: "39",
      label: "39",
    },
    {
      value: "40",
      label: "40",
    },
    {
      value: "41",
      label: "41",
    },
    {
      value: "42",
      label: "42",
    },
    {
      value: "43",
      label: "43",
    },
    {
      value: "44",
      label: "44",
    },
    {
      value: "45",
      label: "45",
    },
    {
      value: "46",
      label: "46",
    },
    {
      value: "47",
      label: "47",
    },
    {
      value: "48",
      label: "48",
    },
    {
      value: "49",
      label: "49",
    },
    {
      value: "50",
      label: "50",
    },
    {
      value: "51",
      label: "51",
    },
    {
      value: "52",
      label: "52",
    },
    {
      value: "53",
      label: "53",
    },
    {
      value: "54",
      label: "54",
    },
    {
      value: "55",
      label: "55",
    },
    {
      value: "56",
      label: "56",
    },
    {
      value: "57",
      label: "57",
    },
    {
      value: "58",
      label: "58",
    },
    {
      value: "59",
      label: "59",
    },
  ],
}
export const INSTRUCTOR_AFFILIATED_ACILITY = [
  {
    label: t("Facility"),
    value: "facility-0",
  },
  {
    label: t("山武"),
    value: "facility-1",
  },
  {
    label: t("東松山"),
    value: "facility-2",
  },
  {
    label: t("(放) 宮子"),
    value: "facility-3",
  },
  {
    label: t("(放) 小泉"),
    value: "facility-4",
  },
  {
    label: t("本庄"),
    value: "facility-5",
  },
  {
    label: t("吉岡"),
    value: "facility-6",
  },
  {
    label: t("熊谷"),
    value: "facility-7",
  },
  {
    label: t("太田"),
    value: "facility-8",
  },
  {
    label: t("宮子"),
    value: "facility-9",
  },
  {
    label: t("小泉"),
    value: "facility-10",
  },
]
export const INSTRUCTOR_OCCUPATIONS = [
  {
    label: t("管理者"),
    value: "facility-11",
  },
  {
    label: t("児童発達支援管理責任者"),
    value: "facility-1",
  },
  {
    label: t("児童指導員"),
    value: "facility-4",
  },
  {
    label: t("児童指導員(児童福祉事業5年以上経験)"),
    value: "facility-4",
  },
  {
    label: t("保育士"),
    value: "facility-4",
  },
  {
    label: t("保育士(児童福祉事業5年以上経験)"),
    value: "facility-4",
  },
  {
    label: t("理学療法士"),
    value: "facility-4",
  },
  {
    label: t("作業療法士"),
    value: "facility-4",
  },
  {
    label: t("言語聴覚士 "),
    value: "facility-4",
  },
  {
    label: t("心理指導担当職員等   "),
    value: "facility-4",
  },
  {
    label: t("看護職員 "),
    value: "facility-4",
  },
  {
    label: t("その他"),
    value: "facility-4",
  },
]

export const NUMBER_OF_STAFF = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
]

export const GRADES = [
  {
    label: "一級地",
    value: "1",
    grade_value: 11.2,
  },
  {
    label: "二級地",
    value: "2",
    grade_value: 10.96,
  },
  {
    label: "三級地",
    value: "3",
    grade_value: 10.9,
  },
  {
    label: "四級地",
    value: "4",
    grade_value: 10.72,
  },
  {
    label: "五級地",
    value: "5",
    grade_value: 10.6,
  },
  {
    label: "六級地",
    value: "6",
    grade_value: 10.36,
  },
  {
    label: "七級地",
    value: "7",
    grade_value: 10.18,
  },
  {
    label: "その他",
    value: "Others",
    grade_value: 10.0,
  },
]
export const QUALIFICATION_NAME = [
  {
    label: t("児童発達支援管理責任者"),
    value: "児童発達支援管理責任者",
  },
  {
    label: "児童指導員任用資格",
    value: "児童指導員任用資格",
  },
  {
    label: t("社会福祉士"),
    value: "社会福祉士",
  },
  { label: "介護福祉士", value: "介護福祉士" },
  { label: t("精神保健福祉士"), value: "精神保健福祉士" },
  { label: t("公認心理師"), value: "公認心理師" },
  { label: t("臨床心理士"), value: "臨床心理士" },
  { label: t("理学療法士免許"), value: "理学療法士免許" },
  { label: t("作業療法士免許"), value: "作業療法士免許" },
  { label: t("言語聴覚士免許"), value: "言語聴覚士免許" },
  { label: t("心理指導担当職員等"), value: "心理指導担当職員等" },
  { label: t("保育士"), value: "保育士" },
  { label: t("看護師免許"), value: "看護師免許" },
  { label: t("医師免許有資格者"), value: "医師免許有資格者" },
  {
    label: t("強度行動障害支援者養成研修(基礎研修)修了"),
    value: "強度行動障害支援者養成研修(基礎研修)修了",
  },
  {
    label: t("重度訪問介護従業者養成研修行動障害支援課程修了"),
    value: "重度訪問介護従業者養成研修行動障害支援課程修了",
  },
  {
    label: t("行動援護従業者養成研修修了"),
    value: "行動援護従業者養成研修修了",
  },
  { lable: t("手話通訳士"), value: "手話通訳士" },
  { label: t("手話通訊者"), value: "手話通訊者" },
  { label: t("柔道整復師"), value: "柔道整復師" },
  { label: t("鍼灸師"), value: "鍼灸師" },
  { label: t("あん摩マッサージ指圧師"), value: "あん摩マッサージ指圧師" },
  {
    label: t("国立障害者リハビリテーションセンター視覚障害学科履修者"),
    value: "国立障害者リハビリテーションセンター視覚障害学科履修者",
  },
  { label: t("その他"), value: "その他" },
]

export const MONTHS = [
  { label: t("01月"), value: 1 },
  { label: t("02月"), value: 2 },
  { label: t("03月"), value: 3 },
  { label: t("04月"), value: 4 },
  { label: t("05月"), value: 5 },
  { label: t("06月"), value: 6 },
  { label: t("07月"), value: 7 },
  { label: t("08月"), value: 8 },
  { label: t("09月"), value: 9 },
  { label: t("10月"), value: 10 },
  { label: t("11月"), value: 11 },
  { label: t("12月"), value: 12 },
]
export const WORK_TYPES = [
  {
    label: t("Full time"),
    value: 1,
  },
  {
    label: t("Full-time/part-time"),
    value: 2,
  },
  {
    label: t("Part-time/full-time"),
    value: 3,
  },
  {
    label: t("Part time"),
    value: 4,
  },
]
export const WORK_EXPERIENCE = [
  {
    label: t("5年以上 "),
    value: 1,
  },
  {
    label: t("5年未満"),
    value: 2,
  },
]
export const OCCUPATION_TYPES = [
  {
    label: t("管理者"),
    value: 1,
  },
  {
    label: t("障害福祉サービス経験者"),
    value: 2,
  },
  {
    label: t(" 指導員(その他)"),
    value: 3,
  },
  {
    label: t("訪問支援員"),
    value: 4,
  },
  {
    label: t("栄養士 調理員"),
    value: 5,
  },
  {
    label: t("その他"),
    value: 6,
  },
]

export const DEFAULT_HOURS = {
  business_start_hour1: null,
  business_start_minute1: null,
  business_end_hour1: null,
  business_end_minute1: null,
  business_start_hour2: null,
  business_start_minute2: null,
  business_end_hour2: null,
  business_end_minute2: null,
  business_start_hour3: null,
  business_start_minute3: null,
  business_end_hour3: null,
  business_end_minute3: null,
  service_start_hour1: null,
  service_start_minute1: null,
  service_end_hour1: null,
  service_end_minute1: null,
  service_start_hour2: null,
  service_start_minute2: null,
  service_end_hour2: null,
  service_end_minute2: null,
  service_start_hour3: null,
  service_start_minute3: null,
  service_end_hour3: null,
  service_end_minute3: null,
}

export const DAYS_OPTION = [
  {
    key: 1,
    label: 1,
    value: 1,
  },
  {
    key: 2,
    label: 2,
    value: 2,
  },
  {
    key: 3,
    label: 3,
    value: 3,
  },
  {
    key: 4,
    label: 4,
    value: 4,
  },
  {
    key: 5,
    label: 5,
    value: 5,
  },
  {
    key: 6,
    label: 6,
    value: 6,
  },
  {
    key: 7,
    label: 7,
    value: 7,
  },
  {
    key: 8,
    label: 8,
    value: 8,
  },
  {
    key: 9,
    label: 9,
    value: 9,
  },
  {
    key: 10,
    label: 10,
    value: 10,
  },
  {
    key: 11,
    label: 11,
    value: 11,
  },
  {
    key: 12,
    label: 12,
    value: 12,
  },
  {
    key: 13,
    label: 13,
    value: 13,
  },
  {
    key: 14,
    label: 14,
    value: 14,
  },
  {
    key: 15,
    label: 15,
    value: 15,
  },
  {
    key: 16,
    label: 16,
    value: 16,
  },
  {
    key: 17,
    label: 17,
    value: 17,
  },
  {
    key: 18,
    label: 18,
    value: 18,
  },
  {
    key: 19,
    label: 19,
    value: 19,
  },
  {
    key: 20,
    label: 20,
    value: 20,
  },
  {
    key: 21,
    label: 21,
    value: 21,
  },
  {
    key: 22,
    label: 22,
    value: 22,
  },
  {
    key: 23,
    label: 23,
    value: 23,
  },
  {
    key: 24,
    label: 24,
    value: 24,
  },
  {
    key: 25,
    label: 25,
    value: 25,
  },
  {
    key: 26,
    label: 26,
    value: 26,
  },
  {
    key: 27,
    label: 27,
    value: 27,
  },
  {
    key: 28,
    label: 28,
    value: 28,
  },
  {
    key: 29,
    label: 29,
    value: 29,
  },
  {
    key: 30,
    label: 30,
    value: 30,
  },
  {
    key: 31,
    label: 31,
    value: 31,
  },
]
/**
 *
 *These are options for
 Facility setup form
 *
 *
 */

export const ACCEPT_NOT = [
  {
    label: t("Accept"),
    value: "accept",
  },
  {
    label: t("Do not accept"),
    value: "do-not-accept",
  },
]

export const DISPLAY_NOT = [
  {
    label: t("Display"),
    value: "display",
  },
  {
    label: t("Do not display"),
    value: "do-not-display",
  },
]

export const SET_UP_NOT = [
  {
    label: t("Set up"),
    value: "set-up",
  },
  {
    label: t("Do not set up"),
    value: "do-not-set-up",
  },
]
export const SEND_NOT = [
  {
    label: t("Send"),
    value: "send",
  },
  {
    label: t("Do not send"),
    value: "do-not-send",
  },
]
export const SET_NOT = [
  {
    label: t("Set"),
    value: "set",
  },
  {
    label: t("Do not set"),
    value: "do-not-set",
  },
]
export const USE_NOT = [
  {
    label: t("Use"),
    value: "use",
  },
  {
    label: t("Do not use"),
    value: "do-not-use",
  },
]

export const MARK_READ_ACTION = [
  {
    label: t("When you press the read \nbutton"),
    value: "button",
  },
  {
    label: t("When you open the \ndetail page"),
    value: "open-detail",
  },
  {
    label: t("When you fill in your digital signature"),
    value: "fill-sign",
  },
]

export const SETTING_CALENDAR = [
  {
    label: t("利用サービス"),
    value: "service-used",
  },
  {
    label: t("施設名"),
    value: "facility-name",
  },
  {
    label: t("今日の活動"),
    value: "today-program",
  },
  {
    label: t("お弁当"),
    value: "lunch-box",
  },
  {
    label: t("お弁当種類"),
    value: "lunch-type",
  },
  {
    label: t("送迎"),
    value: "pick-up",
  },
  {
    label: t("お迎え希望時間"),
    value: "desired-pick-up",
  },
  {
    label: t("送り希望時間"),
    value: "desired-see-off ",
  },
  {
    label: t("お迎え場所"),
    value: "pick-up-address",
  },
  {
    label: t("送り場所"),
    value: "see-off-address",
  },
  {
    label: t("実費"),
    value: "actual-cost",
  },
  {
    label: t("備考"),
    value: "remarks",
  },
]

export const APPLICATION_ACCEPT = [
  {
    label: t("お弁当"),
    value: "lunch-box",
  },
  {
    label: t("お弁当種類"),
    value: "lunch-type",
  },
  {
    label: t("送迎"),
    value: "pick-up",
  },
  {
    label: t("お迎え希望時間"),
    value: "desired-pick-up",
  },
  {
    label: t("送り希望時間"),
    value: "desired-see-off",
  },
  {
    label: t("実費(表示のみ)"),
    value: "actual-cost",
  },
  {
    label: t("備考欄"),
    value: "remark",
  },
]

export const SERVICE_PROVISION_RECORD = [
  {
    label: t("「何枚中何枚」の欄を表示する"),
    value: "「何枚中何枚」の欄を表示する",
  },
  {
    label: t("様式番号を表示する"),
    value: "様式番号を表示する",
  },
  {
    label: t("「上記内容について確認しました。」を表示する"),
    value: "「上記内容について確認しました。」を表示する",
  },
  {
    label: t("「上記内容について確認しました。」を表示する"),
    value: "「上記内容について確認しました。」を表示する",
  },
  {
    label: t("下部に日付記入欄を表示する"),
    value: "下部に日付記入欄を表示する",
  },
  {
    label: t("保護者等確認欄を無くし、下部に表示する"),
    value: "保護者等確認欄を無くし、下部に表示する",
  },
  {
    label: t("デジタルサイン署名欄に日付を記入できるようにする"),
    value: "デジタルサイン署名欄に日付を記入できるようにする",
  },
  {
    label: t("欠席時対応加算理由を表示する"),
    value: "欠席時対応加算理由を表示する",
  },
]

export const SUPPORT_PLAN_OPTION = [
  {
    label: t("説明同意日"),
    value: "説明同意日",
  },
  {
    label: t("保護者サイン欄"),
    value: "保護者サイン欄",
  },
  {
    label: t("同意説明文"),
    value: "同意説明文",
  },
  {
    label: t("児童サイン欄"),
    value: "児童サイン欄",
  },
  {
    label: t("児童発達支援管理責任者の同意説明文"),
    value: "児童発達支援管理責任者の同意説明文",
  },
  {
    label: t("保護者印鑑"),
    value: "保護者印鑑",
  },
]

export const RECORD_SETTING = [
  {
    label: t("時間"),
    value: "時間",
  },
  {
    label: t("体温"),
    value: "体温",
  },
  {
    label: t("食事"),
    value: "食事",
  },
  {
    label: t("おやつ"),
    value: "おやつ",
  },
  {
    label: t("画像"),
    value: "画像",
  },
  {
    label: t("今日の活動"),
    value: "今日の活動",
  },
  {
    label: t("サービス提供内容"),
    value: "サービス提供内容",
  },
  {
    label: t("個別支援計画振り返り"),
    value: "個別支援計画振り返り",
  },
  {
    label: t("コメント（本日の様子）"),
    value: "コメント（本日の様子）",
  },
]

//childList

export const CHILD_FACILITY_OPTION = [
  {
    label: t("石巻 "),
    value: "石巻 ",
  },
  {
    label: t("つくば(3人)"),
    value: "つくば(3人)",
  },
  {
    label: t("山武(3人)"),
    value: "山武(3人)",
  },
  {
    label: t("東松山(3人)"),
    value: "東松山(3人)",
  },
  {
    label: t("(放) 宮子(3人)"),
    value: "(放) 宮子(3人)",
  },
  {
    label: t("(放) 小泉(3人)"),
    value: "(放) 小泉(3人)",
  },
  {
    label: t("本庄(3人)"),
    value: "本庄(3人)",
  },
  {
    label: t("吉岡(3人)"),
    value: "吉岡(3人)",
  },
  {
    label: t("熊谷(3人)"),
    value: "熊谷(3人)",
  },
  {
    label: t("太田(3人)"),
    value: "太田(3人)",
  },
  {
    label: t("宮子(3人)"),
    value: "宮子(3人)",
  },
  {
    label: t("小泉(13人)"),
    value: "小泉(13人)",
  },
  {
    label: t("契約施設無し(31人)"),
    value: "契約施設無し(31人)",
  },
]

export const CHILD_SERVICE_OPTION = [
  {
    label: t("After school day service"),
    value: "1",
    colorCode: theme.red3,
  },
  {
    label: t("Child development support"),
    value: "2",
    colorCode: theme.blue7,
  },
  {
    label: t("After day school[Severely handicapped child]"),
    value: "3",
    colorCode: theme.red3,
  },
  {
    label: t("Child development support[Severely handicapped child]"),
    value: "4",
    colorCode: theme.blue7,
  },
]

//get 31 day list
export const getDaysList = () => {
  const daysList = []
  for (let i = 1; i <= 31; i++) {
    daysList.push(i)
  }
  return daysList.map((item) => {
    return {
      label: item,
      value: item,
    }
  })
}

//get all 12 month list
export const getMonthList = () => {
  const monthsList = []
  for (let i = 1; i <= 12; i++) {
    monthsList.push(i)
  }
  return monthsList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
}

//get all year from now to 1990 list
export const getYearList = () => {
  const yearNow = Number(moment().format("YYYY"))
  const yearList = []
  for (let i = yearNow + 5; i >= 2021; i = i - 1) {
    yearList.push(i)
  }
  return yearList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
}

//get all year from now to 1990 list
export const getPastYearList = (reverse?: boolean) => {
  const yearNow = Number(moment().format("YYYY"))
  const yearList = []
  for (let i = 1980; i <= yearNow; i = i + 1) {
    yearList.push(i)
  }
  const list = yearList.map((item) => {
    return {
      label: `${item}`,
      value: item,
    }
  })
  if (reverse) {
    return list.reverse()
  }
  return list
}

export const DEFAULT_FACILITY_APPLICATION_SETTINGS = {
  accept_cancel_waiting_flg: "0",
  accept_over_app: "0",
  cancel_waiting_distribution: "1",
}
// notification types
export const notificationTypes = [
  {
    label: t("Important"),
    value: "important",
  },
  {
    label: t("Update"),
    value: "update",
  },
  {
    label: t("Notification"),
    value: "notification",
  },
]

// account type
export const ACCOUNT_TYPE = [
  {
    label: t("Normal"),
    value: 1,
  },
  {
    label: t("Current"),
    value: 2,
  },
  {
    label: t("Tax prep"),
    value: 3,
  },
  {
    label: t("Savings"),
    value: 4,
  },
  {
    label: t("Others"),
    value: 5,
  },
]

export const ACCOUNT_TYPE_KEY_VAL = {
  0: "---",
  1: t("Normal"),
  2: t("Current"),
  3: t("Tax prep"),
  4: t("Savings"),
  5: t("Others"),
}

export const CHILDREN_CONTRACT_FACILITY = [
  {
    label: t("つくば"),
    value: "つくば",
  },
  {
    label: t("山武"),
    value: "山武",
  },
  {
    label: t("東松山"),
    value: "東松山",
  },
  {
    label: t("(放) 宮子"),
    value: "(放) 宮子",
  },
  {
    label: t("(放) 小泉"),
    value: "(放) 小泉",
  },
  {
    label: t("本庄"),
    value: "本庄",
  },
  {
    label: t("吉岡"),
    value: "吉岡",
  },
  {
    label: t("熊谷"),
    value: "熊谷",
  },
  {
    label: t("太田"),
    value: "太田",
  },
  {
    label: t("宮子"),
    value: "宮子",
  },
  {
    label: t("小泉"),
    value: "小泉",
  },
  {
    label: t("契約施設無し"),
    value: "契約施設無し",
  },
]

export const PREFECTURES = [
  {
    label: "東京都",
    value: "東京都",
  },
  {
    label: "北海道",
    value: "北海道",
  },
  {
    label: "青森県",
    value: "青森県",
  },
  {
    label: "岩手県",
    value: "岩手県",
  },
  {
    label: "宮城県",
    value: "宮城県",
  },
  {
    label: "秋田県",
    value: "秋田県",
  },
  {
    label: "山形県",
    value: "山形県",
  },
  {
    label: "福島県",
    value: "福島県",
  },
  {
    label: "茨城県",
    value: "茨城県",
  },
  {
    label: "栃木県",
    value: "栃木県",
  },
  {
    label: "群馬県",
    value: "群馬県",
  },
  {
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    label: "千葉県",
    value: "千葉県",
  },
  {
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    label: "新潟県",
    value: "新潟県",
  },
  {
    label: "富山県",
    value: "富山県",
  },
  {
    label: "石川県",
    value: "石川県",
  },
  {
    label: "福井県",
    value: "福井県",
  },
  {
    label: "山梨県",
    value: "山梨県",
  },
  {
    label: "長野県",
    value: "長野県",
  },
  {
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    label: "静岡県",
    value: "静岡県",
  },
  {
    label: "愛知県",
    value: "愛知県",
  },
  {
    label: "三重県",
    value: "三重県",
  },
  {
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    label: "京都付",
    value: "京都付",
  },
  {
    label: "大阪付",
    value: "大阪付",
  },
  {
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    label: "奈良県",
    value: "奈良県",
  },
  {
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    label: "島根県",
    value: "島根県",
  },
  {
    label: "岡山県",
    value: "岡山県",
  },
  {
    label: "広島県",
    value: "広島県",
  },
  {
    label: "山口県",
    value: "山口県",
  },
  {
    label: "徳島県",
    value: "徳島県",
  },
  {
    label: "香川県",
    value: "香川県",
  },
  {
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    label: "高知県",
    value: "高知県",
  },
  {
    label: "福岡県",
    value: "福岡県",
  },
  {
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    label: "長崎県",
    value: "長崎県",
  },
  {
    label: "熊本県",
    value: "熊本県",
  },
  {
    label: "大分県",
    value: "大分県",
  },
  {
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    label: "沖縄県",
    value: "沖縄県",
  },
]

export const PREFECTURE_OPTIONS = PREFECTURES.map((item, index) => {
  return {
    label: item.label,
    value: index + 1,
  }
})

export const DISPLAY_CONTENT = [
  {
    label: t("List of messages received from parents"),
    value: "1",
  },
  {
    label: t("List of message sent from the facility"),
    value: "2",
  },
]

export const STATUS = (displayContent?: any) => {
  const statusList = [
    {
      label: t("Unread"),
      value: "0",
    },
    {
      label: t("Read"),
      value: "1",
    },
  ]

  if (displayContent == "1") {
    statusList.push({
      label: t("No notes from parents"),
      value: "2",
    })
  }
  return statusList
}

export const CHILD_SERVICE_TYPES = {
  1: t("After school day service"),
  2: t("Child development support"),
}

export const SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY = {
  "1": "611000",
  "2": "613000",
  "3": "612000",
  "4": "631000",
  "5": "632000",
}

export const SERVICE_CODE_FOR_CHILD_SUPPORT_DISABILITY_AFTER_SCHOOL = {
  "1": "631000",
  "2": "632000",
}

export const CHILD_DEVELOPMENT_SUPPORT_DISABILITIES = [
  {
    label: t("Handicapped child") + `  611000`,
    code: "611000",
    value: 1,
  },
  {
    label: t("Severely handicapped child") + ` 613000`,
    code: "613000",
    value: 2,
  },
  {
    label: t("Hearing-impaired children") + ` 612000`,
    code: "612000",
    value: 3,
  },
]
export const AFTER_DAY_SCHOOL_DISABILITIES = [
  {
    label: t("Handicapped child") + ` 631000`,
    code: "631000",
    value: 1,
  },
  {
    label: t("Severely handicapped child") + ` 632000`,
    code: "632000",
    value: 2,
  },
]
export const benefitPaymentAmount = () => {
  const amounts = Array.from({ length: 32 }, (_, i) => ({
    value: i,
    label: i,
  }))
  return [
    {
      value: -1,
      label: t("No of days in a month"),
    },
    ...amounts,
  ]
}

export const BURDERN_UPPER_LIMIT = [
  { label: "0", value: 0 },
  { label: "4,600", value: 4600 },
  { label: "18,600", value: 18600 },
  { label: "37,200", value: 37200 },
]
export const BURDERN_UPPER_LIMIT_WITH_STRING_VALUE = [
  { label: "0", value: "0" },
  { label: "4,600", value: "4600" },
  { label: "18,600", value: "18600" },
  { label: "37,200", value: "37200" },
]
export const CHILD_GUIDENCE = [
  {
    label: t("Regular class"),
    value: "Regular class",
  },
  {
    label: t("Class instruction classroom"),
    value: "Class instruction classroom",
  },
  {
    label: t("special needs class"),
    value: "special needs class",
  },
  {
    label: t("Special support school"),
    value: "Special support school",
  },
]

export const SERVICE_OPTION = [
  {
    label: CHILD_SERVICE_TYPES?.[1],
    value: "1",
  },
  {
    label: CHILD_SERVICE_TYPES?.[2],
    value: "2",
  },
]

export const COMMENT_STATUS = [
  {
    label: t("All"),
    value: null,
  },
  {
    label: t("Read"),
    value: "1",
  },
  {
    label: t("Set to read"),
    value: "0",
  },
]

export const MEDICAL_CARE_CHILD_TYPE = [
  { label: t("Division 1"), value: 1 },
  { label: t("Division 2"), value: 2 },
]

export const MULTIPLE_CHILD_REDUCTION_TYPE = [
  { label: "--", value: 0 },
  { label: t("2nd child"), value: 1 },
  { label: t("3rd or above"), value: 2 },
]

export const UPPER_LIMIT_CONTROL_ADDITION_ACQUISATIONS = [
  { label: t("Do not aquire"), value: 1 },
  { label: t("Acquire"), value: 2 },
]

export const getFacilityOptionsBasedOnMangementCompany = (
  company,
  ownFacility,
  otherFacility
) => {
  if (company === "company" || company == 1) {
    return ownFacility?.map(({ facility_name_short, id }) => ({
      label: facility_name_short,
      value: id,
    }))
  }
  if (company === "other" || company == 2) {
    return otherFacility?.map(({ label, value, facility_name, id }) => {
      return {
        label: label || facility_name,
        value: value || id,
      }
    })
  }
}

export const upperLimitManagementCompanyOptions = [
  {
    label: t("None"),
    value: 0,
  },
  {
    label: t("Own facility"),
    value: 1,
  },
  {
    label: t("Other facilities"),
    value: 2,
  },
]

export const serviceType = {
  1: t("放課後等デイサービス"),
  2: t("児童発達支援"),
}

export const upperLimitManagementCompanyType = {
  0: t("None"),
  1: t("Own facility"),
  2: t("Other facilities"),
}
export const TIME_INTERVALS = [
  {
    label: "06:00",
    value: "06:00",
  },
  {
    label: "06:15",
    value: "06:15",
  },
  {
    label: "06:30",
    value: "06:30",
  },
  {
    label: "06:45",
    value: "06:45",
  },
  {
    label: "07:00",
    value: "07:00",
  },
  {
    label: "07:15",
    value: "07:15",
  },
  {
    label: "07:30",
    value: "07:30",
  },
  {
    label: "07:45",
    value: "07:45",
  },
  {
    label: "08:00",
    value: "08:00",
  },
  {
    label: "08:15",
    value: "08:15",
  },
  {
    label: "08:30",
    value: "08:30",
  },
  {
    label: "08:45",
    value: "08:45",
  },
  {
    label: "09:00",
    value: "09:00",
  },
  {
    label: "09:15",
    value: "09:15",
  },
  {
    label: "09:30",
    value: "09:30",
  },
  {
    label: "09:45",
    value: "09:45",
  },
  {
    label: "10:00",
    value: "10:00",
  },
  {
    label: "10:15",
    value: "10:15",
  },
  {
    label: "10:30",
    value: "10:30",
  },
  {
    label: "10:45",
    value: "10:45",
  },
  {
    label: "11:00",
    value: "11:00",
  },
  {
    label: "11:15",
    value: "11:15",
  },
  {
    label: "11:30",
    value: "11:30",
  },
  {
    label: "11:45",
    value: "11:45",
  },
  {
    label: "12:00",
    value: "12:00",
  },
  {
    label: "12:15",
    value: "12:15",
  },
  {
    label: "12:30",
    value: "12:30",
  },
  {
    label: "12:45",
    value: "12:45",
  },
  {
    label: "13:00",
    value: "13:00",
  },
  {
    label: "13:15",
    value: "13:15",
  },
  {
    label: "13:30",
    value: "13:30",
  },
  {
    label: "13:45",
    value: "13:45",
  },
  {
    label: "14:00",
    value: "14:00",
  },
  {
    label: "14:15",
    value: "14:15",
  },
  {
    label: "14:30",
    value: "14:30",
  },
  {
    label: "14:45",
    value: "14:45",
  },
  {
    label: "15:00",
    value: "15:00",
  },
  {
    label: "15:15",
    value: "15:15",
  },
  {
    label: "15:30",
    value: "15:30",
  },
  {
    label: "15:45",
    value: "15:45",
  },
  {
    label: "16:00",
    value: "16:00",
  },
  {
    label: "16:15",
    value: "16:15",
  },
  {
    label: "16:30",
    value: "16:30",
  },
  {
    label: "16:45",
    value: "16:45",
  },
  {
    label: "17:00",
    value: "17:00",
  },
  {
    label: "17:15",
    value: "17:15",
  },
  {
    label: "17:30",
    value: "17:30",
  },
  {
    label: "17:45",
    value: "17:45",
  },
  {
    label: "18:00",
    value: "18:00",
  },
  {
    label: "18:15",
    value: "18:15",
  },
  {
    label: "18:30",
    value: "18:30",
  },
  {
    label: "18:45",
    value: "18:45",
  },
  {
    label: "19:00",
    value: "19:00",
  },
  {
    label: "19:15",
    value: "19:15",
  },
  {
    label: "19:30",
    value: "19:30",
  },
  {
    label: "19:45",
    value: "19:45",
  },
  {
    label: "20:00",
    value: "20:00",
  },
]

export const SERVICES = [
  { label: t("After day school service"), value: "1" },
  { label: t("Child development service"), value: "2" },
]

export const CHILD_SERVICES_OBJECT = {
  "1": t("After day school service"),
  "2": t("Child development service"),
}

export const REMARK_OPTION = [
  {
    label: t("Facility application remarks"),
    value: "1",
  },
  {
    label: t("Actual expenses/expenses"),
    value: "2",
  },
  {
    label: t("Addition item"),
    value: "3",
  },
]

export const UPDATE_OR_NOT_LIST = [
  {
    label: t("Do not update"),
    value: 0,
  },
  { label: t("Want to update"), value: 1 },
]

export const PICK_DROP_OPTIONS = [
  {
    label: "--",
    value: "",
  },
  {
    label: t("School"),
    value: "school",
  },
  {
    label: t("One's home"),
    value: "home",
  },
  {
    label: t("Others"),
    value: "others",
  },
]

export const PICK_DROP_CDS_OPTIONS = [
  {
    label: "--",
    value: "",
  },
  {
    label: t("Nursery school"),
    value: "school",
  },
  {
    label: t("One's home"),
    value: "home",
  },
  {
    label: t("Others"),
    value: "others",
  },
]

export const NO_YES_LIST = [
  { label: t("No"), value: 0 },
  {
    label: t("Yes"),
    value: 1,
  },
]

export const SCHEDULE_DAYS = [
  {
    label: t("Sun"),
    value: "0",
  },
  {
    label: t("Mon"),
    value: "1",
  },
  {
    label: t("Tue"),
    value: "2",
  },
  {
    label: t("Wed"),
    value: "3",
  },
  {
    label: t("Thu"),
    value: "4",
  },
  {
    label: t("Fri"),
    value: "5",
  },
  {
    label: t("Sat"),
    value: "6",
  },
]

export const PRINT_SETTINGS_VALUES = {
  DISPLAY_HOW_MANY_COLUMN_VALUE: "1",
  DISPLAY_FORM_NUMBER_VALUE: "2",
  CHANGE_DISABLE_CHILD_NAME_VALUE: "3",
  CONFIRMED_CONTENT_VALUE: "4",
  SHOW_DATE_FIELD_BOTTOM_VALUE: "5",
  ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE: "6",
  DISPLAY_ACHIEVEMENT_IN_BLANK_PAGE_VALUE: "7",
  DISPLAY_PLANNED_USE_DATE_VALUE: "8",
  REFLECT_DESIRED_PICK_DROP_TIMES_IN_START_END_VALUE: "9",
  LEAVE_FIELD_FOR_GUARDIAN_CONFIRMATION_VALUE: "14",
  DISPLAY_CHILD_NAME_AT_BOTTOM_VALUE: "11",
  DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE: "12",
  DISPLAY_SEAL_COLUMN_AT_BOTTOM_VALUE: "13",
  DISPLAY_DIGITAL_SIGNATURE: "10",
}

export const PRINT_SETTINGS = [
  {
    label: t("Display the 'how many out of how many' column"),
    value: PRINT_SETTINGS_VALUES.DISPLAY_HOW_MANY_COLUMN_VALUE,
  },
  {
    label: t("Display the form number"),
    value: PRINT_SETTINGS_VALUES.DISPLAY_FORM_NUMBER_VALUE,
  },
  {
    label: t("Change 'Disabled child's name' to 'Child's name'"),
    value: PRINT_SETTINGS_VALUES.CHANGE_DISABLE_CHILD_NAME_VALUE,
  },
  {
    label: t("Display 'I have confirmed the above contents.'"),
    value: PRINT_SETTINGS_VALUES.CONFIRMED_CONTENT_VALUE,
  },
  {
    label: t("Show date field at bottom"),
    value: PRINT_SETTINGS_VALUES.SHOW_DATE_FIELD_BOTTOM_VALUE,
  },

  {
    label: t(
      "Eliminate the parent/guardian confirmation column and display it at the bottom"
    ),
    value: PRINT_SETTINGS_VALUES.ELIMINATE_CONFIRMATION_COLUMN_AT_BOTTOM_VALUE,
  },
  {
    label: t("Display achievements on a blank page"),
    value: PRINT_SETTINGS_VALUES.DISPLAY_ACHIEVEMENT_IN_BLANK_PAGE_VALUE,
  },
  {
    label: t("Display planned use date"),
    value: PRINT_SETTINGS_VALUES.DISPLAY_PLANNED_USE_DATE_VALUE,
  },
]

export const ADDITIONAL_FIELDS_WHEN_ELIMINATE_PARENTAL_CONFIRMATION_FIELD_IS_CHECKED =
  [
    {
      label: t("Leave the field for confirmation of guardian, etc."),
      value: PRINT_SETTINGS_VALUES.LEAVE_FIELD_FOR_GUARDIAN_CONFIRMATION_VALUE,
    },
    {
      label: t("Display child's name at the bottom"),
      value: PRINT_SETTINGS_VALUES.DISPLAY_CHILD_NAME_AT_BOTTOM_VALUE,
    },
    {
      label: t("Display signature field for parents at the bottom"),
      value:
        PRINT_SETTINGS_VALUES.DISPLAY_PARENT_SIGNATURE_FIELD_AT_BOTTOM_VALUE,
    },
    {
      label: t("Display the seal column at the bottom"),
      value: PRINT_SETTINGS_VALUES.DISPLAY_SEAL_COLUMN_AT_BOTTOM_VALUE,
    },
  ]

export const PRINT_SETTINGS_WHEN_SCHEDULED_DAYS_IS_CHECKED = [
  {
    label: t(
      "Reflect the desired pick-up and drop-off times in the start and end times"
    ),
    value:
      PRINT_SETTINGS_VALUES.REFLECT_DESIRED_PICK_DROP_TIMES_IN_START_END_VALUE,
  },
]

export const REMARK_CONTENT_VALUES = {
  REMARKS: "1",
  REMARKS_OUTPUT_NHIF: "2",
  ACTUAL_EXPENSES: "3",
  REASON_EXTRA_CHARGE_ABSENTEEISM: "4",
}

export const REMARK_CONTENT = [
  {
    label: t("Remarks"),
    value: REMARK_CONTENT_VALUES.REMARKS,
  },
  {
    label: t(
      "Remarks output when making a request to the National Health Insurance Federation"
    ),
    value: REMARK_CONTENT_VALUES.REMARKS_OUTPUT_NHIF,
  },
  {
    label: t("Actual expenses/expenses"),
    value: REMARK_CONTENT_VALUES.ACTUAL_EXPENSES,
  },
  {
    label: t("Reasons for extra charge for absenteeism"),
    value: REMARK_CONTENT_VALUES.REASON_EXTRA_CHARGE_ABSENTEEISM,
  },
]

export const ACTUAL_EXPENSES_RADIO_OPTION_VALUES = {
  DONOT_INCLUDE_ACTUAL_COST_OF_0_YEN: "1",
  INCLUDE_ACTUAL_COST_OF_0_YEN: "2",
}

export const ADDITIONAL_REMARK_CONTENT_WHEN_ACTUAL_EXPENSES_IS_CHECKED = [
  {
    label: t("Do not include actual cost of 0 yen"),
    value:
      ACTUAL_EXPENSES_RADIO_OPTION_VALUES.DONOT_INCLUDE_ACTUAL_COST_OF_0_YEN,
  },
  {
    label: t("Includes actual cost of 0 yen"),
    value: ACTUAL_EXPENSES_RADIO_OPTION_VALUES.INCLUDE_ACTUAL_COST_OF_0_YEN,
  },
]

export const ADDITIONAL_ITEM = [
  {
    label: t("Extension support addition"),
    value: "2", // value matched based on addition item child page
  },
  {
    label: t("Special support addition"),
    value: "5",
  },
  {
    label: t("Individual support addition (I)"),
    value: "6",
  },
  {
    label: t("Individual support addition (II)"),
    value: "7",
  },
  {
    // TODO: translate
    label: "個別支援サポート（III）",
    value: "26",
  },
  {
    label: t("Affiliated Institutions Collaboration Addition (I)"),
    value: "9",
  },

  {
    label: t("Affiliated institution cooperation addition (II)"),
    value: "10",
  },
  {
    label: t("Severe Behavioral Disorder Child Support Addition"),
    value: "11",
  },
  {
    label: "強度行動障害児支援加算(II)",
    value: "31", // value matched based on addition item child page
  },
  {
    label: t("Only basic remuneration is calculated"),
    value: "14",
  },
  {
    // TODO: translate
    label: "家族支援加算（I）",
    value: "29",
  },
  {
    // TODO: translate
    label: "家族支援加算（II）",
    value: "30",
  },
  {
    // TODO: translate
    label: "事業所間連携加算（I）",
    value: "24",
  },
  {
    // TODO: translate
    label: "事業所間連携加算（II）",
    value: "25",
  },
]

export const DIGITALSIGN_VALUES = {
  SIGN_AND_DISPLAY_WITH_SIGNATURE_VALUE: "1",
  ENTER_AND_DISPLAY_DATE_IN_SIGNATURE_LINE: "2",
}

export const DIGITALSIGN_OPTION = [
  {
    label: t("Sign and display with a digital signature"),
    value: DIGITALSIGN_VALUES.SIGN_AND_DISPLAY_WITH_SIGNATURE_VALUE,
  },
]

export const DIGITALSIGN_SUB_OPTION = [
  {
    label: t("Enter and display the date in the signature line"),
    value: DIGITALSIGN_VALUES.ENTER_AND_DISPLAY_DATE_IN_SIGNATURE_LINE,
  },
]

export const INITIAL_DIGITALSIGN_OPTION = [
  {
    label: t("Sign and display with a digital signature"),
    value: "1",
  },
]

export const ABSENCE_REASON_DISPLAY_VALUES = {
  NOT_DISPLAYED: "1",
  DISPLAYED: "2",
}

export const ADDITIONAL_FIELDS_WHEN_ABSENT_NO_ADDITION_AND_REASONS_ARE_CHECKED =
  [
    {
      label: t("The reason for no addition is not displayed."),
      value: ABSENCE_REASON_DISPLAY_VALUES.NOT_DISPLAYED,
    },
    {
      label: t("Also display the reason for no addition"),
      value: ABSENCE_REASON_DISPLAY_VALUES.DISPLAYED,
    },
  ]

export const PICKUP_DISPLAY_OPTIONS = [
  {
    key: "location",
    label: t("Pickup location"),
    value: 1,
  },
  {
    key: "circle",
    label: t("Display with ◯"),
    value: 2,
  },
]

export const ACCOUNT_TYPE_OPTIONS = [
  {
    label: "---",
    value: 0,
  },
  {
    label: t("Current"),
    value: 1,
  },
  {
    label: t("Normal"),
    value: 2,
  },
  {
    label: t("Savings"),
    value: 3,
  },
  {
    label: t("Others"),
    value: 4,
  },
]

export const ADDITION_TYPE_VALUES = {
  HOME_COOPERATION: 130,
  INHOUSE_CONSULTATION1: 140,
  INHOUSE_CONSULTATION2: 150,
  RELATED_ORGANIZATION: 160,
  RELATED_ORGANIZATION2: 170,
  SPECIAL_SUPPORT_BONUS: 180,
  STRENGTH_BEHAVIOR_DISORDER: 190,
  TRANSITION_SUPPORT: 200,
  OTHER_RECORDS: 999,
  ADDITION_SUPPORT_FAMILIES: 54,
  COCHLEAR_IMPLANTS_SUPPORT: 13,
  COCHLEAR_IMPLANTS_FEE: 3,
  MEAL_PROVISION_FEE: 14,
  SPECIALIZED_SUPPORT_IMPLEMENTATION: 55,
  MEDICAL_COOPERATION: 8,
  RELATED_ORGANIZATION3: 69,
  RELATED_ORGANIZATION4: 70,
  STRENGTH_BEHAVIOR_DISORDER2: 71,
  INTENSIVE_SUPPORT: 61,
  BATHING_SUPPORT: 62,
  CHILD_CARE_SUPPORT: 56,
  DAY_CARE_SUPPORT_FOR_INDEPENDENCE: 57,
  SELF_RELIANCE_SUPPORT: 58,
  COOPERATION_FACILITIES: 59,
  VISUAL_HEARING_SPEECH_IMPAIRMENT: 60,
  HOME_VISIT_DAY_CARE: 63,
  VISITING_SUPPORT_STAFF: 64,
  COOPERATION_RELATED_ORGANIZATION: 65,
  COOPERATION_MULTIPLE_PROFESSIONALS: 66,
  SUPPORT_CARE_NEEDS: 67,
  SEVERE_BEHAVIOR_DISORDER: 68,
}

export const ADDITION_TYPE_OPTIONS = [
  {
    label: t("Home cooperation addition"),
    value: ADDITION_TYPE_VALUES.HOME_COOPERATION,
  },
  {
    label: t("Addition of in-house consultation support (I)"),
    value: ADDITION_TYPE_VALUES.INHOUSE_CONSULTATION1,
  },
  {
    label: t("Addition of in-house consultation support (II)"),
    value: ADDITION_TYPE_VALUES.INHOUSE_CONSULTATION2,
  },
  {
    label: t("Related organizations (I)"),
    value: ADDITION_TYPE_VALUES.RELATED_ORGANIZATION,
  },
  {
    label: t("Related Organizations (II)"),
    value: ADDITION_TYPE_VALUES.RELATED_ORGANIZATION2,
  },
  {
    label: t("Special Support bonus"),
    value: ADDITION_TYPE_VALUES.SPECIAL_SUPPORT_BONUS,
  },
  {
    label: t("Strength Behavioral disorder child support addition"),
    value: ADDITION_TYPE_VALUES.STRENGTH_BEHAVIOR_DISORDER,
  },
  {
    label: t("Addition of childcare/education transition support"),
    value: ADDITION_TYPE_VALUES.TRANSITION_SUPPORT,
  },
  { label: t("Other records"), value: ADDITION_TYPE_VALUES.OTHER_RECORDS },
]

export const ADDITION_TYPE_OPTIONS_FROM_APRIL_2024 = [
  {
    label: t("Additional Support for Families"),
    value: ADDITION_TYPE_VALUES.ADDITION_SUPPORT_FAMILIES,
  },
  {
    label: t("Additional support for children with cochlear implants"),
    value: ADDITION_TYPE_VALUES.COCHLEAR_IMPLANTS_SUPPORT,
  },
  {
    label: t("Additional fee for support for children with cochlear implants"),
    value: ADDITION_TYPE_VALUES.COCHLEAR_IMPLANTS_FEE,
  },
  {
    label: t("Additional fee for meal provision"),
    value: ADDITION_TYPE_VALUES.MEAL_PROVISION_FEE,
  },
  {
    label: t("Additional fee for implementation of specialized support"),
    value: ADDITION_TYPE_VALUES.SPECIALIZED_SUPPORT_IMPLEMENTATION,
  },
  {
    label: t("Additional fee for special support"),
    value: ADDITION_TYPE_VALUES.SPECIAL_SUPPORT_BONUS,
  },
  {
    label: t("Additional fee for medical cooperation system"),
    value: ADDITION_TYPE_VALUES.MEDICAL_COOPERATION,
  },
  {
    label: t("Additional fee for cooperation with related organizations"),
    value: ADDITION_TYPE_VALUES.RELATED_ORGANIZATION,
  },
  {
    label: t("Additional fee for cooperation with related organizations (II)"),
    value: ADDITION_TYPE_VALUES.RELATED_ORGANIZATION2,
  },
  {
    label: t("Additional fee for cooperation with related organizations (III)"),
    value: ADDITION_TYPE_VALUES.RELATED_ORGANIZATION3,
  },
  {
    label: t("Additional fee for cooperation with related organizations (IV)"),
    value: ADDITION_TYPE_VALUES.RELATED_ORGANIZATION4,
  },
  {
    label: t(
      "Additional fee for support for children with severe behavioral disorder (I)"
    ),
    value: ADDITION_TYPE_VALUES.STRENGTH_BEHAVIOR_DISORDER,
  },
  {
    label: t(
      "Additional fee for support for children with severe behavioral disorder (II)"
    ),
    value: ADDITION_TYPE_VALUES.STRENGTH_BEHAVIOR_DISORDER2,
  },
  {
    label: t("Additional fee for intensive support (1)"),
    value: ADDITION_TYPE_VALUES.INTENSIVE_SUPPORT,
  },
  {
    label: t("Additional support for bathing"),
    value: ADDITION_TYPE_VALUES.BATHING_SUPPORT,
  },
  {
    label: t("Additional fee for child care support"),
    value: ADDITION_TYPE_VALUES.CHILD_CARE_SUPPORT,
  },
  {
    label: t("Additional fee for day-care support for independence"),
    value: ADDITION_TYPE_VALUES.DAY_CARE_SUPPORT_FOR_INDEPENDENCE,
  },
  {
    label: t("Additional fee for support for self-reliance"),
    value: ADDITION_TYPE_VALUES.SELF_RELIANCE_SUPPORT,
  },
  {
    label: t("Additional fee for cooperation among facilities"),
    value: ADDITION_TYPE_VALUES.COOPERATION_FACILITIES,
  },
  {
    label: t(
      "Additional support for children with visual, hearing and speech impairment"
    ),
    value: ADDITION_TYPE_VALUES.VISUAL_HEARING_SPEECH_IMPAIRMENT,
  },
  {
    label: t("Addition of childcare/education transition support"),
    value: ADDITION_TYPE_VALUES.TRANSITION_SUPPORT,
  },
  // {
  //   label: t(
  //     "Additional support for family support (home-visit support for day-care center, etc.)"
  //   ),
  //   value: ADDITION_TYPE_VALUES.HOME_VISIT_DAY_CARE,
  // },
  // {
  //   label: t(
  //     "Additional fee for visiting support staff (visiting support for day nurseries, etc.)"
  //   ),
  //   value: ADDITION_TYPE_VALUES.VISITING_SUPPORT_STAFF,
  // },
  // {
  //   label: t(
  //     "Additional fee for cooperation with related organizations (visiting support for day nurseries, etc.)"
  //   ),
  //   value: ADDITION_TYPE_VALUES.COOPERATION_RELATED_ORGANIZATION,
  // },
  // {
  //   label: t(
  //     "Additional fee for support for cooperation among multiple professions (visiting support for day nursery centers, etc.)"
  //   ),
  //   value: ADDITION_TYPE_VALUES.COOPERATION_MULTIPLE_PROFESSIONALS,
  // },
  // {
  //   label: t(
  //     "Additional fee for support for care needs (visiting support for day nurseries, etc.)"
  //   ),
  //   value: ADDITION_TYPE_VALUES.SUPPORT_CARE_NEEDS,
  // },
  // {
  //   label: t(
  //     "Additional fee for support for children with severe behavioral disorder (visiting support for day nurseries, etc.)"
  //   ),
  //   value: ADDITION_TYPE_VALUES.SEVERE_BEHAVIOR_DISORDER,
  // },
  {
    label: t("Other records"),
    value: ADDITION_TYPE_VALUES.OTHER_RECORDS,
  },
]

export const ALL_FACILITY_KINDS = [
  {
    label: t("After school day service"),
    value: 1,
  },

  {
    label: t("Child Development Support"),
    value: 2,
  },
  {
    label: t("After day school[Severely handicapped child]"),
    value: 3,
  },
  {
    label: t("Child development support[Severely handicapped child]"),
    value: 4,
  },
]

export const CAR_COLOR = [
  {
    label: t("Brown"),
    value: "1",
  },
  {
    label: t("Red"),
    value: "2",
  },
  {
    label: t("Reddish purple"),
    value: "3",
  },
  {
    label: t("Purple"),
    value: "4",
  },
  {
    label: t("Blue"),
    value: "5",
  },
  {
    label: t("Yellow-green"),
    value: "6",
  },
  {
    label: t("Green"),
    value: "7",
  },
  {
    label: t("White"),
    value: "8",
  },
  {
    label: t("Silver"),
    value: "9",
  },
  {
    label: t("Yellow"),
    value: "10",
  },
]
export const LOCATIONS = [
  {
    label: t("School"),
    value: 1,
  },
  {
    label: t("One's home"),
    value: 2,
  },
  {
    label: t("others"),
    value: 3,
  },
]

export const FACILITY_LOCATIONS = [
  {
    label: t("school"),
    value: "school",
    locationId: 1,
  },
  {
    label: t("One's home"),
    value: "home",
    locationId: 2,
  },
  {
    label: t("others"),
    value: "others",
    locationId: 3,
  },
]

export const LOCATIONS_FOR_TRANSPORT_BULK_EDIT = [
  {
    label: t("Nursery school"),
    value: 1,
  },
  {
    label: t("one's home"),
    value: 2,
  },
  {
    label: t("others"),
    value: 3,
  },
]

export const SUBSCRIBE_UNSUBSCRIBE_FOR_INFORMATION_COLLECTION = [
  {
    label: t("Make output"),
    value: 1,
  },
  {
    label: t("No output"),
    value: 2,
  },
]
export const CHILDINFORMATION = [
  {
    label: t("Child name"),
    value: "ChildName",
  },
  {
    label: t("Child furigana"),
    value: "ChildNameFurigana",
  },
  {
    label: t("DOB"),
    value: "DOB",
  },
  {
    label: t("Gender"),
    value: "Gender",
  },
  {
    label: t("Hospital name"),
    value: "HospitalName",
  },
  {
    label: t("Doctor name"),
    value: "DoctorName",
  },
  {
    label: t("Hospital contact information"),
    value: "HospitalContact",
  },
  {
    label: t("Day nursery name"),
    value: "DayNurseryName",
  },
  {
    label: t("Day nursery contact information"),
    value: "DayNurseryContact",
  },
  {
    label: t("School year"),
    value: "SchoolYear",
  },
  {
    label: t("Guidance"),
    value: "Guidance",
  },
  {
    label: t("Teacher"),
    value: "Teacher",
  },
  {
    label: t("Allergy"),
    value: "Allergy",
  },
  {
    label: t("Symptoms"),
    value: "Symptoms",
  },
  {
    label: t("Family structure"),
    value: "FamilyStructure",
  },
  {
    label: t("Remarks"),
    value: "Remarks",
  },
  {
    label: t("Special skill· thing of interest"),
    value: "ThingOfInterest",
  },
  {
    label: t("Things to watch out for"),
    value: "ThingsToWatchOut",
  },
]
export const OUTPUT_FORMAT_FOR_INFORMATION_COLLECTION = [
  {
    label: t("Separate sheets for each facility"),
    value: 1,
  },
  {
    label: t("Output all information in one sheet"),
    value: 2,
  },
]
export const PARENTSINFORMATION = [
  {
    label: t("Parents name"),
    value: "ParentName",
  },
  {
    label: t("Parents name furigana"),
    value: "ParentNameFurigana",
  },
  {
    label: t("Po box"),
    value: "PoBox",
  },
  {
    label: t("Address (prefecture)"),
    value: "AddressPrefecture",
  },
  {
    label: t("Address"),
    value: "Address",
  },
  {
    label: t("Phone number"),
    value: "ParentPhoneNumber",
  },
  {
    label: t("Relation with child"),
    value: "RelationWithChild",
  },
  {
    label: t("Day nursery contact"),
    value: "ParentDayNurseryContact",
  },
  {
    label: t("Remarks"),
    value: "ParentRemarks",
  },
  {
    label: t("Emergency phone number 1"),
    value: "EmergencyPhone1",
  },
  {
    label: t("Emergency contact 1"),
    value: "EmergencyContact1",
  },
  {
    label: t("Emergency phone no. 2"),
    value: "EmergencyPhone2",
  },
  {
    label: t("Emergency contact. 2"),
    value: "EmergencyContact2",
  },
  {
    label: t("Emergency contact remarks"),
    value: "EmergencyContactRemarks",
  },
  {
    label: t("Financial institution name Kana"),
    value: "FinancialInstituteNameKana",
  },
  {
    label: t("Branch name Kana"),
    value: "BranchNameKana",
  },
  {
    label: t("Financial institution number"),
    value: "FinancialInstituteNumber",
  },
  {
    label: t("Branch code"),
    value: "BranchCode",
  },
  {
    label: t("Deposit item"),
    value: "DepositItem",
  },
  {
    label: t("Account number"),
    value: "AccountNumber",
  },
  {
    label: t("Account holder"),
    value: "AccountHolderName",
  },
  {
    label: t("Customer code"),
    value: "CustomerCode",
  },
  {
    label: t("Email"),
    value: "Email",
  },
  {
    label: t("Email address (If multiple)"),
    value: "Email2",
  },
  {
    label: t("Email notification setting"),
    value: "EmailNotificationSetting",
  },
]
export const RECIPIENTINFORMATION = [
  {
    label: t("Beneficiary certificate usage start date"),
    value: "CertificateStartDate",
  },
  {
    label: t("Beneficiary certificate number"),
    value: "CertificateNumber",
  },
  {
    label: t("Provision city"),
    value: "PaymentMunicipality",
  },
  {
    label: t("use service"),
    value: "ServiceUsed",
  },
  {
    label: t("Children with disabilities"),
    value: "ChildrenWithDisability",
  },
  {
    label: t("Medical Decision Score"),
    value: "MedicalJudgementScore",
  },
  {
    label: t("Division of children with medical care"),
    value: "DivisionOfChildrenWithMedicalCare",
  },
  {
    label: t("Special support addition"),
    value: "SpecialAreaAddition",
  },
  {
    label: t("Benefit determination period start date"),
    value: "BenefitDeterminationStartDate",
  },
  {
    label: t("Benefit determination period end date"),
    value: "BenefitDeterminationEndDate",
  },
  {
    label: t("Provision period start date"),
    value: "PaymentStartDate",
  },
  {
    label: t("Provision period end date"),
    value: "PaymentEndDate",
  },
  {
    label: t("Consultation support office"),
    value: "ConsultationSupportOffice",
  },
  {
    label: t("Consultation support specialist"),
    value: "ConsultationSupportSpecialist",
  },
  {
    label: t("Burden upper limit monthly amount"),
    value: "BurdenUpperLimitMonthlyAmount",
  },
  {
    label: t("Applicable period start date"),
    value: "ApplicationStartDate",
  },
  {
    label: t("Applicable period end date"),
    value: "ApplicationEndDate",
  },
  {
    label: t("Upper limit management company"),
    value: "UpperLimitManagementCompany",
  },
  {
    label: t("Upper limit control addition acquisition setting"),
    value: "UpperLimitAcquisitionSetting",
  },
  {
    label: t("Multi-child reduction target category"),
    value: "MultiChildReductionTargetCategory",
  },
  {
    label: t("Children eligible for free of charge start date"),
    value: "EligibleChildStartDate",
  },
  {
    label: t("Children eligible for free of charge end date"),
    value: "EligibleChildEndDate",
  },
]

export const FACILITYUSEINFORMATION = [
  {
    label: t("Facility name"),
    value: "NameOfFacility",
  },
  {
    label: t("Use service Year month"),
    value: "ServiceStartDate",
  },
  {
    label: t("Contract start day"),
    value: "ContractStartDate",
  },
  {
    label: t("Service provision start day"),
    value: "ServiceProvisionStartDate",
  },
  {
    label: t("Benefit payment amount"),
    value: "BenefitPaymentAmount",
  },
  {
    label: t("Category for children requiring medical care"),
    value: "DivisionOfChildrenWithMedicalCare",
  },
  {
    label: t("Contract entry number"),
    value: "ContractEntryNumber",
  },
]
export const JOINTFACILITYUSEINFORMATION = [
  {
    label: t("Joint facility name"),
    value: "facility_other_name",
  },
  {
    label: t("Hiragana"),
    value: "facility_name_furigana",
  },
  {
    label: t("Service provision"),
    value: "service_type01",
  },
  {
    label: t("Address"),
    value: "address",
  },
  {
    label: t("Phone number"),
    value: "tel_no",
  },
  {
    label: t("FAX number"),
    value: "fax_no",
  },
  {
    label: t("Remarks"),
    value: "remark_text",
  },
  {
    label: t("Contract amount"),
    value: "payment_amount",
  },
]
export const BODY_TEMPERATURE_OPTIONS = (start: number, end: number) => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = start; i <= end; i++) {
    result.push({
      label: `${i}`,
      value: `${i}`,
    })
  }
  return result
}

export const BODY_TEMPERATURE_OPTIONS_ONE_TEN = (
  start: number,
  end: number
) => {
  const result = []
  result.push({ label: "--", value: "" })
  for (let i = start; i < end; i++) {
    if (i < 10) {
      result.push({
        label: `${i}`,
        value: `${i}`,
      })
    } else {
      result.push({
        label: `${i}`,
        value: `${i}`,
      })
    }
  }
  return result
}

export const afterSchoolServicesOpt = [
  {
    label: t("After school"),
    value: 1,
  },
  {
    label: t("School holiday"),
    value: 2,
  },
]

export const defaultAbsentNote = `【欠席の連絡のあった日】

【誰が電話してきたか】

【連絡を受けた対応者】

【欠席の理由】

【当日のご本人の様子】

【次回通所予定日】
`

// values aren't certain for type II
export const AbsentTypes = [
  {
    label: t("Addition for Absence(Ⅰ)"),
    value: 1,
  },
  {
    label: t("Addition for Absence(Ⅰ)【No addition】"),
    value: 2,
  },
  {
    label: t("Addition for Absence II"),
    value: 3,
  },
  {
    label: t("Addition for Absence II【No addition】"),
    value: 4,
  },
]
export const AbsentTypesWithLineBreak = [
  {
    label: t("Addition for Absence(Ⅰ)."),
    value: 1,
  },
  {
    label: t("Addition for Absence(Ⅰ)【No addition】."),
    value: 2,
  },
  {
    label: t("Addition for Absence II"),
    value: 3,
  },
  {
    label: t("Addition for Absence II【No addition】."),
    value: 4,
  },
]

export const ACTUAL_EXPENSE_OPTIONS = [
  {
    label: t("Does not include the actual cost of 0 yen"),
    value: 1,
  },
  {
    label: t("Include actual cost of 0 yen"),
    value: 2,
  },
]

export const CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_1 = [
  {
    label: t("When conducted at the Child Development Support Center"),
    value: 1,
  },
  {
    label: t("When performing outside the Child Development Support Center"),
    value: 2,
  },
  {
    label: t("Symbiotic Child Development Support Benefits"),
    value: 3,
  },
  {
    label: t("Criteria applicable child development support benefits"),
    value: 4,
  },
]
export const CHILD_DEVELOPMENT_SUPPORT_REVENUE_CONDITION_2 = [
  {
    label: t("Criteria applicable child development support benefits I"),
    value: 1,
  },
  {
    label: t("Criteria applicable child development support benefits II"),
    value: 2,
  },
]

export const AFTER_DAY_SCHOOL_REVENUE_CONDITION_1 = [
  {
    label: t("Disable child type"),
    value: 1,
  },
  {
    label: t("Symbiosis-type after-school day care service benefit expenses"),
    value: 2,
  },
]
export const UPPER_LIMIT_MANAGEMENT_COMPANY_OPTIONS = [
  {
    label: t("None"),
    value: 0,
  },
  {
    label: t("Own facility"),
    value: 1,
  },
  {
    label: t("Other facilities"),
    value: 2,
  },
]

export const INITIAL_PRINT_SETTINGS_ENHANCED = [
  {
    label: t("Display the 'how many out of how many' column"),
    value: "1",
  },
  {
    label: t("Display the form number"),
    value: "2",
  },
  {
    label: t("Display except used day"),
    value: "9",
  },
  {
    label: t("Change 'Disabled child's name' to 'Child's name'"),
    value: "3",
  },
  {
    label: t("Display 'I have confirmed the above contents.'"),
    value: "4",
  },
  {
    label: t("Show date field at bottom"),
    value: "5",
  },
  {
    label: t(
      "Eliminate the parent/guardian confirmation column and display it at the bottom"
    ),
    value: "6",
  },

  {
    label: t("Display achievements on a blank page"),
    value: "7",
  },

  {
    label: t("Display planned use date"),
    value: "8",
  },
]

export const PRINT_SETTINGS_ENHANCED = [
  {
    label: t("Display the 'how many out of how many' column"),
    value: "1",
  },
  {
    label: t("Display the form number"),
    value: "2",
  },
  {
    label: t("Display except used day"),
    value: "9",
  },
  {
    label: t("Change 'Disabled child's name' to 'Child's name'"),
    value: "3",
  },
  {
    label: t("Display 'I have confirmed the above contents.'"),
    value: "4",
  },
  {
    label: t("Show date field at bottom"),
    value: "5",
  },

  {
    label: t(
      "Eliminate the parent/guardian confirmation column and display it at the bottom"
    ),
    value: "6",
  },
  {
    label: t("Leave the parent/guardian confirmation field"),
    value: "10",
  },
  {
    label: t("Display child's name at the bottom"),
    value: "11",
  },
  {
    label: t("Show parental signature line at bottom"),
    value: "12",
  },

  {
    label: t("Display the seal column at the bottom"),
    value: "13",
  },
  {
    label: t("Display achievements on a blank page"),
    value: "7",
  },

  {
    label: t("Display planned use date"),
    value: "8",
  },
]

export const REMARK_CONTENT_ENHANCEMENT_VALUES = {
  REMARKS: "1",
  REMARKS_OUTPUT_NHIF: "2",
  ACTUAL_EXPENSES: "3",
  REASON_FOR_EXTRA_CHARGE_FOR_ABSENT: "4",
}

export const REMARK_CONTENT_ENHANCEMENT = [
  {
    label: t("Remarks"),
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.REMARKS,
  },
  {
    label: t(
      "Remarks output when making a request to the National Health Insurance Federation"
    ),
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.REMARKS_OUTPUT_NHIF,
  },
  {
    label: t("Actual expenses/expenses"),
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.ACTUAL_EXPENSES,
  },

  {
    label: t("Reasons for extra charge for absenteeism"),
    value: REMARK_CONTENT_ENHANCEMENT_VALUES.REASON_FOR_EXTRA_CHARGE_FOR_ABSENT,
  },
]

export const ADDITIONAL_ITEM_ENHACED = [
  {
    label: t("Extension support addition"),
    value: "1",
  },
  {
    label: t("Special support addition"),
    value: "2",
  },
  {
    label: t("Individual support addition (I)"),
    value: "3",
  },
  {
    label: t("Individual support addition (II)"),
    value: "4",
  },

  {
    label: t("Affiliated Institutions Collaboration Addition (I)"),
    value: "5",
  },

  {
    label: t("Affiliated institution cooperation addition (II)"),
    value: "6",
  },
  {
    label: t("Severe Behavioral Disorder Child Support Addition"),
    value: "7",
  },
]
export const DISABLE_CHILD_OUT_PRINT_SETTIGNS = [
  {
    label: t("Print stock number"),
    value: "1",
  },
  {
    label: t("Print users with a billing amount of 0 yen"),
    value: "2",
  },
  {
    label: t('Print the "how many out of how many" column on the statement'),
    value: "3",
  },
]

export const DONT_DO_LIST = [
  {
    label: t("Do not"),
    value: "0",
  },
  { label: t("Do"), value: "1" },
]

export const NO_YES_STRING_LIST = [
  {
    label: t("no "),
    value: "0",
  },
  { label: t("yes "), value: "1" },
]

export const INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES = {
  NONE: 0,
  HEALTH: 1,
  MOTOR: 2,
  COGNITION: 3,
  LANGUAGE: 4,
  RELATION: 5,
  OTHERS: 6,
}

export const INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTIONS = [
  {
    label: "--",
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.NONE,
  },
  {
    label: t("Health/Lifestyle"),
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.HEALTH,
  },
  {
    label: t("Motor/Sensory"),
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.MOTOR,
  },
  {
    label: t("Cognition/Behavior"),
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.COGNITION,
  },
  {
    label: t("Language/Communication"),
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.LANGUAGE,
  },
  {
    label: t("Human Relations/Sociality"),
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.RELATION,
  },
  {
    label: t("Others"),
    value: INDIVIDUAL_SUPPORT_PLAN_SUPPORT_CONTENT_OPTION_VALUES.OTHERS,
  },
]

export const MinuteOptionsAtInterval = (interval: number): Option[] => {
  const opt = []
  let counter = 0
  while (counter < 60) {
    const counterString = counter.toString().padStart(2, "0")
    opt.push({
      label: counterString,
      value: counterString,
    })
    counter += interval
  }
  return opt
}

export const ACHIEVEMENT_GOAL_TYPE = [
  { label: "---", value: 0 },
  { label: t("achievement"), value: 1 },
  { label: t("Not achieved"), value: 2 },
  { label: t("Partially achieved"), value: 3 },
  { label: t("others"), value: 4 },
]

export const EVALUATION_TYPES = [
  { label: "---", value: 0 },
  { label: t("continuation"), value: 1 },
  { label: t("correction"), value: 2 },
  { label: t("end"), value: 3 },
  { label: t("others"), value: 4 },
]

export const FORBIDDEN_STATUS = 403
